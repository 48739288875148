import React, { useRef } from 'react'
import styles from '../../css/filter_booking/filter.module.css'
import { useLocation } from 'react-router'
import CalendarFilter from './CalendarFilter'
import AllFilter from './AllFilter'

const Filter = React.forwardRef(({ isLoading }, ref) => {
  const location = useLocation()
  const Query = location.search
  const params = new URLSearchParams(Query)
  params.delete('page')

  const dateRef = useRef()
  const handleCalendarfilter = () => {
    dateRef.current.OpenModal()
  }

  const Allfilter = useRef()
  const handleAllfilter = () => {
    Allfilter.current.OpenModal()
  }

  const allFiltersSelected = Array.from(params).length

  return (
    <>
      <div className={styles.filter_block}>
        <button onClick={handleCalendarfilter}>
          <svg
            viewBox='0 0 48 48'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
            style={{
              display: 'block',
              height: '16px',
              width: '16px',
              fill: 'currentColor',
              stroke: 'currentColor',
            }}
          >
            <g stroke='none'>
              <path
                d='M40 29.295V8H8v31a1 1 0 0 0 .883.993L9 40h20.295L40 29.295z'
                fillOpacity='.2'
              ></path>
              <path d='M19 2v2h10V2h2v2h11a2 2 0 0 1 1.995 1.85L44 6v24.953L30.953 44H9a5 5 0 0 1-4.995-4.783L4 39V6a2 2 0 0 1 1.85-1.995L6 4h11V2h2zm10 4H19v2h-2V6H6v33a3 3 0 0 0 2.824 2.995L9 42h20v-8a5 5 0 0 1 4.783-4.995L34 29h8V6H31v2h-2V6zm12.123 25H34a3 3 0 0 0-2.995 2.824L31 34v7.123L41.123 31z'></path>
            </g>
          </svg>
          <span className={styles.length}>1</span>
        </button>
        <button className={allFiltersSelected > 0 ? styles.active : null} onClick={handleAllfilter}>
          <svg
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
            style={{
              display: 'block',
              height: '16px',
              width: '16px',
              fill: 'currentColor',
            }}
          >
            <path d='M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z'></path>
          </svg>
          <span className={styles.length}>{allFiltersSelected}</span>
        </button>
      </div>
      <CalendarFilter ref={dateRef} />
      <AllFilter ref={Allfilter} isLoading={isLoading} />
    </>
  )
})

export default Filter
