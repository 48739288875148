import { useRef } from 'react'
import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import { addCurrency } from '../../../../../../utils/addCurrency'
import TransferSatus from '../../../../default_components/TransferSatus'
import AffiliationModal from './manual_modals/AffiliationModal'
import AccountConnect from '../../../../../users/externals_accounts/AccountConnect'
import axios from 'axios'
import { useQuery } from 'react-query'
import LoadingPoint from '../../../../../LoadingPoint'
import { STATUS_BOOKING } from '../../../../../../enums/booking/status.enums'
import CopyTextToClipboard from '../../../../../ui/CopyTextToClipboard'

const TransferReferrerManual = ({ reservation, calculatedPrices }) => {
  const {
    data = null,
    isLoading,
    error,
    refetch: refetchAffiliation,
  } = useQuery(`get-affiliation-host-${reservation?.host_id.id}`, () =>
    axios
      .get(`/gowifmi_affiliation/by_host/${reservation?.host_id.id}/${reservation?._id}`)
      .then(res => {
        return res.data
      })
  )

  const totalGagne = data?.total_fee
  const currency = data?.currency

  const tranferRef = useRef()
  const handleTransfer = () => {
    tranferRef.current.OpenModal()
  }

  if (error) {
    alert('Une erreur est survenu lors du chargement de parrainage')
  }

  return (
    <>
      {isLoading ? (
        <LoadingPoint />
      ) : (
        <>
          {data && (
            <>
              <div className={styles.content}>
                <h3>Détails du versement d'affiliation</h3>
                {!data?.transfer_status && (
                  <p>
                    Nous transferons au parrain :{' '}
                    {addCurrency(
                      reservation.status === STATUS_BOOKING.CANCELLED
                        ? calculatedPrices.revenueReferrerAfterRefund
                        : calculatedPrices.revenueReferrerBeforeRefund,
                      reservation.prices?.currency
                    )}
                  </p>
                )}
                {data?.transfer_status && (
                  <>
                    <TransferSatus state={data?.transfer_status} />
                    <div>
                      <p>Service : {data?.transfer_service}</p>
                      {data?.transfer_id && (
                        <p>
                          Identifiant du transfert :{' '}
                          <a
                            href={`https://dashboard.stripe.com/connect/transfers/${data?.transfer_id}`}
                            target='_blanck'
                          >
                            {data?.transfer_id}
                          </a>
                        </p>
                      )}
                      <p>Total gagné : {addCurrency(totalGagne, currency)}</p>
                      {data?.comment && <p>Commentaire : {data?.comment}</p>}
                    </div>
                  </>
                )}
                <div className={styles.btn_block}>
                  <button type='button' onClick={handleTransfer}>
                    {data?.transfer_status ? 'Modifier le transfert' : 'Enregistrer un transfert'}
                  </button>
                </div>
              </div>
              <div className={styles.content}>
                <h3>Partenaire de transfert pour le parrain</h3>
                <AccountConnect user={data.referrer} />
                <CopyTextToClipboard text={data.referrer?.id} title='ID' />
              </div>
              <AffiliationModal
                ref={tranferRef}
                reservation={reservation}
                calculatedPrices={calculatedPrices}
                affiliation={data}
                refetchAffiliation={refetchAffiliation}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export default TransferReferrerManual
