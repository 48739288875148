import styles from '../../css/users/header.module.css'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import Filter from './filters/Filter'

const Header = ({ isLoading, data }) => {
  const location = useLocation()
  const path = location.pathname.split('/')[2]

  return (
    <div>
      <div className={styles.header}>
        <div>
          <h1>
            {data?.totalRecords} {data?.totalRecords > 1 ? 'Utilisateurs' : 'Utilisateur'}
          </h1>
          <div className={styles.block_link_search}>
            <div className={styles.link_reservation}>
              <Link to='/users/all' className={path === 'all' ? styles.active : null}>
                Tout
              </Link>
              <Link
                to='/users/connect-web-site'
                className={path === 'connect-web-site' ? styles.active : null}
              >
                Connectés sur le site
              </Link>
              <Link
                to='/users/connect-app'
                className={path === 'connect-app' ? styles.active : null}
              >
                Connectés sur l'app
              </Link>
            </div>
            <Filter isLoading={isLoading} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
