import styles from '../../../../../css/modehotes/reservation/detail.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'

const Navigation = ({ reservation }) => {
  const location = useLocation()
  const history = useHistory()

  const id = location.pathname.split('/')[4]
  const path = location.pathname.split('/')[5]

  return (
    <div>
      <div className={styles.navigations}>
        <div className={styles.left_block}>
          <button
            type='button'
            onClick={() => history.replace(`/hosting/reservations/details/${id}`)}
            className={!path ? styles.active : null}
          >
            La réservation
          </button>
          <button
            type='button'
            onClick={() => history.replace(`/hosting/reservations/details/${id}/traveler`)}
            className={path === 'traveler' ? styles.active : null}
          >
            Voyageur
          </button>
          <button
            type='button'
            onClick={() => history.replace(`/hosting/reservations/details/${id}/annonce`)}
            className={path === 'annonce' ? styles.active : null}
          >
            Détails de l'annonce
          </button>
          <button
            type='button'
            onClick={() =>
              history.replace(`/hosting/reservations/details/${id}/policies-and-rules`)
            }
            className={path === 'policies-and-rules' ? styles.active : null}
          >
            Conditions et règlement
          </button>
          <button
            type='button'
            onClick={() => history.replace(`/hosting/reservations/details/${id}/transfer-payment`)}
            className={path === 'transfer-payment' ? styles.active : null}
          >
            Versements
          </button>
        </div>
        <div className={styles.image}>
          {reservation?.hotel?.images[0].url && (
            <a
              href={`https://gowifmi.com/rooms/${reservation?.hotel?._id}`}
              target='_blank'
              rel='noreferrer'
            >
              <img src={reservation?.hotel?.images[0].url} alt='couverture' />
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default Navigation
