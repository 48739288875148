import styles from '../../../css/navigations/sidebar.module.css'

const Tooltip = ({ title }) => {
  return (
    <div className={styles.tooltip}>
      <span>{title}</span>
    </div>
  )
}

export default Tooltip
