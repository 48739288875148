import styles from '../../../css/mains/detail.module.css'

function ConnexionInfo({ userData }) {
  return (
    <div className={styles.detail_info_1}>
      <h2>Connexion Info</h2>
      <p>
        Ici vous trouverez les informations de conection les plus importantes le contact que vous
        avez séléctionné
      </p>

      <div className={styles.content_block}>
        <h3>Denière connection plateforme</h3>
        <p>Navigateur : {userData?.platform_infos_last_connexion?.browser_name || 'non défini'}</p>
        <p>
          Version du navigateur :{' '}
          {userData?.platform_infos_last_connexion?.browser_version || 'non défini'}
        </p>
        <p>
          Type d'appareil : {userData?.platform_infos_last_connexion?.device_type || 'non défini'}
        </p>
        <p>
          Fournisseur d'appareil :{' '}
          {userData?.platform_infos_last_connexion?.device_vendor || 'non défini'}
        </p>
        <p>
          Modèle d'appareil :{' '}
          {userData?.platform_infos_last_connexion?.device_model || 'non défini'}
        </p>
        <p>
          Système d'exploitation :{' '}
          {userData?.platform_infos_last_connexion?.os_name || 'non défini'}
        </p>
        <p>
          Version du système d'exploitation :{' '}
          {userData?.platform_infos_last_connexion?.os_version || 'non défini'}
        </p>
      </div>
      <div className={styles.content_block}>
        <h3>Denière connection emplacement</h3>
        <p>Pays : {userData?.location_infos_last_connexion?.country || 'non défini'}</p>
        <p>countryCode : {userData?.location_infos_last_connexion?.countryCode || 'non défini'}</p>
        <p>Cité : {userData?.location_infos_last_connexion?.city || 'non défini'}</p>
        <p>Région : {userData?.location_infos_last_connexion?.regionName || 'non défini'}</p>
        <p>Timezone : {userData?.location_infos_last_connexion?.timezone || 'non défini'}</p>
        <p>continent : {userData?.location_infos_last_connexion?.continent || 'non défini'}</p>
        <p>
          continentCode : {userData?.location_infos_last_connexion?.continentCode || 'non défini'}
        </p>
        <p>Adresse ip : {userData?.location_infos_last_connexion?.ip || 'non défini'}</p>
        <p>isProxy : {userData?.location_infos_last_connexion?.isProxy.toString()}</p>
      </div>
    </div>
  )
}
export default ConnexionInfo
