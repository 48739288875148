import { useEffect, useState, useRef } from 'react'
import styles from '../../../css/affiliations/sponsors/list_sponsor.module.css'
import moment from 'moment'
import axios from 'axios'
import { useQuery } from 'react-query'
import LoadingPoint from '../../LoadingPoint'
import Header from './Header'
import { useHistory, Link } from 'react-router-dom'
import Pagination from '../../../components/ui/Pagination'
import UserProfile from './UserProfile'
import { addCurrency } from '../../../utils/addCurrency'
import DetailAffiliation from '../global_components/DetailAffiliation'

const ListSponsor = () => {
  const [searchParams, setSearchParams] = useState(window.location.search)
  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen(location => {
      setSearchParams(location.search)
    })

    return () => {
      window.scrollTo(0, 0)
      unlisten()
    }
  }, [history])

  const fetchData = async () => {
    const { data } = await axios.get(`/gowifmi_affiliation${searchParams}`)
    return data
  }

  const {
    data = {},
    isLoading,
    error,
  } = useQuery([`get-all-affiliations`, searchParams], fetchData)

  const detailRef = useRef(null)
  const handleDetail = affiliation => {
    detailRef.current.handleOpenModal(affiliation)
  }

  return (
    <div className={styles.container}>
      <Header isLoading={isLoading} data={data} />
      {isLoading ? (
        <LoadingPoint />
      ) : (
        <>
          <div className={styles.flex_container}>
            {data?.affiliations?.map(item => (
              <div key={item._id} className={styles.sponsor_card}>
                <UserProfile user={item.referrer} typeUser='Parrain' />
                <UserProfile user={item.referral} typeUser='Filleul' />
                {item?.referral_progression === 25 && (
                  <p className={styles.progress_text}>En attente de la finalisation de l'annonce</p>
                )}
                {item?.referral_progression === 50 && (
                  <p className={styles.progress_text}>En attente de la publication de l'annonce</p>
                )}
                {item?.referral_progression === 75 && (
                  <p className={styles.progress_text}>En attente de la réservation</p>
                )}
                {item?.referral_progression === 100 && (
                  <p className={styles.progress_text}>
                    Félicitations, l'hôte vient d'honorer son premier séjour
                  </p>
                )}
                <div className={styles.progress_block}>
                  <div
                    className={
                      item?.referral_progression >= 25
                        ? `${styles.progress_step} ${styles.active}`
                        : styles.progress_step
                    }
                  ></div>
                  <div
                    className={
                      item?.referral_progression >= 50
                        ? `${styles.progress_step} ${styles.active}`
                        : styles.progress_step
                    }
                  ></div>
                  <div
                    className={
                      item?.referral_progression >= 75
                        ? `${styles.progress_step} ${styles.active}`
                        : styles.progress_step
                    }
                  ></div>
                  <div
                    className={
                      item?.referral_progression >= 100
                        ? `${styles.progress_step} ${styles.active}`
                        : styles.progress_step
                    }
                  ></div>
                </div>
                <div className={styles.progress_footer}>
                  <div className={styles.progress_footer_paid}>
                    {item.transfer_status ? (
                      <>
                        <Link to={`/hosting/reservations/details/${item.reservation}`}>
                          Voir la réservation
                        </Link>
                        <p>{addCurrency(item?.total_fee, item?.currency)}</p>
                      </>
                    ) : (
                      <p>En cours</p>
                    )}
                  </div>
                  <div className={styles.progress_footer_date}>
                    <p>Commencé le {moment(item.createdAt).format('ll')}</p>
                    <div>
                      <button
                        type='button'
                        className={styles.btn_more_info}
                        onClick={() => handleDetail(item)}
                      >
                        <svg
                          aria-label='Plus d’options'
                          fill='#333'
                          height='24'
                          role='img'
                          viewBox='0 0 24 24'
                          width='24'
                        >
                          <circle cx='12' cy='12' r='1.5'></circle>
                          <circle cx='6' cy='12' r='1.5'></circle>
                          <circle cx='18' cy='12' r='1.5'></circle>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Pagination data={data} />
          {data?.totalRecords < 1 && <p>Aucun résultat trouvé</p>}
        </>
      )}
      {error && <p>Une erreur s'est produite veuillez réessayer</p>}
      <DetailAffiliation ref={detailRef} />
    </div>
  )
}

export default ListSponsor
