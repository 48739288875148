import SearchInput from '../../../ui/SearchInput'

const ByHostId = ({ hostId, setHostId }) => {
  return (
    <SearchInput
      id='search_host'
      placeholder="ID l'hôte"
      value={hostId}
      onChange={e => {
        setHostId(e.target.value)
      }}
      onClick={() => {
        setHostId('')
      }}
    />
  )
}

export default ByHostId
