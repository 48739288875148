import styles from '../../../../../../../css/modehotes/annonce/details/adresse.module.css'
import Autocomplete from './Autocomplete'
import CurrentAdresse from './CurrentAdresse'
const Formulaire = ({
  fullAddress,
  appartement,
  ville,
  etat,
  codePostal,
  pays,
  setFullAddress,
  setAppartement,
  setVille,
  setEtat,
  setCodePostal,
  setPays,
  setCountryAbbreviation,
  setCoords,
}) => {
  return (
    <div className={styles.form_block}>
      <CurrentAdresse
        setFullAddress={setFullAddress}
        setVille={setVille}
        setCodePostal={setCodePostal}
        setEtat={setEtat}
        setPays={setPays}
        setCountryAbbreviation={setCountryAbbreviation}
        setCoords={setCoords}
      />
      <div className={styles.block}>
        <label htmlFor='pays'>Pays/région</label>
        <select id='pays' autoComplete='off' value={pays} disabled>
          <option>{pays}</option>
        </select>
      </div>
      <div className={styles.block}>
        <Autocomplete
          fullAddress={fullAddress}
          setFullAddress={setFullAddress}
          setVille={setVille}
          setCodePostal={setCodePostal}
          setEtat={setEtat}
          setPays={setPays}
          setCountryAbbreviation={setCountryAbbreviation}
          setCoords={setCoords}
        />
      </div>
      <div className={styles.block}>
        <label htmlFor='appartement'>Appartement</label>
        <input
          id='appartement'
          type='text'
          autoComplete='off'
          placeholder='Appartement, bâtiment, ect. (facultatif)'
          value={appartement}
          onChange={e => setAppartement(e.target.value)}
        />
      </div>
      <div className={styles.inputs}>
        <div className={styles.block}>
          <label htmlFor='ville'>Ville</label>
          <input
            id='ville'
            type='text'
            placeholder='La ville'
            autoComplete='off'
            value={ville}
            onChange={e => setVille(e.target.value)}
          />
        </div>
        <div className={styles.block}>
          <label htmlFor='etat'>État</label>
          <input
            id='etat'
            type='text'
            autoComplete='off'
            placeholder='État (facultatif)'
            value={etat}
            onChange={e => setEtat(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.block}>
        <label htmlFor='code_postal'>Code postal</label>
        <input
          id='code_postal'
          type='text'
          autoComplete='off'
          placeholder='Code postal (facultatif)'
          value={codePostal}
          onChange={e => setCodePostal(e.target.value)}
        />
      </div>
    </div>
  )
}

export default Formulaire
