import { useState } from 'react'
import styles from '../../../../../css/modehotes/annonce/filtres/filtre.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import Lit from './lit_et_salle_bain/Lit'
import SallBain from './lit_et_salle_bain/Salle_bain'

const Chambre_lit = () => {
  const [voirPlus, setVoirPlus] = useState(false)
  const NumberChambre = ['1', '2', '3', '4', '5', '6', '7', '8']
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const handleChambre = e => {
    Params.delete('page')
    Params.set('number_of_rooms', e.target.value)
    history.push(`${path}?${Params}`)
  }
  const AllChambre = () => {
    Params.delete('page')
    Params.delete('number_of_rooms')
    history.push(`${path}?${Params}`)
  }

  return (
    <div className={styles.block_section_filter}>
      <h3>Chambres et lits</h3>
      <div className={styles.chambre_lit}>
        <div>
          <p>Chambres</p>
          <div className={styles.radio_container}>
            <label>
              <input checked={!Params.get('number_of_rooms')} type='radio' onChange={AllChambre} />
              <span>Tout</span>
            </label>
            {NumberChambre.map(value => (
              <label key={value}>
                <input
                  type='radio'
                  value={value}
                  checked={Params.get('number_of_rooms') === value}
                  onChange={handleChambre}
                />
                <span>
                  {value}
                  {value >= 8 && '+'}
                </span>
              </label>
            ))}
          </div>
        </div>
        <Lit />
        {voirPlus && <SallBain />}
        <div>
          <button onClick={() => setVoirPlus(!voirPlus)}>
            {voirPlus ? 'Afficher moins' : 'Afficher plus'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Chambre_lit
