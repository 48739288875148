import { useEffect, useState } from 'react'
import styles from '../../css/accounts/profile_by_modal.module.css'
import axios from 'axios'
import LoadingPoint from '../LoadingPoint'
import Comment from './profiles/Comment'
import Annonces from './profiles/Annonces'
import ProfileDescription from './profiles/ProfileDescription'
import CoverImage from './profiles/CoverImage'
import Modal from '../ui/Modal'
import CloseButton from '../ui/CloseButton'

const ProfileByModal = ({ userId, modal, setModal }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [getUser, setGetUser] = useState(false)

  useEffect(() => {
    if (modal) {
      setIsLoading(true)
      axios
        .get(`/user/${userId}`)
        .then(res => {
          setGetUser(res.data)
        })
        .catch(() => {
          setError(true)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [modal])

  return (
    <Modal isOpen={modal} onClose={() => setModal(false)}>
      <div className={styles.modal_content}>
        <div className={styles.m_head}>
          <CloseButton onClick={() => setModal(false)} />
          <div></div>
        </div>
        <div className={styles.m_body}>
          {isLoading ? (
            <div className={styles.container_load}>
              <LoadingPoint />
            </div>
          ) : (
            <div>
              <div className={styles.profil_box}>
                <CoverImage getUser={getUser} />
                <ProfileDescription getUser={getUser} />
                <div className={styles.profil_infos}>
                  <Annonces getUser={getUser} />
                  <Comment getUser={getUser} />
                </div>
              </div>
            </div>
          )}
        </div>
        {error && <p>Error server</p>}
      </div>
    </Modal>
  )
}

export default ProfileByModal
