import styles from '../../../css/mains/accounts/extenal_account.module.css'

function AccountCustomer({ user }) {
  return (
    <div className={styles.container}>
      <a href={`https://dashboard.stripe.com/customers/${user?.stripeCustomer}`} target='_blanck'>
        Stripe customer
      </a>
    </div>
  )
}
export default AccountCustomer
