import styles from '../../css/user_loading/user_auth.module.css'
import LoadingPoint from '../../components/LoadingPoint'

function LoadingUser() {
  return (
    <>
      <div className={styles.container}>
        <LoadingPoint />
      </div>
    </>
  )
}
export default LoadingUser
