import { useQuery } from 'react-query'
import axios from 'axios'

function useAnnonce() {
  const { data = {}, isLoading } = useQuery('dashboard_hotel', async () => {
    const response = await axios.get('/gowifmi_hotels/dashboard')
    return response.data
  })

  return {
    isLoading,
    inProgress: data.inProgress || 0,
    saved: data.save || 0,
    published: data.publish || 0,
    hidden: data.hide || 0,
    deactivated: data.deactivate || 0,
    gowifmiPublished: data.gowifmiPublished || 0,
    allHotels: data.allHotels || 0,
  }
}

export default useAnnonce
