import styles from '../../../css/accounts/profile/commentaire.module.css'
import axios from 'axios'
import moment from 'moment'
import { useQuery } from 'react-query'
import LoadingPoint from '../../LoadingPoint'
import AnnonceComment from './commenters/AnnonceComment'
import UserComment from './commenters/UserComment'
import StarSvg from '../../svgs/StarSvg'

const Comment = ({ getUser }) => {
  const {
    data: annonceCommenter = [],
    isLoading,
    error,
  } = useQuery(`get-hostComment-${getUser.id}`, () =>
    axios.get(`/hotel_comment/hostComment/${getUser.id}`).then(res => {
      return res.data
    })
  )

  const { data: userCommenter = [] } = useQuery(`get-userComment-${getUser.id}`, () =>
    axios.get(`/hotel_comment/userComment/${getUser.id}`).then(res => {
      return res.data
    })
  )

  if (error) {
    return <div>Les commentaires n'arrivent pas à charger veuillez reséyer</div>
  }

  return (
    <>
      {isLoading ? (
        <LoadingPoint />
      ) : (
        <>
          <div className={styles.container_comente}>
            <div className={styles.profil_commentaire}>
              <h3>
                <StarSvg width={18} height={18} />
                {annonceCommenter.length} commentaire{annonceCommenter.length > 1 ? 's' : ''} de la
                part de voyageurs
              </h3>
              <div className={styles.comment_list}>
                {annonceCommenter.slice(0, 8).map(item => (
                  <div key={item._id}>
                    <div className={styles.profil_block_img}>
                      <div className={styles.image_block}>
                        {item.user_id?.picture ? (
                          <img src={item.user_id?.picture} alt='' />
                        ) : (
                          <h2>{item.user_id?.first_name.slice(0, 1)}</h2>
                        )}
                      </div>
                      <div className={styles.pro_date}>
                        <h4>
                          {item.user_id?.first_name}, {item.user_id?.city}
                        </h4>
                        <p>Membre depuis {moment(item.user_id?.createdAt).format('LL').slice(2)}</p>
                      </div>
                    </div>
                    <div className={styles.message}>
                      <div className={styles.hotel_img}>
                        <h4>{item.hotel?.title}</h4>
                        <div className={styles.hotel_img_card}>
                          <img src={item.hotel?.images[0].url} alt='couverture' />
                        </div>
                      </div>
                      <p>{item.comment}</p>
                      <h5>{moment(item.createdAt).format('LL').slice(2)}</h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/*----all comments of all annonce---------*/}
            <AnnonceComment annonceCommenter={annonceCommenter} />
            {/*----all comments created of this user ---------*/}
            <UserComment userCommenter={userCommenter} />
          </div>
        </>
      )}
    </>
  )
}

export default Comment
