import styles from '../../../css/modehotes/facturation/filter_invoice.module.css'
import ByInvoiceId from './filters/ByInvoiceId'
import ByHostId from './filters/ByHostId'

const FilterInvoice = ({ invoiceId, setInvoiceId, hostId, setHostId }) => {
  return (
    <>
      <div className={styles.search_block_text}>
        <ByInvoiceId invoiceId={invoiceId} setInvoiceId={setInvoiceId} />
        <ByHostId hostId={hostId} setHostId={setHostId} />
      </div>
    </>
  )
}

export default FilterInvoice
