import React, { useState } from 'react'
import styles from '../../../css/users/filters/all_filter.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import Modal from '../../ui/Modal'
import CloseButton from '../../ui/CloseButton'
import ByFirstName from './filtre_blocks/ByFirstName'
import ByEmail from './filtre_blocks/ByEmail'
import ById from './filtre_blocks/ById'
import ByName from './filtre_blocks/ByName'
import ExternalAccount from './filtre_blocks/ExternalAccount'
import IdentityVerified from './filtre_blocks/IdentityVerified'
import StateOfAccount from './filtre_blocks/StateOfAccount'
import Category from './filtre_blocks/Category'
import LoadingPoint from '../../LoadingPoint'

const Filter = React.forwardRef(({ isLoading }, ref) => {
  const [modal, setModal] = useState(false)
  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))
  const history = useHistory()
  const location = useLocation()
  const page = location.pathname

  const deleteAllFilters = () => {
    history.push(`${page}`)
    setModal(false)
  }

  return (
    <>
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <h3>Filtres</h3>
            <div></div>
          </div>
          <div className={styles.m_body}>
            <ByFirstName />
            <ById />
            <ByEmail />
            <ByName />
            <IdentityVerified />
            <ExternalAccount />
            <StateOfAccount />
            <Category />
          </div>
          <div className={styles.m_footer}>
            <button type='button' className={styles.effacher} onClick={deleteAllFilters}>
              Tout effacer
            </button>

            <button type='button' className={styles.appliquer} onClick={() => setModal(false)}>
              {isLoading ? <LoadingPoint /> : 'Afficher les résultats'}
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
})

export default Filter
