import React, { useState } from 'react'
import styles from '../../../css/affiliations/detail.module.css'
import Modal from '../../ui/Modal'
import CloseButton from '../../ui/CloseButton'
import CopyTextToClipboard from '../../ui/CopyTextToClipboard'

const CheckEdSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
      aria-hidden='true'
      role='presentation'
      focusable='false'
      style={{
        display: 'block',
        fill: 'none',
        height: '20px',
        width: '20px',
        stroke: 'currentcolor',
        strokeWidth: '2.66667',
        overflow: 'visible',
      }}
    >
      <path fill='none' d='m4 16.5 8 8 16-16'></path>
    </svg>
  )
}

const DetailAffiliation = React.forwardRef(({}, ref) => {
  const [modal, setModal] = useState(false)
  const [data, setData] = useState(null)

  React.useImperativeHandle(ref, () => ({
    handleOpenModal: affiliation => {
      setData(affiliation)
      setModal(true)
    },
  }))

  const referrer = data?.referrer
  const referral = data?.referral

  if (!data) return null

  return (
    <>
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <h3>Détails du parrainage</h3>
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div className={styles.display_block}>
              <div className={styles.display_block_info}>
                <div className={styles.display_block_info_item}>
                  <p>ID du parrainage</p>
                  <CopyTextToClipboard text={data._id} title='ID' />
                </div>
                {data.reservation && (
                  <div className={styles.display_block_info_item}>
                    <p>ID de la réservation associée</p>
                    <CopyTextToClipboard text={data.reservation} title='ID' />
                  </div>
                )}
              </div>
              <div className={styles.display_block_info}>
                <h2>Parrain</h2>
                <div className={styles.display_block_info_item}>
                  <p>ID</p>
                  <CopyTextToClipboard text={referrer?.id} title='ID' />
                </div>
                {referrer?.first_name && (
                  <div className={styles.display_block_info_item}>
                    <p>Prénom</p>
                    <CopyTextToClipboard text={referrer?.first_name} title='Prénom' />
                  </div>
                )}
                {referrer?.email && (
                  <div className={styles.display_block_info_item}>
                    <p>Email</p>
                    <CopyTextToClipboard text={referrer?.email} title='Email' />
                    {referrer?.isEmailVerified && <CheckEdSvg />}
                  </div>
                )}
                {referrer?.phone_number && (
                  <div className={styles.display_block_info_item}>
                    <p>Téléphone</p>
                    <CopyTextToClipboard text={referrer?.phone_number} title='Téléphone' />
                    {referrer?.isTelephoneVerified && <CheckEdSvg />}
                  </div>
                )}
              </div>
              <div className={styles.display_block_info}>
                <h2>Filleul</h2>
                <div className={styles.display_block_info_item}>
                  <p>ID</p>
                  <CopyTextToClipboard text={referral?.id} title='ID' />
                </div>
                {referral?.first_name && (
                  <div className={styles.display_block_info_item}>
                    <p>Prénom</p>
                    <CopyTextToClipboard text={referral?.first_name} title='Prénom' />
                  </div>
                )}
                {referral?.email && (
                  <div className={styles.display_block_info_item}>
                    <p>Email</p>
                    <CopyTextToClipboard text={referral?.email} title='Email' />
                    {referral?.isEmailVerified && <CheckEdSvg />}
                  </div>
                )}
                {referral?.phone_number && (
                  <div className={styles.display_block_info_item}>
                    <p>Téléphone</p>
                    <CopyTextToClipboard text={referral?.phone_number} title='Téléphone' />
                    {referral?.isTelephoneVerified && <CheckEdSvg />}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
})

export default DetailAffiliation
