import { useState } from 'react'
import styles from '../../../../css/accounts/profile/commentaire.module.css'
import moment from 'moment'
import Modal from '../../../ui/Modal'
import CloseButton from '../../../ui/CloseButton'

const UserComment = ({ userCommenter }) => {
  const [modal, setModal] = useState(false)

  if (userCommenter.length < 1) {
    return null
  }

  return (
    <>
      <div>
        <div className={styles.myComente}>
          <button onClick={() => setModal(true)}>
            Commentaires de la part de cet utilisateur ({userCommenter.length})
          </button>
        </div>
      </div>
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            {userCommenter.map(item => (
              <div className='' key={item._id}>
                <div className={styles.profil_block_img}>
                  <div className={styles.image_block}>
                    {item.user_id?.picture ? (
                      <img src={item.user_id?.picture} alt='' />
                    ) : (
                      <h2>{item.user_id?.first_name.slice(0, 1)}</h2>
                    )}
                  </div>
                  <div>
                    <h4>
                      {item.user_id?.first_name}, {item.user_id?.city}
                    </h4>
                    <p>Membre depuis {moment(item.user_id?.createdAt).format('LL').slice(2)}</p>
                  </div>
                </div>
                <div className={styles.message}>
                  <div className={styles.hotel_img}>
                    <h4>{item.hotel?.title}</h4>
                    <div className={styles.hotel_img_card}>
                      <img src={item.hotel?.images[0].url} alt='couverture' />
                    </div>
                  </div>
                  <p>{item.comment}</p>
                  <h5>{moment(item.createdAt).format('LL').slice(2)}</h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default UserComment
