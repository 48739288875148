import React, { useState } from 'react'
import styles from '../../../../../../../css/modehotes/reservation/refund_modal.module.css'
import CloseButton from '../../../../../../ui/CloseButton'
import Modal from '../../../../../../ui/Modal'
import axios from 'axios'
import LoadingPoint from '../../../../../../LoadingPoint'
import Toast from '../../../../../../infos/toast'
import { addCurrency } from '../../../../../../../utils/addCurrency'
import { useForm } from 'react-hook-form'
import { REFUND_SATATUS } from '../../../../../../../enums/booking/refundStatus.enums'

const RefundModif = React.forwardRef(({ reservation, refetch }, ref) => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const modifications = reservation.modifications[reservation.modifications.length - 1]

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))

  const closeModal = () => {
    setModal(false)
  }
  const priceText = modifications?.new_price_to_pay?.toString()
  const TotalRembourser = Number(priceText?.slice(1, priceText.length))

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange' })
  const onSubmit = data => {
    setLoading(true)
    axios
      .patch(`/gowifmi_reservations/refund_payment/${reservation._id}`, data)
      .then(async () => {
        Toast.fire({
          icon: 'success',
          title: 'Le remboursement à étè effectué avec succés.',
        })
        await refetch()
      })
      .catch(err => {
        if (err.response?.data?.message === 'demande_invalid') {
          Toast.fire({
            icon: 'error',
            title:
              "Vous ne pouvez pas rembourser le voyageur, parce que l'Hôte n'as pas accepter cette demande de modification",
          })
        } else if (err.response?.data?.raw?.message) {
          Toast.fire({
            icon: 'error',
            title: err.response?.data?.raw?.message,
          })
        } else {
          Toast.fire({ icon: 'error', title: 'Un problème est survenu au niveau de serveur' })
        }
      })
      .finally(() => {
        reset()
        setModal(false)
        setLoading(false)
      })
  }

  return (
    <>
      <Modal isOpen={modal} onClose={closeModal}>
        <form autoComplete='off' onSubmit={handleSubmit(onSubmit)} className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={closeModal} />
            <div className={styles.m_body_head}>
              <h2>Rembourser le voyageur</h2>
            </div>
          </div>
          <div className={styles.m_body}>
            <div className={styles.recap}>
              <h4>Détails du prix</h4>
              <p>
                Nouveau Total séjour :{' '}
                {addCurrency(modifications?.total_stay_fee, reservation.prices?.currency)}
              </p>
              <h4>Montant à remboursé</h4>
              <p>
                Nous remboursons le voyageur :{' '}
                <strong>{addCurrency(TotalRembourser, reservation.prices?.currency)}</strong> sur le
                prix Total initial de{' '}
                <strong>
                  {addCurrency(modifications?.total_initial, reservation.prices?.currency)}
                </strong>
              </p>
            </div>
            <div>
              <label htmlFor='price'>
                Rassurez-vous que ce montant à remboursé est belle et bien correcte
              </label>
              <input
                type='text'
                inputMode='numeric'
                id='price'
                placeholder='Total à rembourser'
                disabled
                value={addCurrency(TotalRembourser, reservation.prices?.currency)}
              />
            </div>
            <div>
              <label htmlFor='status'>Statut *</label>
              <select
                id='status'
                {...register('status', {
                  required: 'Statut est obligatoire.',
                })}
              >
                <option value=''>Sélectionner le statut</option>
                <option value={REFUND_SATATUS.COMPLETE}>Réussi</option>
                <option value={REFUND_SATATUS.IN_PROGRESS}>Traitement</option>
                <option value={REFUND_SATATUS.REJECTED}>Echoué</option>
              </select>
              {errors.status && <p className={styles.error}>{errors.status.message}</p>}
            </div>
            <div>
              <label htmlFor='refund_service'>Service *</label>
              <select
                id='refund_service'
                {...register('refund_service', {
                  required: 'Service est obligatoire.',
                })}
              >
                <option value=''>Sélectionner un service</option>
                <option value='stripe'>Stripe</option>
                <option value='paypal'>Paypal</option>
                <option value='payoneer'>Payoneer</option>
                <option value='mercury'>Mercury</option>
                <option value='to_host'>Par hôte</option>
              </select>
              {errors.refund_service && (
                <p className={styles.error}>{errors.refund_service.message}</p>
              )}
            </div>

            <div>
              <label htmlFor='refund_id'>Identifiant du remboursement</label>
              <input
                type='text'
                id='refund_id'
                {...register(
                  'refund_id'
                  // {required: 'Identifiant du remboursement est obligatoire.',}
                )}
              />
              {/* {errors.refund_id && <p className={styles.error}>{errors.refund_id.message}</p>} */}
            </div>
            <div>
              <label htmlFor='comment'>Ajouter un commentaire</label>
              <textarea
                type='text'
                id='comment'
                placeholder='comment...'
                {...register('comment')}
              ></textarea>
            </div>
          </div>

          <div className={styles.btn_action}>
            <button type='button' onClick={closeModal}>
              Annuler
            </button>
            {loading ? (
              <button type='button' disabled className={styles.loading}>
                <LoadingPoint />
              </button>
            ) : (
              <button type='submit'>Enregistrer le remboursement</button>
            )}
          </div>
        </form>
      </Modal>
    </>
  )
})
export default RefundModif
