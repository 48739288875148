import styles from '../../../css/modehotes/statistics/header.module.css'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'

const Header = ({}) => {
  const location = useLocation()
  const path = location.pathname.split('/')[2]

  return (
    <div>
      <div className={styles.header}>
        <h1>Statistiques</h1>
        <div className={styles.block_link_search}>
          <div className={styles.link_reservation}>
            <Link
              to='/statistics/search-hotel'
              className={path === 'search-hotel' ? styles.active : null}
            >
              Recherches d'hôtels
            </Link>
            <Link to='/statistics/views' className={path === 'views' ? styles.active : null}>
              Consultations
            </Link>
            <Link
              to='/statistics/reservation'
              className={path === 'reservation' ? styles.active : null}
            >
              Réservations
            </Link>
            <Link
              to='/statistics/evaluation-hotel'
              className={path === 'evaluation-hotel' ? styles.active : null}
            >
              Commentaires des logements
            </Link>
            <Link
              to='/statistics/evaluation-traveler'
              className={path === 'evaluation-traveler' ? styles.active : null}
            >
              Commentaires sur les voyageurs
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
