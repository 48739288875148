import { useEffect, useContext } from 'react'
import styles from '../../css/accounts/account.module.css'
import { useHistory } from 'react-router-dom'
import { User } from '../../context/UserContext'
import AppLayout from '../../layouts/AppLayout'
import AllAccountSetings from '../../components/accounts/AllAccountSetings'

const AccountSetings = () => {
  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (user === null) {
    history.push('/')
    return null
  }

  return (
    <AppLayout>
      <title>Paramètre du compte - Gowifmi</title>

      <div className={styles.account_container}>
        <div className={styles.account_header}>
          <h1>Compte</h1>
          <p>
            {user.first_name && <strong>{user.first_name},</strong>} {user.email}
          </p>
        </div>
        <div className={styles.content}>
          <div>
            <AllAccountSetings />
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

export default AccountSetings
