import { useEffect, useState } from 'react'
import styles from '../../css/ui/modal_customisable.module.css'
import ReactDOM from 'react-dom'

let timeout
const ModalCustomisable = ({ isOpen, onClose, children }) => {
  const [opened, setOpened] = useState(false)

  useEffect(() => {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

    if (timeout) {
      clearTimeout(timeout)
    }
    if (isOpen) {
      setOpened(true)
      document.body.style.overflow = 'hidden'
      document.body.style.insetInlineEnd = `${scrollbarWidth}px`
      document.body.style.paddingRight = `${scrollbarWidth}px`
    } else {
      timeout = setTimeout(() => {
        setOpened(false)
        document.body.style.overflow = null
        document.body.style.insetInlineEnd = null
        document.body.style.paddingRight = null
      }, 500)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [isOpen])

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  if (!opened) return null
  return ReactDOM.createPortal(
    <div className={isOpen ? `${styles.modal} ${styles.active}` : styles.modal}>
      <div className={styles.modal_overlay}></div>
      <div className={styles.container} onClick={handleOverlayClick}>
        <div>{children}</div>
      </div>
    </div>,
    document.getElementById('modal-root')
  )
}

export default ModalCustomisable
