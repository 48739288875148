import { useState, useEffect, useRef, useContext, memo } from 'react'
import styles from '../../css/navigations/navbarHote.module.css'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { User } from '../../context/UserContext'
import { useSelector } from 'react-redux'
import useNotification from '../../hooks/useNotification'
import Dropdown from './nav_bars/Dropdown'
import SectionLeft from './nav_bars/SectionLeft'
import FindUser from '../accounts/FindUser'

function Navbar() {
  const [togle, setTogle] = useState(false)
  const { totalNotification } = useNotification()
  const user = useContext(User)
  const userReducestore = useSelector(state => state.userReducer)
  const location = useLocation()
  const path = location.pathname.split('/')
  const contenttogle = useRef(null)
  useEffect(() => {
    const handleClickOutside = event => {
      if (contenttogle.current && !contenttogle.current.contains(event.target)) {
        setTogle(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const funduserRef = useRef(null)
  const handleFundUser = () => {
    funduserRef.current.handleOpenModal()
  }

  return (
    <>
      <div className={styles.navbar}>
        <SectionLeft path={path} />
        <div className={styles.right}>
          <div className={styles.liens}>
            <Link
              to='/hosting/reservations/upcoming'
              className={path[2] === 'reservations' ? styles.active : ''}
            >
              Réservations
            </Link>
            <Link to='/hosting/listings' className={path[2] === 'listings' ? styles.active : ''}>
              Annonces
            </Link>
            <Link to='/hosting/calendar' className={path[2] === 'calendar' ? styles.active : ''}>
              Calendrier
            </Link>
          </div>
          <div className={styles.buttons}>
            <button type='button' className={styles.send_message} onClick={handleFundUser}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 32 32'
                aria-hidden='true'
                role='presentation'
                focusable='false'
                style={{
                  display: 'block',
                  fill: 'none',
                  height: '16px',
                  width: '16px',
                  stroke: 'currentcolor',
                  strokeWidth: '4',
                  overflow: 'visible',
                }}
              >
                <path fill='none' d='M13 24a11 11 0 1 0 0-22 11 11 0 0 0 0 22zm8-3 9 9'></path>
              </svg>
            </button>
            <div
              className={togle ? `${styles.toogle} ${styles.active}` : styles.toogle}
              ref={contenttogle}
            >
              <button className={styles.wrapper} onClick={() => setTogle(!togle)}>
                <div className={styles.toggle_menu}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 32 32'
                    aria-hidden='true'
                    role='presentation'
                    focusable='false'
                  >
                    <g fill='none'>
                      <path d='M2 16h28M2 24h28M2 8h28'></path>
                    </g>
                  </svg>
                </div>
                <div className={styles.toggle_profile}>
                  {!user === null || !userReducestore.picture ? (
                    <svg
                      viewBox='0 0 32 32'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'
                      role='presentation'
                      focusable='false'
                    >
                      <path d='m16 .7c-8.437 0-15.3 6.863-15.3 15.3s6.863 15.3 15.3 15.3 15.3-6.863 15.3-15.3-6.863-15.3-15.3-15.3zm0 28c-4.021 0-7.605-1.884-9.933-4.81a12.425 12.425 0 0 1 6.451-4.4 6.507 6.507 0 0 1 -3.018-5.49c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5a6.513 6.513 0 0 1 -3.019 5.491 12.42 12.42 0 0 1 6.452 4.4c-2.328 2.925-5.912 4.809-9.933 4.809z'></path>
                    </svg>
                  ) : (
                    <img src={userReducestore.picture} alt='' />
                  )}
                </div>
                {user && <>{totalNotification > 0 && <span>{totalNotification}</span>}</>}
              </button>
              {togle && <Dropdown togle={togle} setTogle={setTogle} />}
            </div>
          </div>
        </div>
      </div>
      <FindUser ref={funduserRef} />
    </>
  )
}
export default memo(Navbar)
