import { useState, useEffect } from 'react'
import styles from '../../../css/modehotes/reservation/download_receipt_button.module.css'
import { pdf } from '@react-pdf/renderer'
import SucceedTemplatePDF from './SucceedTemplatePDF'
import LoadingPoint from '../../LoadingPoint'

const DownloadReceiptButton = ({ reservation, user, hotel }) => {
  const [generatePDF, setGeneratePDF] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (generatePDF) {
      const generateAndDownloadPDF = async () => {
        setIsLoading(true)
        const doc = <SucceedTemplatePDF reservation={reservation} user={user} hotel={hotel} />
        const blob = await pdf(doc).toBlob()
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = `Reçu-réservation-${reservation.reservation_infos.reference}.pdf`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url) // Nettoyer l'URL pour éviter les fuites de mémoire
        setIsLoading(false)
        setGeneratePDF(false)
      }

      generateAndDownloadPDF()
    }
  }, [generatePDF])

  const handleClick = () => {
    setGeneratePDF(true)
  }

  return (
    <div className={styles.container}>
      {!isLoading ? (
        <button onClick={handleClick}>
          <span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              fill='currentColor'
              viewBox='0 0 24 24'
            >
              <path d='M12 16l4-5h-3V4h-2v7H8l4 5zm6 1v2H6v-2H4v4h16v-4h-2z' />
            </svg>
          </span>
          Télécharger le reçu
        </button>
      ) : (
        <button type='button' disabled>
          <span>
            <LoadingPoint />
          </span>
          Préparation du téléchargement du reçu
        </button>
      )}
    </div>
  )
}

export default DownloadReceiptButton
