import { useEffect, useContext } from 'react'
import styles from '../../css/affiliations/sponsor_container.module.css'
import ListSponsor from '../../components/affiliations/sponsors/ListSponsor'
import Navigation from '../../components/affiliations/global_components/Navigation'
import { User } from '../../context/UserContext'
import AppLayout from '../../layouts/AppLayout'
import { useHistory } from 'react-router-dom'

const Sponsored = () => {
  const user = useContext(User)
  const history = useHistory()

  useEffect(() => {
    if (user === null) {
      history.push('/')
    }
  }, [])

  return (
    <AppLayout>
      <title>Vos filleuls | Gowifmi</title>
      <Navigation />
      <div className={styles.main_home}>
        <ListSponsor />
      </div>
    </AppLayout>
  )
}

export default Sponsored
