import { useContext } from 'react'
import styles from '../../css/navigations/sidebar.module.css'
import { useLocation } from 'react-router'
import { User, Logout } from '../../context/UserContext'
import GowifmiLogoSvg from '../svgs/GowifmiLogoSvg'
import GowifmiIconSvg from '../svgs/GowifmiIconSvg'
import { isPagePath } from '../../utils/PagePath'
import UserLink from './sidebar/UserLink'
import Assistance from './sidebar/Assistance'
import Statistic from './sidebar/Statistic'
import Sponsorship from './sidebar/Sponsorship'
import Account from './sidebar/Account'
import LogoutUser from './sidebar/LogoutUser'
import { USER_ROLE } from '../../enums/user/role.enums'

const Sidebar = () => {
  const user = useContext(User)
  const logout = useContext(Logout)
  const location = useLocation()
  const path = location.pathname.split('/')[1]

  return (
    <div className={isPagePath(path) ? `${styles.container} ${styles.hidded}` : styles.container}>
      <div className={styles.s_head}>
        <div className={styles.logo_block}>
          <a href='/' className={styles.logo}>
            <GowifmiLogoSvg />
          </a>
          <a href='/' className={styles.icon}>
            <GowifmiIconSvg />
          </a>
        </div>
      </div>

      <div className={styles.s_body}>
        {user?.role === USER_ROLE.ADMIN && <UserLink user={user} path={path} />}
        {user?.role === USER_ROLE.ADMIN || user?.role === USER_ROLE.USER_MODERATOR ? (
          <Assistance user={user} path={path} />
        ) : null}
        <Statistic user={user} path={path} />
        <Sponsorship user={user} path={path} />
        <Account user={user} path={path} />
        <LogoutUser user={user} logout={logout} />
      </div>
    </div>
  )
}

export default Sidebar
