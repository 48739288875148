import { useEffect, useContext } from 'react'
import styles from '../css/layouts/app_layout.module.css'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { User } from '../context/UserContext'
import Navbar from '../components/navigations/Navbar'
import Sidebar from '../components/navigations/Sidebar'
import { isPagePath } from '../utils/PagePath'

const AppLayout = ({ children }) => {
  const location = useLocation()
  const path = location.pathname.split('/')[1]
  const sizeViewBottomNav = window.matchMedia('(max-width:743px)')

  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
    if (!user?.isGowifmiEmployee) {
      history.push('/login')
    }
  }, [])

  return (
    <div>
      {!sizeViewBottomNav.matches && <Sidebar />}
      <div className={isPagePath(path) ? `${styles.main} ${styles.hidded}` : styles.main}>
        <Navbar />
        <div>{children}</div>
      </div>
    </div>
  )
}

export default AppLayout
