import React, { useState } from 'react'
import styles from '../../../../../../../css/modehotes/reservation/refund_modal.module.css'
import CloseButton from '../../../../../../ui/CloseButton'
import Modal from '../../../../../../ui/Modal'
import axios from 'axios'
import LoadingPoint from '../../../../../../LoadingPoint'
import Toast from '../../../../../../infos/toast'
import { useForm } from 'react-hook-form'
import { REFUND_SATATUS } from '../../../../../../../enums/booking/refundStatus.enums'
import { addCurrencyComplet } from '../../../../../../../utils/addCurrency'

const AddRefundTravelModal = React.forwardRef(({ reservation, refetch }, ref) => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))

  const closeModal = () => {
    setModal(false)
  }

  const totalsejour = reservation.prices?.total_stay_fee
  const travelerServiceFee = reservation.prices?.service_fee
  //total revenus before refund
  const revenusBeforeHostServiceFee = totalsejour - travelerServiceFee

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange' })
  const onSubmit = Formdata => {
    const data = {
      ...Formdata,
      refund_amount: Number(Formdata.refund_amount),
      service_fee: Number(Formdata.service_fee),
      total_stay_fee: Number(Formdata.total_stay_fee),
      currency: 'usd',
    }
    setLoading(true)
    axios
      .patch(`/gowifmi_reservations/refund_travel/${reservation._id}`, data)
      .then(res => {
        Toast.fire({ icon: 'success', title: 'Remboursement ajouté avec succès' })
        refetch()
        reset()
      })
      .catch(err => {
        if (err.response?.data?.message === 'status_invalid') {
          Toast.fire({
            icon: 'error',
            title:
              'Vous ne pouvez pas rembourser le voyageur, parce que la réservation ne pas annulée',
          })
        } else if (err.response?.data?.raw?.message) {
          Toast.fire({
            icon: 'error',
            title: err.response?.data?.raw?.message,
          })
        } else {
          Toast.fire({ icon: 'error', title: 'Un problème est survenu au niveau de serveur' })
        }
      })
      .finally(() => {
        setLoading(false)
        setModal(false)
      })
  }

  return (
    <>
      <Modal isOpen={modal} onClose={closeModal}>
        <form autoComplete='off' onSubmit={handleSubmit(onSubmit)} className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={closeModal} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div className={styles.m_body_head}>
              <h2>Ajouter un remboursement</h2>
              <p>
                * Dans le cas d'un remboursement Total voici le montant{' '}
                <strong>
                  {addCurrencyComplet(revenusBeforeHostServiceFee, reservation.prices?.currency)}
                </strong>{' '}
                à remboursé sans déduction de frais de service d'hôte.
              </p>
            </div>
            <div>
              <label htmlFor='status'>Statut *</label>
              <select
                id='status'
                defaultValue={reservation?.refund_total?.status}
                {...register('status', {
                  required: 'Statut est obligatoire.',
                })}
              >
                <option value=''>Sélectionner le statut</option>
                <option value={REFUND_SATATUS.COMPLETE}>Réussi</option>
                <option value={REFUND_SATATUS.IN_PROGRESS}>Traitement</option>
                <option value={REFUND_SATATUS.REJECTED}>Echoué</option>
              </select>
              {errors.status && <p className={styles.error}>{errors.status.message}</p>}
            </div>
            <div>
              <label htmlFor='refund_service'>Service *</label>
              <select
                id='refund_service'
                defaultValue={reservation?.refund_total?.refund_service}
                {...register('refund_service', {
                  required: 'Service est obligatoire.',
                })}
              >
                <option value=''>Sélectionner un service</option>
                <option value='stripe'>Stripe</option>
                <option value='paypal'>Paypal</option>
                <option value='payoneer'>Payoneer</option>
                <option value='mercury'>Mercury</option>
                <option value='to_host'>Par l'hôte</option>
              </select>
              {errors.refund_service && (
                <p className={styles.error}>{errors.refund_service.message}</p>
              )}
            </div>

            <div>
              <label htmlFor='refund_id'>Identifiant du remboursement</label>
              <input
                type='text'
                id='refund_id'
                defaultValue={reservation?.refund_total?.refund_id}
                {...register('refund_id')}
              />
            </div>

            <div>
              <label htmlFor='refund_amount'>Total à remboursé *</label>
              <input
                type='text'
                id='refund_amount'
                defaultValue={reservation?.refund_total?.refund_amount}
                {...register('refund_amount', {
                  required: 'Total à remboursé est obligatoire.',
                })}
              />
              {errors.refund_amount && (
                <p className={styles.error}>{errors.refund_amount.message}</p>
              )}
            </div>

            <div>
              <label htmlFor='comment'>Ajouter un commentaire</label>
              <textarea
                type='text'
                id='comment'
                defaultValue={reservation?.refund_total?.comment}
                placeholder='comment...'
                {...register('comment')}
              ></textarea>
            </div>
          </div>
          <div className={styles.btn_action}>
            <button type='button' onClick={closeModal}>
              Annuler
            </button>
            {loading ? (
              <button type='button' disabled className={styles.loading}>
                <LoadingPoint />
              </button>
            ) : (
              <button type='submit'>Enregistrer le remboursement</button>
            )}
          </div>
        </form>
      </Modal>
    </>
  )
})
export default AddRefundTravelModal
