import styles from '../../../css/users/history/list_history.module.css'

const ChangeZone = ({ history }) => {
  const changes = history.changes

  return (
    <div className={styles.modified_block}>
      {changes.email && (
        <p>
          Email : <span>{changes.email}</span>
        </p>
      )}
      {changes.isEmailVerified !== undefined && (
        <p>
          Email Vérifié : <span>{changes.isEmailVerified ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {changes.password && (
        <p>
          Mot de Passe :<span> Modifié</span>
        </p>
      )}
      {changes.password_submit !== undefined && (
        <p>
          Soumission du Mot de Passe : <span>{changes.password_submit ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {changes.name && (
        <p>
          Nom : <span>{changes.name}</span>
        </p>
      )}
      {changes.first_name && (
        <p>
          Prénom : <span>{changes.first_name}</span>
        </p>
      )}
      {changes.phone_number && (
        <p>
          Numéro de Téléphone : <span>{changes.phone_number}</span>
        </p>
      )}
      {changes.isCompany_account !== undefined && (
        <p>
          Compte Entreprise : <span>{changes.isCompany_account ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {changes.stripeCustomer && (
        <p>
          Client Stripe : <span>{changes.stripeCustomer}</span>
        </p>
      )}
      {changes.account_connect_stripe && (
        <p>
          Compte Connect Stripe : <span>{changes.account_connect_stripe}</span>
        </p>
      )}
      {changes.isTelephoneVerified !== undefined && (
        <p>
          Téléphone Vérifié : <span>{changes.isTelephoneVerified ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {changes.work_email && (
        <p>
          Email Professionnel : <span>{changes.work_email}</span>
        </p>
      )}
      {changes.language_1 && (
        <p>
          Langue 1 : <span>{changes.language_1}</span>
        </p>
      )}
      {changes.language_2 && (
        <p>
          Langue 2 : <span>{changes.language_2}</span>
        </p>
      )}
      {changes.profile_categories && (
        <p>
          Profession : <span>{changes.profile_categories}</span>
        </p>
      )}
      {changes.about_me && (
        <p>
          À propos de moi : <span>{changes.about_me}</span>
        </p>
      )}
      {changes.gender && (
        <p>
          Genre : <span>{changes.gender}</span>
        </p>
      )}
      {changes.date_of_birth && (
        <p>
          Date de Naissance : <span>{changes.date_of_birth}</span>
        </p>
      )}
      {changes?.address && (
        <div>
          {changes?.address?.full_address && (
            <p>
              Adresse complète : <span>{changes?.address?.full_address}</span>
            </p>
          )}
          {changes?.address?.street && (
            <p>
              Rue : <span>{changes?.address?.street}</span>
            </p>
          )}
          {changes?.address?.apartment_name && (
            <p>
              Nom de l'Appartement : <span>{changes?.address?.apartment_name}</span>
            </p>
          )}
          {changes?.address?.city && (
            <p>
              Ville : <span>{changes?.address?.city}</span>
            </p>
          )}
          {changes?.address?.state && (
            <p>
              État : <span>{changes?.address?.state}</span>
            </p>
          )}
          {changes?.address?.postal_code && (
            <p>
              Code Postal : <span>{changes?.address?.postal_code}</span>
            </p>
          )}
          {changes?.address?.country && (
            <p>
              Pays : <span>{changes?.address?.country}</span>
            </p>
          )}
          {changes?.address?.country_code && (
            <p>
              Code du Pays : <span>{changes?.address?.country_code}</span>
            </p>
          )}
        </div>
      )}
      {changes.picture && (
        <p>
          Photo de Profil : <span>{changes.picture}</span>
        </p>
      )}
      {changes.cover_picture && (
        <p>
          Photo de Couverture : <span>{changes.cover_picture}</span>
        </p>
      )}
      {changes.superhost !== undefined && (
        <p>
          Superhôte : <span>{changes.superhost ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {changes.role && (
        <p>
          Rôle : <span>{changes.role}</span>
        </p>
      )}
      {changes.isHost !== undefined && (
        <p>
          Hôte : <span>{changes.isHost ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {changes.isGowifmiEmployee !== undefined && (
        <p>
          Employé GoWiFmi : <span>{changes.isGowifmiEmployee ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {changes.identitySubmitted !== undefined && (
        <p>
          Identité Soumise : <span>{changes.identitySubmitted ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {changes.isIdentityValid !== undefined && (
        <p>
          Identité Valide : <span>{changes.isIdentityValid ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {changes.twoAuthentificator && (
        <div>
          <p>
            Authentification à Deux Facteurs Activée :{' '}
            <span>{changes.twoAuthentificator.enabled ? 'Oui' : 'Non'}</span>
          </p>
          {changes.twoAuthentificator.tempSecret !== undefined && (
            <p>
              Secret Temporaire :{' '}
              <span>{changes.twoAuthentificator.tempSecret ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes.twoAuthentificator.secret !== undefined && (
            <p>
              Secret : <span>{changes.twoAuthentificator.secret ? 'Oui' : 'Non'}</span>
            </p>
          )}
        </div>
      )}
      {changes.sessions && (
        <div>
          <p>Sessions</p>
          <p>
            Site Actif : <span>{changes.sessions.active_site ? 'Oui' : 'Non'}</span>
          </p>
          <p>
            App Active : <span>{changes.sessions.active_app ? 'Oui' : 'Non'}</span>
          </p>
        </div>
      )}
      {changes.isDisabled !== undefined && (
        <p>
          Désactivé : <span>{changes.isDisabled ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {changes.disabled_reason_1 && (
        <p>
          Raison de Désactivation 1 : <span>{changes.disabled_reason_1}</span>
        </p>
      )}
      {changes.disabled_reason_2 && (
        <p>
          Raison de Désactivation 2 : <span>{changes.disabled_reason_2}</span>
        </p>
      )}
      {changes.platform_infos_last_connexion && (
        <div>
          <p>Dernière Connexion </p>
          {changes.platform_infos_last_connexion.browser_name && (
            <p>
              Nom du Navigateur : <span>{changes.platform_infos_last_connexion.browser_name}</span>
            </p>
          )}
          {changes.platform_infos_last_connexion.browser_version && (
            <p>
              Version du Navigateur :{' '}
              <span>{changes.platform_infos_last_connexion.browser_version}</span>
            </p>
          )}
          {changes.platform_infos_last_connexion.device_type && (
            <p>
              Type de l'Appareil : <span>{changes.platform_infos_last_connexion.device_type}</span>
            </p>
          )}
          {changes.platform_infos_last_connexion.device_vendor && (
            <p>
              Vendeur de l'Appareil :{' '}
              <span>{changes.platform_infos_last_connexion.device_vendor}</span>
            </p>
          )}
          {changes.platform_infos_last_connexion.device_model && (
            <p>
              Modèle de l'Appareil :{' '}
              <span>{changes.platform_infos_last_connexion.device_model}</span>
            </p>
          )}
          {changes.platform_infos_last_connexion.os_name && (
            <p>
              Nom du Système d'Exploitation :{' '}
              <span>{changes.platform_infos_last_connexion.os_name}</span>
            </p>
          )}
          {changes.platform_infos_last_connexion.os_version && (
            <p>
              Version du Système d'Exploitation :{' '}
              <span>{changes.platform_infos_last_connexion.os_version}</span>
            </p>
          )}
        </div>
      )}
      {changes.location_infos_last_connexion && (
        <div>
          <p>Informations de Localisation </p>
          {changes.location_infos_last_connexion.ip && (
            <p>
              IP : <span>{changes.location_infos_last_connexion.ip}</span>
            </p>
          )}
          {changes.location_infos_last_connexion.city && (
            <p>
              Ville : <span>{changes.location_infos_last_connexion.city}</span>
            </p>
          )}
          {changes.location_infos_last_connexion.country && (
            <p>
              Pays : <span>{changes.location_infos_last_connexion.country}</span>
            </p>
          )}
          {changes.location_infos_last_connexion.countryCode && (
            <p>
              Code du Pays : <span>{changes.location_infos_last_connexion.countryCode}</span>
            </p>
          )}
          {changes.location_infos_last_connexion.regionName && (
            <p>
              Région : <span>{changes.location_infos_last_connexion.regionName}</span>
            </p>
          )}
          {changes.location_infos_last_connexion.timezone && (
            <p>
              Fuseau Horaire : <span>{changes.location_infos_last_connexion.timezone}</span>
            </p>
          )}
          {changes.location_infos_last_connexion.continent && (
            <p>
              Continent : <span>{changes.location_infos_last_connexion.continent}</span>
            </p>
          )}
          {changes.location_infos_last_connexion.continentCode && (
            <p>
              Code du Continent : <span>{changes.location_infos_last_connexion.continentCode}</span>
            </p>
          )}
          {changes.location_infos_last_connexion.lat !== undefined && (
            <p>
              Latitude : <span>{changes.location_infos_last_connexion.lat}</span>
            </p>
          )}
          {changes.location_infos_last_connexion.lng !== undefined && (
            <p>
              Longitude : <span>{changes.location_infos_last_connexion.lng}</span>
            </p>
          )}
          {changes.location_infos_last_connexion.isProxy !== undefined && (
            <p>
              Proxy : <span>{changes.location_infos_last_connexion.isProxy ? 'Oui' : 'Non'}</span>
            </p>
          )}
        </div>
      )}
      {changes.picture && (
        <div className={styles.img_container}>
          <div className={styles.img_block}>
            <img src={changes.picture} alt='photo_profil' />
          </div>
        </div>
      )}
      {changes.cover_picture && (
        <div className={styles.img_container}>
          <div className={styles.img_block}>
            <img src={changes.cover_picture} alt='photo_couverture' />
          </div>
        </div>
      )}
    </div>
  )
}

export default ChangeZone
