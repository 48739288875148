import SearchInput from '../../../ui/SearchInput'

const ByInvoiceId = ({ invoiceId, setInvoiceId }) => {
  return (
    <SearchInput
      id='search_invoice'
      placeholder='ID facture'
      value={invoiceId}
      onChange={e => {
        setInvoiceId(e.target.value)
      }}
      onClick={() => {
        setInvoiceId('')
      }}
    />
  )
}

export default ByInvoiceId
