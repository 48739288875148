const TYPE_HISTORY = {
  CREATE_USER: 'create_user',
  UPDATE_USER: 'update_user',
  UPDATE_EMAIL: 'update_email',
  UPDATE_PASSWORD: 'update_password',
  FORGOT_PASSWORD: 'forgot_password',
  GENERATE_2FA_QRCODE: 'generate_2fa_qrcode',
  SETUP_2FA: 'setup_2fa',
  RESET_2FA: 'reset_2fa',
  ADD_PROFILE_IMAGE: 'add_profile_image',
  ADD_COVER_IMAGE: 'add_cover_image',
  DISABLE_ACCOUNT: 'disable_account',
  DELETE_USER: 'delete_user',
}

const MODIFY_BY = {
  USER: 'user',
  GOWIFMI: 'gowifmi',
}

export { TYPE_HISTORY, MODIFY_BY }
