import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import BillStatus from '../../../../default_components/BillStatus'
import { addCurrency } from '../../../../../../utils/addCurrency'

const Bill = ({ reservation, calculatedPrices }) => {
  const { totalGowifmiServiceFee } = calculatedPrices

  return (
    <div className={styles.content}>
      <h3>Facturation</h3>

      <p>Paiement de : {addCurrency(totalGowifmiServiceFee, reservation.prices?.currency)}</p>
      <BillStatus reservation={reservation} />
    </div>
  )
}

export default Bill
