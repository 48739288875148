import styles from '../../../../css/users/filters/all_filter.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import Checkbox from '../../../ui/Checkbox'

const ExternalAccount = () => {
  const array = [
    { name: 'Client Stripe', value: 'stripe_customer' },
    { name: 'connect Stripe', value: 'account_connect_stripe' },
  ]

  const Query = window.location.search
  const Params = new URLSearchParams(Query)

  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      Params.delete('page')
      Params.append(e.target.value, 'true')
      history.push(`${path}?${Params}`)
    } else {
      Params.delete('page')
      Params.delete(e.target.value)
      history.push(`${path}?${Params}`)
    }
  }

  return (
    <div className={styles.block_section_filter}>
      <h3>Les comptes externe</h3>
      <div className={styles.grid_system}>
        {array.map(item => (
          <div key={item.name} className={styles.checkbox_container}>
            <div>
              <Checkbox
                id={item.name}
                value={item.value}
                checked={Params.has(item.value)}
                onChange={onchangeFilters}
              />
            </div>
            <label htmlFor={item.name}>{item.name}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ExternalAccount
