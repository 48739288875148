import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import { Link } from 'react-router-dom'

const HistoryActivity = ({ reservation }) => {
  return (
    <div className={styles.content}>
      <h3>Historique</h3>
      <div className={styles.sup}>
        <Link to={`/hosting/reservation-history?reservation=${reservation._id}`}>
          Voir l'historique des activités
        </Link>
      </div>
    </div>
  )
}

export default HistoryActivity
