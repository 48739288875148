import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const LocalLaw = () => {
  const [open, setOpen] = useState(false)

  const openFuction = () => {
    setOpen(true)
  }
  const reset = () => {
    setOpen(false)
  }

  return (
    <div className={styles.block}>
      <h2>Lois et réglementations</h2>
      {!open ? (
        <Section title='Législation locale' handleOpen={openFuction}></Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Votre législation locale' handleReset={reset}></ContenHead>
          <div className={styles.body}>
            <div className={styles.loi_block}>
              <p>
                Prenez un moment pour vérifier les réglementations locales qui s'appliquent à votre
                logement. Nous voulons nous assurer que vous disposez de toutes les informations
                nécessaires pour bien commencer votre activité d'hôte.
              </p>
              <p>
                La plupart des villes réglementent le partage de logement, et les lois ou arrêtés
                qui s'appliquent peuvent apparaître à différents endroits (loi d'urbanisme, plan
                d'occupation des sols, code de la construction, règlement de zonage, etc.). Le plus
                souvent, vous devez vous enregistrer et obtenir une autorisation avant de pouvoir
                mettre en location votre propriété ou accepter des voyageurs. Vous pouvez également
                être responsable de la collecte et du versement de certaines taxes. Dans certains
                endroits, les locations de courte durée sont totalement interdites.
              </p>
              <p>
                Vous êtes responsable de la mise en location ou de la réservation d'un logement. Il
                vous appartient donc de connaître les règles qui s'appliquent avant d'utiliser
                Gowifmi. Pour vous aider à démarrer, nous vous proposons des ressources utiles dans
                la section ( Réglementations de votre ville )
              </p>
              <p>
                En acceptant nos Conditions générales et en mettant en ligne une annonce, vous vous
                engagez à respecter vos lois et réglementations locales.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default LocalLaw
