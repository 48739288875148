import { useState } from 'react'
import styles from '../../../css/modehotes/calendar/calendar.module.css'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import moment from 'moment'
import axios from 'axios'
import Swal from 'sweetalert2'
import LoadingPoint from '../../LoadingPoint'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: toast => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

const CalendarComponent = ({ setListeActive, annonce, path, refetch }) => {
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [dateRange, setDateRange] = useState([new Date(), new Date()])
  const [startDate, endDate] = dateRange
  const Maxdate = moment(new Date())
    .add(annonce?.availability_calendar?.availability_range, 'month')
    .format('YYYY-MM-DD')

  const updateDate = button => {
    setLoading(true)
    const data = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    }
    const Link =
      button === 'bloquer'
        ? `/gowifmi_hotels/pushDates/${path}`
        : `/gowifmi_hotels/pullDates/${path}`
    axios
      .patch(Link, data)
      .then(() => {
        refetch()
        Toast.fire({
          icon: 'success',
          title: 'Le calendrier a été mis à jour avec succès',
        })
        setLoading(false)
      })
      .catch(() => {
        Swal.fire('warning', 'Waooh un problème est survenu au niveau de serveur !', 'warning')
        setLoading(false)
      })
  }

  return (
    <div className={styles.container}>
      {annonce !== null ? (
        <div>
          <div className={styles.calendar_head}>
            <div className={styles.open_modal}>
              <button onClick={() => setListeActive(true)}>
                <svg
                  viewBox='0 0 32 32'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <g file='none' fillRule='nonzero'>
                    <path d='m2 16h28'></path>
                    <path d='m2 24h28'></path>
                    <path d='m2 8h28'></path>
                  </g>
                </svg>
              </button>
            </div>
            <div className={styles.datesSelectionner}>
              {disabled ? (
                <p>Sélectionner des dates</p>
              ) : (
                <>
                  <p>{moment(startDate).format('ll')}</p>
                  {endDate && <p>Jusqu'au {moment(endDate).format('ll')}</p>}
                </>
              )}
            </div>
            {loading ? (
              <LoadingPoint />
            ) : (
              <div className={styles.actions_btn}>
                <button
                  disabled={disabled}
                  className={disabled ? styles.disabled : ''}
                  onClick={() => updateDate('bloquer')}
                >
                  Bloquer
                </button>
                <button
                  disabled={disabled}
                  className={disabled ? styles.disabled : ''}
                  onClick={() => updateDate('dispo')}
                >
                  Disponible
                </button>
              </div>
            )}
          </div>

          <div className={styles.calendar}>
            <Calendar
              onChange={setDateRange}
              onClickDay={value => {
                setDateRange([value])
                setDisabled(false)
              }}
              value={dateRange}
              minDate={new Date()}
              maxDate={new Date(Maxdate)}
              selectRange={true}
              view='month'
              tileContent={({ date }) =>
                annonce?.reservation_dates?.includes(moment(date).format('YYYY-MM-DD')) ? (
                  <p className={styles.notice_date}>Date bloquée</p>
                ) : null
              }
              tileClassName={({ date }) =>
                annonce?.reservation_dates?.includes(moment(date).format('YYYY-MM-DD'))
                  ? `${styles.date} ${styles.date_bloquer}`
                  : styles.date
              }
            />
          </div>
        </div>
      ) : (
        <div className={styles.select_annonce}>
          <p>Sélectionner une annonce</p>
          <button onClick={() => setListeActive(true)}>Sélectionner</button>
        </div>
      )}
    </div>
  )
}

export default CalendarComponent
