import styles from '../../../../css/accounts/profile/profil_description.module.css'
import { PROFIL_CATEGORY } from '../../../../enums/user/category.enums'

const Category = ({ getUser }) => {
  const categoryProfil = getUser?.profile_categories[0]
  if (!categoryProfil) return null

  return (
    <div className={styles.category_block}>
      {categoryProfil === PROFIL_CATEGORY.TOURIST_GUIDE && <p>Guide touristique</p>}
      {categoryProfil === PROFIL_CATEGORY.PHOTOGRAPHER && <p>Photographe</p>}
      {categoryProfil === PROFIL_CATEGORY.LOCAL_CHEF && <p>Chef cuisinier local</p>}
      {categoryProfil === PROFIL_CATEGORY.LOCAL_CRAFTSMAN && <p>Artisan local</p>}
      {categoryProfil === PROFIL_CATEGORY.TRANSLATOR_INTERPRETER && <p>Traducteur/interprète</p>}
      {categoryProfil === PROFIL_CATEGORY.LOCAL_HISTORIAN && <p>Historien local</p>}
      {categoryProfil === PROFIL_CATEGORY.FAMILY_TRAVEL_SPECIALIST && (
        <p>Spécialiste en voyages pour familles</p>
      )}
      {categoryProfil === PROFIL_CATEGORY.EXCURSION_ORGANIZER && <p>Organisateur d'excursions</p>}
      {categoryProfil === PROFIL_CATEGORY.TRAVEL_INFLUENCER && <p>Influenceur voyage</p>}
    </div>
  )
}

export default Category
