import styles from '../../../css/filter_booking/all_filter.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import { STATUS_BOOKING } from '../../../enums/booking/status.enums'
import Checkbox from '../../ui/Checkbox'

const Status = () => {
  const array = [
    { name: 'Demande', value: STATUS_BOOKING.REQUEST },
    { name: 'À venir', value: STATUS_BOOKING.FUTURE },
    { name: 'En cours', value: STATUS_BOOKING.IN_PROGRESS },
    { name: 'Terminée', value: STATUS_BOOKING.COMPLETE },
    { name: 'Réjetée', value: STATUS_BOOKING.REJECTED },
    { name: 'Annulée', value: STATUS_BOOKING.CANCELLED },
    { name: 'Supprimée', value: STATUS_BOOKING.DELETED },
  ]
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  URLSearchParams.prototype.remove = function (key, value) {
    const entries = this.getAll(key)
    const newEntries = entries.filter(entry => entry !== value)
    this.delete(key)
    newEntries.forEach(newEntry => this.append(key, newEntry))
  }
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      Params.delete('page')
      Params.append('status', e.target.value)
      history.push(`${path}?${Params}`)
    } else {
      Params.delete('page')
      Params.remove('status', e.target.value)
      history.push(`${path}?${Params}`)
    }
  }
  return (
    <div className={styles.block_section_filter}>
      <h3>Statut de la réservation</h3>
      <div className={styles.grid_system}>
        {array.map(item => (
          <div key={item.name} className={styles.checkbox_container}>
            <div>
              <Checkbox
                id={item.name}
                value={item.value}
                checked={Params.getAll('status').includes(item.value)}
                onChange={onchangeFilters}
              />
            </div>
            <label htmlFor={item.name}>{item.name}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Status
