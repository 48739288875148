import styles from '../../../css/filter_booking/all_filter.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import Checkbox from '../../ui/Checkbox'

const PaymentToHost = () => {
  const Query = window.location.search
  const Params = new URLSearchParams(Query)

  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      Params.delete('page')
      Params.delete('no_transfer_payment_to_host')
      Params.append('transfer_payment_to_host', true)
      history.push(`${path}?${Params}`)
    } else {
      Params.delete('page')
      Params.delete('transfer_payment_to_host')
      history.push(`${path}?${Params}`)
    }
  }
  const onchangeFilters2 = e => {
    if (e.target.checked === true) {
      Params.delete('page')
      Params.delete('transfer_payment_to_host')
      Params.append('no_transfer_payment_to_host', true)
      history.push(`${path}?${Params}`)
    } else {
      Params.delete('page')
      Params.delete('no_transfer_payment_to_host')
      history.push(`${path}?${Params}`)
    }
  }
  return (
    <div className={styles.block_section_filter}>
      <h3>Versement de l'hôte</h3>
      <div className={styles.grid_system}>
        <div className={styles.checkbox_container}>
          <div>
            <Checkbox
              id='transfer_payment_to_host'
              checked={Params.get('transfer_payment_to_host')}
              onChange={onchangeFilters}
            />
          </div>
          <label htmlFor='transfer_payment_to_host'>Effectué</label>
        </div>
        <div className={styles.checkbox_container}>
          <div>
            <Checkbox
              id='no_transfer_payment_to_host'
              checked={Params.get('no_transfer_payment_to_host')}
              onChange={onchangeFilters2}
            />
          </div>
          <label htmlFor='no_transfer_payment_to_host'>Non effectué</label>
        </div>
      </div>
    </div>
  )
}

export default PaymentToHost
