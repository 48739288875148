import { useEffect, useState, useContext } from 'react'
import styles from '../../../css/accounts/two_authentificator.module.css'
import { useHistory } from 'react-router-dom'
import { User } from '../../../context/UserContext'
import LoadingPoint from '../../../components/LoadingPoint'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import AppLayout from '../../../layouts/AppLayout'
import AllAccountSetings from '../../../components/accounts/AllAccountSetings'
import { useQuery } from 'react-query'
import Toast from '../../../components/infos/toast'

const SetTwoAuthentificator = () => {
  const [loadingbutton, setLoadingbutton] = useState(false)
  const user = useContext(User)
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
  }, [])

  const formSchema = yup.object().shape({
    code: yup.string().required("Veuillez saisir le code d'authentification"),
  })

  const {
    data = {},
    isLoading,
    error,
  } = useQuery(`get-grcode_2fa`, () =>
    axios.get(`/user/generate_grcode_2fa`).then(res => {
      return res.data
    })
  )

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) })

  const onSubmit = data => {
    setLoadingbutton(true)
    axios
      .post(`/user/setup_2fa`, data)
      .then(() => {
        setLoadingbutton(false)
        reset()
        Toast.fire({
          icon: 'success',
          title: "Votre mode d'authentification à 2FA est activé avec succès",
        })
        history.push('/')
      })
      .catch(err => {
        setLoadingbutton(false)
        if (!err.response?.data?.success) {
          Toast.fire({ icon: 'error', title: "Information d'identification invalides" })
        } else {
          Toast.fire({ icon: 'error', title: 'Un probleme est survenu au niveau de serveur' })
        }
      })
  }

  return (
    <AppLayout>
      <title>Connexion et sécurité | 2FA - Gowifmi</title>
      <div className={styles.seting_container}>
        <div className={styles.seting_box}>
          <div className={styles.AllAccount_setings}>
            <AllAccountSetings />
          </div>
          <div className={styles.separate}> </div>
          <div className={styles.securite_1}>
            <div>
              <h1>Connexion et sécurité - 2FA</h1>
            </div>
            <p className={styles.p_head}>
              Mettez à jour votre mode d'authentification à 2 facteurs
            </p>
            {isLoading ? (
              <LoadingPoint />
            ) : (
              <div>
                <div className={styles.gr_code_block}>
                  <div className={styles.gr_code_block_img}>
                    <img src={data.image} alt='qrcode' />
                  </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div
                    className={
                      errors.code ? `${styles.block_input} ${styles.erreur}` : styles.block_input
                    }
                  >
                    <label htmlFor='actuel'>Code</label>
                    <input
                      type='tel'
                      id='actuel'
                      autoComplete='none'
                      placeholder='123 456'
                      {...register('code')}
                    />
                    {errors.code && <span className={styles.erreur}>{errors.code.message}</span>}
                  </div>

                  <div className={styles.buttoms}>
                    {loadingbutton ? (
                      <button disabled className={styles.loading}>
                        <LoadingPoint />
                      </button>
                    ) : (
                      <button type='submit'>Soumettre</button>
                    )}
                  </div>
                </form>
                {error && (
                  <p className={styles.p_error}>
                    Une erreur est survenue lors du chargement de l'image Qrcode
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

export default SetTwoAuthentificator
