import styles from '../../css/home/all_hotel_count.module.css'
import useAnnonce from '../../hooks/useAnnonce'
import ChartHotel from './charts/ChartHotel'

function AllHotelCount() {
  const { inProgress, saved, published, hidden, deactivated, gowifmiPublished, allHotels } =
    useAnnonce()
  return (
    <div className={styles.container}>
      <div className={styles.tab_block_2}>
        <div className={styles.tab_colum}>
          <div className={styles.tab_text}>
            <p>Toutes tes annonces</p>
            <p className={styles.mini}>Travaillons</p>
          </div>
          <div className={styles.tab_icon}>
            <i className='fa fa-thumbs-up'></i>
          </div>
        </div>
        <div className={styles.colum_2}>
          <div className={styles.colum_2_text}>
            <p>En cours</p>
            <p className={styles.number}>{inProgress}</p>
          </div>
          <div className={styles.colum_2_text}>
            <p>Enregistrées</p>
            <p className={styles.number}>{saved}</p>
          </div>
          <div className={styles.colum_2_text}>
            <p>Publiées</p>
            <p className={styles.number}>{published}</p>
          </div>
          <div className={styles.colum_2_text}>
            <p>Masquées</p>
            <p className={styles.number}>{hidden}</p>
          </div>
          <div className={styles.colum_2_text}>
            <p>Désactivées</p>
            <p className={styles.number}>{deactivated}</p>
          </div>
          <div className={styles.colum_2_text}>
            <p>Gowifmi publie</p>
            <p>{gowifmiPublished} </p>
          </div>
        </div>
      </div>
      <div className={styles.tab_block}>
        <ChartHotel
          inProgress={inProgress}
          saved={saved}
          published={published}
          hidden={hidden}
          deactivated={deactivated}
          gowifmiPublished={gowifmiPublished}
          allHotels={allHotels}
        />
      </div>
    </div>
  )
}
export default AllHotelCount
