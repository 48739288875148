import styles from '../../../../../css/modehotes/reservation/detail.module.css'
import momentTimeZone from 'moment-timezone'
import Modification from './components/Modification'
import StatusReservation from './components/StatusReservation'
import SetupIntent from './components/payments/SetupIntent'
import PaymentIntent from './components/payments/PaymentIntent'
import Progress from './components/Progress'
import DateReservation from './components/DateReservation'
import DetailPrice from './components/DetailPrice'
import ValidSvg from '../../../../svgs/ValidSvg'
import { STATUS_BOOKING } from '../../../../../enums/booking/status.enums'
import ChangeStatus from './components/ChangeStatus'
import Cancellation from './components/Cancellation'
import HistoryActivity from './components/HistoryActivity'
import CopyTextToClipboard from '../../../../ui/CopyTextToClipboard'
import DownloadReceipt from './components/DownloadReceipt'

const Reservation = ({ reservation, calculatedPrices, refetch }) => {
  const userTimezone = momentTimeZone.tz.guess()

  return (
    <>
      <div className={styles.block}>
        <h2>Les informations de la réservation</h2>
        <div className={styles.content}>
          <h3>Paiement en ligne</h3>
          <div className={styles.demande}>
            <ValidSvg state={reservation.pay_online} />
            <span>{reservation.pay_online ? 'Oui' : 'Non'}</span>
          </div>
        </div>
        <StatusReservation reservation={reservation} />
        <Progress reservation={reservation} />
        {reservation.pay_online && (
          <div className={styles.content}>
            {reservation.status === STATUS_BOOKING.REQUEST ? (
              <SetupIntent reservation={reservation} setupIntent={reservation.setup_intent} />
            ) : (
              <PaymentIntent reservation={reservation} paymentIntent={reservation.payment_intent} />
            )}
          </div>
        )}
        <DetailPrice reservation={reservation} calculatedPrices={calculatedPrices} />
        <DateReservation reservation={reservation} />
        <div className={styles.content}>
          <h3>Les voyageurs</h3>
          <p>Adultes : {reservation?.reservation_infos?.total_adults}</p>
          <p>Enfants : {reservation?.reservation_infos?.total_children}</p>
          <p>Bébés : {reservation?.reservation_infos?.total_babies}</p>
          <p>Animaux : {reservation?.reservation_infos?.total_pets}</p>
        </div>
        <div className={styles.content}>
          <h3>Référence</h3>
          <p>{reservation?.reservation_infos?.reference}</p>
        </div>
        <Modification reservation={reservation} refetch={refetch} />
        <div className={styles.content}>
          <h3>La date de la création</h3>
          <p>{momentTimeZone.utc(reservation?.createdAt).tz(userTimezone).calendar()}</p>
        </div>
        <ChangeStatus reservation={reservation} refetch={refetch} />
        {reservation.status === STATUS_BOOKING.FUTURE ||
        reservation.status === STATUS_BOOKING.CANCELLED ||
        reservation.status === STATUS_BOOKING.IN_PROGRESS ? (
          <Cancellation
            reservation={reservation}
            calculatedPrices={calculatedPrices}
            refetch={refetch}
          />
        ) : null}
        <div className={styles.content}>
          <h3>ID de la réservation</h3>
          <CopyTextToClipboard text={reservation?._id} title='ID' />
        </div>
        <HistoryActivity reservation={reservation} />
        <DownloadReceipt reservation={reservation} />
      </div>
    </>
  )
}

export default Reservation
