import { Bar } from 'react-chartjs-2'

function ChartHotel({
  inProgress,
  saved,
  published,
  hidden,
  deactivated,
  gowifmiPublished,
  allHotels,
}) {
  const data = {
    labels: ['En cours', 'Enregistrées', 'Publiées', 'Masquées', 'Desactivées', 'Gowifmi publie'],
    datasets: [
      {
        label: ' ',
        data: [inProgress, saved, published, hidden, deactivated, gowifmiPublished],
        backgroundColor: [
          'rgba(255, 206, 86, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(255, 206, 86, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Les annonces effectués',
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: allHotels,
      },
    },
  }

  return <Bar data={data} options={options} width={100} height={100} />
}

export default ChartHotel
