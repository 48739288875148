import PayementStatus from '../../../../../default_components/PaymentStatus'
import { ONLINE_PAYMENT_STATUS } from '../../../../../../../enums/booking/onlineStatus'

const PaymentIntent = ({ reservation, paymentIntent }) => {
  return (
    <div>
      <h3>Paiement stripe</h3>
      <PayementStatus state={paymentIntent?.status} />
      {paymentIntent?.status === ONLINE_PAYMENT_STATUS.SUCCEEDED && (
        <p>
          <strong>Message</strong> : Le paiement est effectué avec succès.
        </p>
      )}
      {paymentIntent?.status === ONLINE_PAYMENT_STATUS.PROCESSING && (
        <p>
          <strong>Message</strong> : Le paiement est en cours de traitement. <br />
          Nous vous enverron l'etat du voyage par cette adresse e-mail (
          {reservation?.user_id?.email} )
        </p>
      )}
      {paymentIntent?.status === ONLINE_PAYMENT_STATUS.REJECTED && (
        <p>
          <strong>Message</strong> : Le paiement n'a pas abouti. <br />
          veuillez réessayer
        </p>
      )}
      <p>
        <strong>ID</strong> :
        <a
          href={`https://dashboard.stripe.com/payments/${paymentIntent?.stripe_id}`}
          target='_blanck'
        >
          {paymentIntent?.stripe_id}
        </a>
      </p>
    </div>
  )
}

export default PaymentIntent
