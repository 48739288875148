import styles from '../../../../css/users/filters/all_filter.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import { PROFIL_CATEGORY } from '../../../../enums/user/category.enums'
import Checkbox from '../../../ui/Checkbox'

const Category = () => {
  const array = [
    { name: 'Guide touristique', value: PROFIL_CATEGORY.TOURIST_GUIDE },
    { name: 'Photographe', value: PROFIL_CATEGORY.PHOTOGRAPHER },
    { name: 'Chef cuisinier local', value: PROFIL_CATEGORY.LOCAL_CHEF },
    { name: 'Artisan local', value: PROFIL_CATEGORY.LOCAL_CRAFTSMAN },
    { name: 'Traducteur/interprète', value: PROFIL_CATEGORY.TRANSLATOR_INTERPRETER },
    { name: 'Historien local', value: PROFIL_CATEGORY.LOCAL_HISTORIAN },
    {
      name: 'Spécialiste en voyages pour familles',
      value: PROFIL_CATEGORY.FAMILY_TRAVEL_SPECIALIST,
    },
    { name: "Organisateur d'excursions", value: PROFIL_CATEGORY.EXCURSION_ORGANIZER },
    { name: 'Influenceur voyage', value: PROFIL_CATEGORY.TRAVEL_INFLUENCER },
  ]
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  URLSearchParams.prototype.remove = function (key, value) {
    const entries = this.getAll(key)
    const newEntries = entries.filter(entry => entry !== value)
    this.delete(key)
    newEntries.forEach(newEntry => this.append(key, newEntry))
  }
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      Params.delete('page')
      Params.append('category', e.target.value)
      history.push(`${path}?${Params}`)
    } else {
      Params.delete('page')
      Params.remove('category', e.target.value)
      history.push(`${path}?${Params}`)
    }
  }
  return (
    <div className={styles.block_section_filter}>
      <h3>Catégories</h3>
      <div className={styles.grid_system}>
        {array.map(item => (
          <div key={item.name} className={styles.checkbox_container}>
            <div>
              <Checkbox
                id={item.name}
                value={item.value}
                checked={Params.getAll('category').includes(item.value)}
                onChange={onchangeFilters}
              />
            </div>
            <label htmlFor={item.name}>{item.name}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Category
