export const GET_ANNONCE = 'GET_ANNONCE'
export const UPDATE_ANNONCE = 'UPDATE_ANNONCE'
export const GET_USER = 'GET_USER'

//* ***get les annonces de l'hote connecter */
export function getAnnonce(data) {
  return {
    type: GET_ANNONCE,
    payload: data,
  }
}
//* ***modifier les données de user*/
export function getUser(data) {
  return {
    type: GET_USER,
    payload: data,
  }
}
