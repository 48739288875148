const Loading = () => {
  return (
    <div className='loading-point'>
      <div className='content-point'>
        <div className='point'></div>
        <div className='point'></div>
        <div className='point'></div>
      </div>
    </div>
  )
}

export default Loading
