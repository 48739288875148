import { useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import LoadingPoint from '../../../LoadingPoint'
import Switch from '../../../ui/Switch'

const SwitchInfo = ({ url, state, updateData = {}, refetch }) => {
  const [loading, setLoading] = useState(false)

  const handlechange = () => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Pour mettre à jour ce utilisateur',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, modifié !',
    }).then(result => {
      if (result.isConfirmed) {
        setLoading(true)
        axios
          .patch(url, updateData)
          .then(() => {
            Swal.fire('success', 'utilisateur modifié avec succès', 'success')
            refetch()
          })
          .catch(() => {
            Swal.fire('error', 'échec de la mise à jour', 'error')
          })
          .finally(() => setLoading(false))
      }
    })
  }

  return (
    <div className=''>
      {loading ? <LoadingPoint /> : <Switch checked={state} onChange={handlechange} />}
    </div>
  )
}

export default SwitchInfo
