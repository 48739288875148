import styles from '../../../../css/modehotes/history/list_history.module.css'
import { TYPE_HISTORY, MODIFY_BY } from '../../../../enums/booking/type_history.enums'
import { Link } from 'react-router-dom'
import momentTimeZone from 'moment-timezone'
import ChangeZone from './ChangeZone'

const ListHistory = ({ data }) => {
  const userTimezone = momentTimeZone.tz.guess()

  return (
    <div className={styles.container}>
      {data.map(item => (
        <div className={styles.container_block} key={item._id}>
          <p className={styles.p_date}>
            {momentTimeZone.utc(item.createdAt).tz(userTimezone).calendar()}
          </p>
          {item.type === TYPE_HISTORY.CREATE_RESERVATION && <h2>Création de la réservation</h2>}
          {item.type === TYPE_HISTORY.CREATE_REQUEST_RESERVATION && (
            <h2>Création de la demande de réservation</h2>
          )}
          {item.type === TYPE_HISTORY.CONFIRM_PAYMENT && (
            <h2>Confirmation du paiement de la réservation</h2>
          )}
          {item.type === TYPE_HISTORY.CONFIRM_SETUP_PAYMENT && (
            <h2>Confirmation de l'enregistrement du paiement pour réservation</h2>
          )}
          {item.type === TYPE_HISTORY.UPDATE_TRAVELERS && <h2>Mise à jour des voyageurs</h2>}
          {item.type === TYPE_HISTORY.UPDATE_PROCESS && <h2>Mise à jour du processus</h2>}
          {item.type === TYPE_HISTORY.CREATE_REQUEST_UPDATE_DATE && (
            <h2>Création de la demande de mise à jour de date</h2>
          )}
          {item.type === TYPE_HISTORY.CANCEL_REQUEST_UPDATE_DATE && (
            <h2>Annulation de la demande de mise à jour de date</h2>
          )}
          {item.type === TYPE_HISTORY.CANCEL_REQUEST_RESERVATION && (
            <h2>Annulation de la demande de réservation</h2>
          )}
          {item.type === TYPE_HISTORY.CANCEL_RESERVATION && <h2>Annulation de la réservation</h2>}
          {item.type === TYPE_HISTORY.ACCEPT_REQUEST_RESERVATION && (
            <h2>Acceptation de la demande de réservation</h2>
          )}
          {item.type === TYPE_HISTORY.ACCEPT_REQUEST_UPDATE_DATE && (
            <h2>Acceptation de la demande de mise à jour de date</h2>
          )}
          {item.type === TYPE_HISTORY.REJECT_REQUEST_UPDATE_DATE && (
            <h2>Rejet de la demande de mise à jour de date</h2>
          )}
          {item.type === TYPE_HISTORY.GOWIFMI_UPDATE_RESERVATION && (
            <h2>Mise à jour de la réservation par Gowifmi</h2>
          )}
          {item.type === TYPE_HISTORY.CHANGE_STATUS_PROCESSING_TO_COMPLETE && (
            <h2>Changement de statut de traitement à complété</h2>
          )}
          {item.type === TYPE_HISTORY.CHANGE_STATUS_FUTURE_TO_IN_PROGRESS && (
            <h2>Changement de statut de à venir à en cours</h2>
          )}
          {item.type === TYPE_HISTORY.CHANGE_STATUS_IN_PROGRESS_TO_COMPLETE && (
            <h2>Changement de statut de en cours à terminé</h2>
          )}
          {item.type === TYPE_HISTORY.CHANGE_STATUS_TO_DELETE && (
            <h2>Changement de statut à supprimé</h2>
          )}
          {item.modifyBy === MODIFY_BY.HOST && (
            <p className={styles.p_profil}>Modifié par l'hôte · {item.user?.first_name}</p>
          )}
          {item.modifyBy === MODIFY_BY.TRAVELER && (
            <p className={styles.p_profil}>Modifié par le voyageur · {item.user?.first_name}</p>
          )}
          {item.modifyBy === MODIFY_BY.GOWIFMI && (
            <p className={styles.p_profil}>Modifié par Gowifmi · {item.user?.first_name}</p>
          )}
          <ChangeZone history={item} />
          <div className={styles.footer}>
            <Link to={`/hosting/reservations/details/${item.reservation?._id}`}>
              Voir la réservation
            </Link>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ListHistory
