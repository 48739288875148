const TYPE_HISTORY = {
  CREATE_HOTEL: 'create_hotel',
  UPDATE_HOTEL: 'update_hotel',
  ADD_IMAGE: 'add_image',
  UPDATE_IMAGE: 'update_image',
  REORGANIZE_IMAGE: 'reorgarnize_image',
  DELETE_IMAGE: 'delete_image',
  DEACTIVETE_HOTEL: 'deactivete_hotel',
}

const MODIFY_BY = {
  HOST: 'host',
  GOWIFMI: 'gowifmi',
}

export { TYPE_HISTORY, MODIFY_BY }
