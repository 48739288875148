import { useContext } from 'react'
import styles from '../../../../css/modehotes/facturation/invoice.module.css'
import { User } from '../../../../context/UserContext'
import PendingSvg from '../../../svgs/PendingSvg'
import CheckSvg from '../../../svgs/CheckSvg'
import ValidateInvoice from './ValidateInvoice'
import { USER_ROLE } from '../../../../enums/user/role.enums'

const Invoice = ({ data, refetch }) => {
  const user = useContext(User)

  return (
    <div className={styles.block_item}>
      <h3>Facture de {data.host?.first_name}</h3>
      {data.paid ? (
        <div className={styles.status_block}>
          <div className={styles.complet}>
            <span>Réglé</span> <CheckSvg />
          </div>
        </div>
      ) : (
        <div className={styles.status_block}>
          <div className={styles.incomplet}>
            <span>En attente</span> <PendingSvg />
          </div>
        </div>
      )}
      <div>
        <p>
          Pour {data.reservations.length} réservation{data.reservations.length > 1 ? 's' : ''}
        </p>
        <p>
          Voir la facture :{' '}
          <a href={`https://dashboard.stripe.com/invoices/${data.invoiceId}`} target='_blanck'>
            {data.invoiceId}
          </a>
        </p>
        <a href={`${data.invoiceUrl}`} target='_blanck'>
          Lien du paiement de la facture
        </a>
        {data.manualPayment && <p>Paiement effectué manuellement</p>}
      </div>
      {user?.role === USER_ROLE.ADMIN && <ValidateInvoice refetch={refetch} data={data} />}
    </div>
  )
}

export default Invoice
