import React, { useState } from 'react'
import styles from '../../css/accounts/find_user.module.css'
import Modal from '../ui/Modal'
import CloseButton from '../ui/CloseButton'
import SearchUser from './find_users/SearchUser'

const FindUser = React.forwardRef(({}, ref) => {
  const [modal, setModal] = useState(false)
  React.useImperativeHandle(ref, () => ({
    handleOpenModal: () => {
      setModal(true)
    },
  }))

  return (
    <>
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <h3>Rechercher un utilisateur</h3>
            <div></div>
          </div>
          <div className={styles.m_body}>
            <SearchUser />
          </div>
        </div>
      </Modal>
    </>
  )
})

export default FindUser
