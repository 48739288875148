import { GET_ANNONCE, GET_USER, UPDATE_ANNONCE } from './actions'
import { etatinitial } from './states'

export function updateAnnonce(state = {}, action) {
  switch (action.type) {
    case GET_ANNONCE:
      return action.payload
    case UPDATE_ANNONCE:
      return action.payload
    default:
      return state
  }
}

export function userReducer(state = etatinitial, action) {
  switch (action.type) {
    case GET_USER:
      return action.payload
    default:
      return state
  }
}
