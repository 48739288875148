import styles from '../../../../../css/modehotes/annonce/details/navigation.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'

const Navigation = () => {
  const history = useHistory()
  const location = useLocation()
  const id = location.pathname.split('/')[4]
  const path = location.pathname.split('/')[5]
  const Query = location.search

  return (
    <div className={styles.links_page}>
      <button
        onClick={() => history.push(`/hosting/listings/details/${id}${Query}`)}
        className={!path ? styles.active : null}
        disabled={!path}
      >
        Détails de l'annonce
      </button>
      <button
        onClick={() =>
          history.push(`/hosting/listings/details/${id}/pricing-and-availability${Query}`)
        }
        className={path === 'pricing-and-availability' ? styles.active : null}
        disabled={path === 'pricing-and-availability'}
      >
        Tarifs et disponibilité
      </button>
      <button
        onClick={() => history.push(`/hosting/listings/details/${id}/policies-and-rules${Query}`)}
        className={path === 'policies-and-rules' ? styles.active : null}
        disabled={path === 'policies-and-rules'}
      >
        Conditions et règlements
      </button>
      <button
        onClick={() => history.push(`/hosting/listings/details/${id}/info-for-guests${Query}`)}
        className={path === 'info-for-guests' ? styles.active : null}
        disabled={path === 'info-for-guests'}
      >
        Informations pour les voyageurs
      </button>
    </div>
  )
}

export default Navigation
