import styles from '../../../../../../css/modehotes/statistics/evaluations/comment_modal.module.css'
import moment from 'moment'
import Modal from '../../../../../ui/Modal'
import CloseButton from '../../../../../ui/CloseButton'
import GowifmiPublish from './GowifmiPublish'

const CommentModal = ({ refetch, modal, comments, handleOpenModal }) => {
  return (
    <Modal isOpen={modal} onClose={handleOpenModal}>
      <div className={styles.modal_content}>
        <div className={styles.m_head}>
          <CloseButton onClick={handleOpenModal} />
          <div></div>
        </div>
        <div className={styles.m_body}>
          {comments.map((item, i) => (
            <div className='' key={i}>
              <div className={styles.profil_block_avis}>
                <a
                  href={`/profile/${item.user_id?.id}`}
                  target='_blank'
                  className={styles.image_block}
                  rel='noreferrer'
                >
                  {item.user_id?.picture ? (
                    <img src={item.user_id?.picture} alt='' />
                  ) : (
                    <h2>{item.user_id?.first_name?.slice(0, 1)}</h2>
                  )}
                </a>
                <div>
                  <h4>{item.user_id?.first_name}</h4>
                  <p>{moment(item.createdAt).format('LL').slice(2)}</p>
                </div>
              </div>
              <div>
                <p>{item.comment}</p>
              </div>
              <GowifmiPublish refetch={refetch} comment={item} />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default CommentModal
