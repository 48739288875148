import SetupStatus from '../../../../../default_components/SetupStatus'
import { ONLINE_PAYMENT_STATUS } from '../../../../../../../enums/booking/onlineStatus'

const SetupIntent = ({ reservation, setupIntent }) => {
  return (
    <div>
      <h3>Enrégistrement de coordonées bancaire sur stripe</h3>
      <SetupStatus state={setupIntent?.status} />
      {setupIntent?.status === ONLINE_PAYMENT_STATUS.SUCCEEDED && (
        <p>
          <strong>Message</strong> : Le traitement des détails de paiement est effectué avec succès.
        </p>
      )}
      {setupIntent?.status === ONLINE_PAYMENT_STATUS.PROCESSING && (
        <p>
          <strong>Message</strong> : Le traitement des détails de paiement est en cours. <br />
          Nous vous informerons par cette adresse e-mail ( {reservation.user_id?.email} ) lorsque le
          traitement sera terminé
        </p>
      )}
      {setupIntent?.status === ONLINE_PAYMENT_STATUS.REJECTED && (
        <p>
          <strong>Message</strong> : Echec du traitement des détails de paiement. <br />
          Veuillez essayer un autre mode de paiement.
        </p>
      )}
      <p>
        <strong>ID</strong> : {setupIntent?.stripe_id}
      </p>
    </div>
  )
}

export default SetupIntent
