import React, { useState } from 'react'
import styles from '../../css/filter_booking/dateFilter.module.css'
import moment from 'moment'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { useHistory } from 'react-router-dom'
import Modal from '../ui/Modal'
import CloseButton from '../ui/CloseButton'

const CalendarFilter = React.forwardRef(({}, ref) => {
  const [modal, setModal] = useState(false)
  const history = useHistory()
  const pathname = window.location.pathname
  const query = window.location.search
  const params = new URLSearchParams(query)
  const checkin = params.get('checkin')
  const checkout = params.get('checkout')

  const [dateRange, setDateRange] = useState([
    checkin ? moment(checkin) : moment(),
    checkout ? moment(checkout) : moment(),
  ])

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))

  const handleCloseModal = () => {
    setModal(false)
  }

  const removeDates = () => {
    params.delete('page')
    params.delete('checkin')
    params.delete('checkout')
    history.push(`${pathname}?${params}`)
    setDateRange([moment(), moment()])
    setModal(false)
  }

  const handleApply = () => {
    params.delete('page')
    params.set('checkin', moment(dateRange[0]).format('YYYY-MM-DD'))
    params.set('checkout', moment(dateRange[1]).format('YYYY-MM-DD'))
    history.push(`${pathname}?${params}`)
    setModal(false)
  }

  return (
    <>
      <Modal isOpen={modal} onClose={handleCloseModal}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={handleCloseModal} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div className={styles.dateContent}>
              <h4>Sélectionnez les dates</h4>
              <div>
                <div className={styles.calendar_head}>
                  <div className={styles.gauch}>
                    <p>Réservations qui commencent ou prennent fin entre les dates suivantes.</p>
                    <p>
                      {moment(dateRange[0]).format('ll')} – {moment(dateRange[1]).format('ll')}
                    </p>
                  </div>
                </div>
              </div>
              <Calendar
                onChange={setDateRange}
                value={dateRange}
                selectRange={true}
                showNeighboringMonth={false}
                view='month'
              />
            </div>
          </div>
          <div className={styles.btn_action}>
            <button onClick={removeDates}>Effacer</button>
            <button onClick={handleApply}>Appliquer</button>
          </div>
        </div>
      </Modal>
    </>
  )
})
export default CalendarFilter
