import { useState } from 'react'
import styles from '../../../css/modehotes/statistics/listing.module.css'
import axios from 'axios'
import moment from 'moment'
import { useQuery } from 'react-query'
import CloseButton from '../../ui/CloseButton'
import Modal from '../../ui/Modal'
import LoadingPoint from '../../LoadingPoint'
import GodownSvg from '../../svgs/GodownSvg'

const ListingHotel = ({ selectedListing, setSelectedListing }) => {
  const [modal, setModal] = useState(false)

  const {
    data = [],
    isLoading,
    error,
  } = useQuery(['get-host_hotel'], () =>
    axios.get(`/gowifmi_hotels/all_hotel_publish`).then(res => {
      return res.data
    })
  )

  const handleSelectHotel = id => {
    setSelectedListing(id)
    setModal(false)
  }

  const listingActive = data.find(el => el._id === selectedListing)?.title || 'Nouvelle'

  if (error) return <p>error</p>

  return (
    <div className={styles.container}>
      {isLoading ? (
        <LoadingPoint />
      ) : (
        <div className={styles.container_title}>
          <button type='button' onClick={() => setModal(true)}>
            <p>{selectedListing ? listingActive : 'Toutes les annonces'}</p>
            <div>
              <GodownSvg />
            </div>
          </button>
        </div>
      )}

      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <h3>Sélectionnez une annonce</h3>
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div className={styles.m_body_title}>
              <button type='button' onClick={() => handleSelectHotel('')}>
                <p className={selectedListing === '' ? styles.active : null}>Toutes les annonces</p>
                {!selectedListing && <CheckSvg />}
              </button>
            </div>
            <div className={styles.all_list}>
              {data?.map(el => (
                <button
                  key={el._id}
                  className={selectedListing === el._id ? styles.active : null}
                  onClick={() => handleSelectHotel(el._id)}
                >
                  <div className={styles.lists_block}>
                    <div>
                      <div className={styles.lists_card}>
                        {el.images[0]?.url ? (
                          <img src={el.images[0]?.url} alt='' />
                        ) : (
                          <svg
                            viewBox='0 0 16 16'
                            xmlns='http://www.w3.org/2000/svg'
                            aria-hidden='true'
                            role='presentation'
                            focusable='false'
                          >
                            <path d='M8.602 1.147l.093.08 7.153 6.914-.696.718L14 7.745V14.5a.5.5 0 0 1-.41.492L13.5 15H10V9.5a.5.5 0 0 0-.41-.492L9.5 9h-3a.5.5 0 0 0-.492.41L6 9.5V15H2.5a.5.5 0 0 1-.492-.41L2 14.5V7.745L.847 8.86l-.696-.718 7.153-6.915a1 1 0 0 1 1.297-.08z'></path>
                          </svg>
                        )}
                      </div>
                    </div>
                    <div className={styles.lists_text}>
                      <h4>{el.title || 'Nouvelle'}</h4>
                      <p>{moment(el.createdAt).format('ll')}</p>
                    </div>
                  </div>
                  {selectedListing === el._id && <CheckSvg />}
                </button>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const CheckSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
      style={{
        display: 'block',
        height: '20px',
        width: '20px',
        stroke: 'currentColor',
        strokeWidth: '2',
        overflow: 'visible',
        fill: 'none',
      }}
      aria-label='Inclus avec Gowifmi'
      role='img'
      focusable='false'
    >
      <path fill='none' d='m4 16.5 8 8 16-16'></path>
    </svg>
  )
}

export default ListingHotel
