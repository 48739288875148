import { useEffect, useState, useContext } from 'react'
import styles from '../../../css/accounts/info_perso.module.css'
import { useHistory } from 'react-router-dom'
import { User } from '../../../context/UserContext'
import LegaleName from '../../../components/accounts/info_perso/LegaleName'
import Gender from '../../../components/accounts/info_perso/Gender'
import DateOfBirth from '../../../components/accounts/info_perso/DateOfBirth'
import Email from '../../../components/accounts/info_perso/Email'
import Telephone from '../../../components/accounts/info_perso/Telephone'
import Adresse from '../../../components/accounts/info_perso/Adresse'
import AppLayout from '../../../layouts/AppLayout'
import AllAccountSetings from '../../../components/accounts/AllAccountSetings'

const Info_perso = () => {
  const [active, setActive] = useState()

  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
  }, [])

  return (
    <AppLayout>
      <title>Informations personnelles paramètre Gowifmi</title>
      <div className={styles.seting_container}>
        <div className={styles.seting_box}>
          <div className={styles.AllAccount_setings}>
            <AllAccountSetings />
          </div>
          <div className={styles.separate}> </div>
          <div className={styles.seting_box_1}>
            <div>
              <h1>Informations personnelles</h1>
            </div>
            <LegaleName active={active} chageActive={setActive} />
            <Gender active={active} chageActive={setActive} />
            <DateOfBirth active={active} chageActive={setActive} />
            <Email active={active} chageActive={setActive} />
            <Telephone active={active} chageActive={setActive} />
            <Adresse active={active} chageActive={setActive} />
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

export default Info_perso
