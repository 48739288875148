import styles from '../../../../css/modehotes/history/list_history.module.css'
import { TYPE_HISTORY } from '../../../../enums/logement/type_history.enums'

const ChangeZone = ({ history }) => {
  const changes = history.changes

  return (
    <div className={styles.modified_block}>
      {changes?.status && (
        <p>
          Statut : <span>{changes?.status}</span>
        </p>
      )}
      {changes?.title && (
        <p>
          Titre : <span>{changes?.title}</span>
        </p>
      )}
      {changes?.advantage_marketing && (
        <p>
          Avantage Marketing : <span>{changes?.advantage_marketing}</span>
        </p>
      )}
      {changes?.property_type_group && (
        <p>
          Groupe de Type de Propriété : <span>{changes?.property_type_group}</span>
        </p>
      )}
      {changes?.property_type && (
        <p>
          Type de Propriété : <span>{changes?.property_type}</span>
        </p>
      )}
      {changes?.accommodation_type && (
        <p>
          Type d'Hébergement : <span>{changes?.accommodation_type}</span>
        </p>
      )}
      {changes?.usage_home_type && (
        <p>
          Type de Maison : <span>{changes?.usage_home_type}</span>
        </p>
      )}
      {changes?.amenities && (
        <div>
          <p>
            Équipements : <span>{changes?.amenities.join(', ')}</span>
          </p>
          <p>({changes?.amenities.length} équipements au total)</p>
        </div>
      )}
      {changes?.about && (
        <div>
          {changes?.about?.presentation && (
            <p>
              Présentation : <span>{changes?.about?.presentation}</span>
            </p>
          )}
          {changes?.about?.leisure_sports && (
            <p>
              Loisirs et Sports : <span>{changes?.about?.leisure_sports}</span>
            </p>
          )}
          {changes?.about?.for_children && (
            <p>
              Pour les Enfants : <span>{changes?.about?.for_children}</span>
            </p>
          )}
          {changes?.about?.enhanced_cleaning !== undefined && (
            <p>
              Nettoyage Amélioré : <span>{changes?.about?.enhanced_cleaning ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.about?.host_activity_status && (
            <p>
              Statut de l'Hôte : <span>{changes?.about?.host_activity_status}</span>
            </p>
          )}
        </div>
      )}
      {changes?.traveler_safety && (
        <div>
          {changes?.traveler_safety?.pool_jacuzzi_unfenced_unlocked !== undefined && (
            <p>
              Piscine/Jacuzzi non clôturé et non verrouillé :{' '}
              <span>
                {changes?.traveler_safety?.pool_jacuzzi_unfenced_unlocked ? 'Oui' : 'Non'}
              </span>
            </p>
          )}
          {changes?.traveler_safety?.lake_river_other_water_body_nearby !== undefined && (
            <p>
              Lac/Rivière à proximité :{' '}
              <span>
                {changes?.traveler_safety?.lake_river_other_water_body_nearby ? 'Oui' : 'Non'}
              </span>
            </p>
          )}
          {changes?.traveler_safety?.playground_climbing_structure !== undefined && (
            <p>
              Structure de Jeux :{' '}
              <span>{changes?.traveler_safety?.playground_climbing_structure ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.traveler_safety?.dangerous_animals !== undefined && (
            <p>
              Animaux Dangereux :{' '}
              <span>{changes?.traveler_safety?.dangerous_animals ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.traveler_safety?.cameras !== undefined && (
            <p>
              Caméras : <span>{changes?.traveler_safety?.cameras ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.traveler_safety?.weapon !== undefined && (
            <p>
              Arme : <span>{changes?.traveler_safety?.weapon ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.traveler_safety?.dwelling_includes_stairs_or_staircases !== undefined && (
            <p>
              Inclut des Escaliers :{' '}
              <span>
                {changes?.traveler_safety?.dwelling_includes_stairs_or_staircases ? 'Oui' : 'Non'}
              </span>
            </p>
          )}
          {changes?.traveler_safety?.height_unbarriered_unprotected !== undefined && (
            <p>
              Hauteur non Protégée :{' '}
              <span>
                {changes?.traveler_safety?.height_unbarriered_unprotected ? 'Oui' : 'Non'}
              </span>
            </p>
          )}
          {changes?.traveler_safety?.possible_noise_nuisances !== undefined && (
            <p>
              Nuisances Sonores Possibles :{' '}
              <span>{changes?.traveler_safety?.possible_noise_nuisances ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.traveler_safety?.pets_on_property !== undefined && (
            <p>
              Animaux sur la Propriété :{' '}
              <span>{changes?.traveler_safety?.pets_on_property ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.traveler_safety?.no_on_site_parking !== undefined && (
            <p>
              Pas de Parking sur le Site :{' '}
              <span>{changes?.traveler_safety?.no_on_site_parking ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.traveler_safety?.some_spaces_shared !== undefined && (
            <p>
              Certains Espaces Partagés :{' '}
              <span>{changes?.traveler_safety?.some_spaces_shared ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.traveler_safety?.applicable_equipment_restrictions !== undefined && (
            <p>
              Restrictions d'Équipement Applicables :{' '}
              <span>
                {changes?.traveler_safety?.applicable_equipment_restrictions ? 'Oui' : 'Non'}
              </span>
            </p>
          )}
        </div>
      )}
      {changes?.details && (
        <div>
          {changes?.details?.traveler !== undefined && (
            <p>
              Voyageurs : <span>{changes?.details?.traveler}</span>
            </p>
          )}
          {changes?.details?.number_of_rooms !== undefined && (
            <p>
              Nombre de Chambres : <span>{changes?.details?.number_of_rooms}</span>
            </p>
          )}
          {changes?.details?.number_of_beds !== undefined && (
            <p>
              Nombre de Lits : <span>{changes?.details?.number_of_beds}</span>
            </p>
          )}
          {changes?.details?.number_of_bathrooms !== undefined && (
            <p>
              Nombre de Salles de Bain : <span>{changes?.details?.number_of_bathrooms}</span>
            </p>
          )}
          {changes?.details?.panoramic_views && (
            <p>
              Vues Panoramiques : <span>{changes?.details?.panoramic_views.join(', ')}</span>
            </p>
          )}
        </div>
      )}
      {changes?.prices && (
        <div>
          {changes?.prices?.nightly_price !== undefined && (
            <p>
              Prix par Nuit : <span>${changes?.prices?.nightly_price}</span>
            </p>
          )}
          {changes?.prices?.cleaning_fee !== undefined && (
            <p>
              Frais de Nettoyage : <span>${changes?.prices?.cleaning_fee}</span>
            </p>
          )}
          {changes?.prices?.security_deposit !== undefined && (
            <p>
              Dépôt de Garantie : <span>${changes?.prices?.security_deposit}</span>
            </p>
          )}
          {changes?.prices?.additional_guest_fee !== undefined && (
            <p>
              Frais Supplémentaires par Invité :{' '}
              <span>${changes?.prices?.additional_guest_fee}</span>
            </p>
          )}
          {changes?.prices?.stay_fee !== undefined && (
            <p>
              Frais de Séjour : <span>${changes?.prices?.stay_fee}</span>
            </p>
          )}
          {changes?.prices?.currency && (
            <p>
              Devise : <span>${changes?.prices?.currency}</span>
            </p>
          )}
        </div>
      )}
      {changes?.discounts && (
        <div>
          {changes?.discounts?.nightly !== undefined && (
            <p>
              Remise de Nuit : <span>{changes?.discounts?.nightly}%</span>
            </p>
          )}
          {changes?.discounts?.weekly !== undefined && (
            <p>
              Remise Hebdomadaire : <span>{changes?.discounts?.weekly}%</span>
            </p>
          )}
          {changes?.discounts?.monthly !== undefined && (
            <p>
              Remise Mensuelle : <span>{changes?.discounts?.monthly}%</span>
            </p>
          )}
        </div>
      )}
      {changes?.stay_duration && (
        <div>
          {changes?.stay_duration?.minimum !== undefined && (
            <p>
              Durée Minimale de Séjour : <span>{changes?.stay_duration?.minimum}</span>
            </p>
          )}
          {changes?.stay_duration?.maximum !== undefined && (
            <p>
              Durée Maximale de Séjour : <span>{changes?.stay_duration?.maximum}</span>
            </p>
          )}
        </div>
      )}
      {changes?.availability_calendar && (
        <div>
          {changes?.availability_calendar?.delai_booking_before_checkin !== undefined && (
            <p>
              Délai de Réservation avant Check-in :{' '}
              <span>{changes?.availability_calendar?.delai_booking_before_checkin}</span>
            </p>
          )}
          {changes?.availability_calendar?.preparation_time !== undefined && (
            <p>
              Temps de Préparation : <span>{changes?.availability_calendar?.preparation_time}</span>
            </p>
          )}
          {changes?.availability_calendar?.availability_range !== undefined && (
            <p>
              Plage de Disponibilité :{' '}
              <span>{changes?.availability_calendar?.availability_range} mois</span>
            </p>
          )}
          {changes?.availability_calendar?.prohibited_checkin_day && (
            <p>
              Jour de Check-in Interdit :{' '}
              <span>{changes?.availability_calendar?.prohibited_checkin_day.join(', ')}</span>
            </p>
          )}
          {changes?.availability_calendar?.prohibited_checkout_day && (
            <p>
              Jour de Check-out Interdit :{' '}
              <span>{changes?.availability_calendar?.prohibited_checkout_day.join(', ')}</span>
            </p>
          )}
        </div>
      )}
      {changes?.regulations && (
        <div>
          {changes?.regulations?.cancellation_policy && (
            <p>
              Politique d'Annulation : <span>{changes?.regulations?.cancellation_policy}</span>
            </p>
          )}
          {changes?.regulations?.pay_online !== undefined && (
            <p>
              Paiement en Ligne : <span>{changes?.regulations?.pay_online ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.regulations?.pay_at_host !== undefined && (
            <p>
              Paiement chez l'Hôte :{' '}
              <span>{changes?.regulations?.pay_at_host ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.regulations?.instant_booking !== undefined && (
            <p>
              Réservation Instantanée :{' '}
              <span>{changes?.regulations?.instant_booking ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.regulations?.check_in_1 && (
            <p>
              Check-in 1 : <span>{changes?.regulations?.check_in_1}</span>
            </p>
          )}
          {changes?.regulations?.check_in_2 && (
            <p>
              Check-in 2 : <span>{changes?.regulations?.check_in_2}</span>
            </p>
          )}
          {changes?.regulations?.check_out && (
            <p>
              Check-out : <span>{changes?.regulations?.check_out}</span>
            </p>
          )}
          {changes?.regulations?.accept_children !== undefined && (
            <p>
              Acceptation des Enfants :{' '}
              <span>{changes?.regulations?.accept_children ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.regulations?.accept_babies !== undefined && (
            <p>
              Acceptation des Bébés :{' '}
              <span>{changes?.regulations?.accept_babies ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.regulations?.accept_pets !== undefined && (
            <p>
              Acceptation des Animaux :{' '}
              <span>{changes?.regulations?.accept_pets ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.regulations?.smoking_allowed !== undefined && (
            <p>
              Fumeur : <span>{changes?.regulations?.smoking_allowed ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.regulations?.accept_events !== undefined && (
            <p>
              Acceptation des Événements :{' '}
              <span>{changes?.regulations?.accept_events ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.regulations?.accommodation_rules && (
            <p>
              Règles d'Hébergement : <span>{changes?.regulations?.accommodation_rules}</span>
            </p>
          )}
        </div>
      )}
      {changes?.traveler_information && (
        <div>
          {changes?.traveler_information?.neighborhood_description && (
            <p>
              Description du Quartier :{' '}
              <span>{changes?.traveler_information?.neighborhood_description}</span>
            </p>
          )}
          {changes?.traveler_information?.getting_around && (
            <p>
              Se Déplacer : <span>{changes?.traveler_information?.getting_around}</span>
            </p>
          )}
          {changes?.traveler_information?.arrival_instructions && (
            <p>
              Instructions d'Arrivée :{' '}
              <span>{changes?.traveler_information?.arrival_instructions}</span>
            </p>
          )}
          {changes?.traveler_information?.arrival_instructions_message && (
            <p>
              Message d'Instructions d'Arrivée :{' '}
              <span>{changes?.traveler_information?.arrival_instructions_message}</span>
            </p>
          )}
          {changes?.traveler_information?.wifi_name && (
            <p>
              Nom du Wi-Fi : <span>{changes?.traveler_information?.wifi_name}</span>
            </p>
          )}
          {changes?.traveler_information?.wifi_password && (
            <p>
              Mot de Passe Wi-Fi : <span>{changes?.traveler_information?.wifi_password}</span>
            </p>
          )}
          {changes?.traveler_information?.itinerary && (
            <p>
              Itinéraire : <span>{changes?.traveler_information?.itinerary}</span>
            </p>
          )}
          {changes?.traveler_information?.traveler_manual && (
            <p>
              Manuel du Voyageur : <span>{changes?.traveler_information?.traveler_manual}</span>
            </p>
          )}
        </div>
      )}
      {changes?.languages && (
        <div>
          <p>
            Langues : <span>{changes?.languages.join(', ')}</span>
          </p>
          <p>({changes?.languages.length} langues au total)</p>
        </div>
      )}
      {changes?.address && (
        <div>
          {changes?.address?.full_address && (
            <p>
              Adresse complète : <span>{changes?.address?.full_address}</span>
            </p>
          )}
          {changes?.address?.street && (
            <p>
              Rue : <span>{changes?.address?.street}</span>
            </p>
          )}
          {changes?.address?.apartment_name && (
            <p>
              Nom de l'Appartement : <span>{changes?.address?.apartment_name}</span>
            </p>
          )}
          {changes?.address?.city && (
            <p>
              Ville : <span>{changes?.address?.city}</span>
            </p>
          )}
          {changes?.address?.state && (
            <p>
              État : <span>{changes?.address?.state}</span>
            </p>
          )}
          {changes?.address?.postal_code && (
            <p>
              Code Postal : <span>{changes?.address?.postal_code}</span>
            </p>
          )}
          {changes?.address?.country && (
            <p>
              Pays : <span>{changes?.address?.country}</span>
            </p>
          )}
          {changes?.address?.country_code && (
            <p>
              Code du Pays : <span>{changes?.address?.country_code}</span>
            </p>
          )}
        </div>
      )}
      {changes?.images && (
        <div>
          {history.type === TYPE_HISTORY.ADD_IMAGE && <p>Image ajoutée</p>}
          {history.type === TYPE_HISTORY.UPDATE_IMAGE && <p>Image modifiée </p>}
          {history.type === TYPE_HISTORY.REORGANIZE_IMAGE && <p>Images Réorganiser </p>}
          {history.type === TYPE_HISTORY.DELETE_IMAGE && <p>Image supprimée</p>}
          <div className={styles.img_container}>
            {changes?.images.slice(0, 10).map((image, index) => (
              <div key={index}>
                <div className={styles.img_block}>
                  <img src={image.url} alt={image.caption ?? 'photo'} />
                </div>
                <p className={styles.img_caption}>
                  <span>{image.caption}</span>
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
      {changes?.redirect_url && (
        <p>
          URL de Redirection : <span>{changes?.redirect_url}</span>
        </p>
      )}
      {changes?.custom_url && (
        <p>
          URL Personnalisée : <span>{changes?.custom_url}</span>
        </p>
      )}
      {changes?.gowifmi_published !== undefined && (
        <p>
          Publié sur GoWiFmi : <span>{changes?.gowifmi_published ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {changes?.gowifmi_publish_date && (
        <p>
          Date de Publication sur GoWiFmi :{' '}
          <span>{new Date(changes?.gowifmi_publish_date).toLocaleDateString()}</span>
        </p>
      )}
      {changes?.deactivate_reason && (
        <div>
          {changes?.deactivate_reason?.deactivate_by && (
            <p>
              Désactivé par : <span>{changes?.deactivate_reason?.deactivate_by}</span>
            </p>
          )}
          {changes?.deactivate_reason?.raison_1 && (
            <p>
              Raison : <span>{changes?.deactivate_reason?.raison_1}</span>
            </p>
          )}
          {changes?.deactivate_reason?.message && (
            <p>
              Message : <span>{changes?.deactivate_reason?.message}</span>
            </p>
          )}
          {changes?.deactivate_reason?.date && (
            <p>
              Date de Désactivation :{' '}
              <span>{new Date(changes?.deactivate_reason?.date).toLocaleDateString()}</span>
            </p>
          )}
        </div>
      )}
    </div>
  )
}

export default ChangeZone
