import Tooltip from '../../../../../../ui/Tooltip'
import { Link } from 'react-router-dom'

const History = ({ annonce }) => {
  return (
    <Tooltip title="Historique de l'annonce">
      <div>
        <Link to={`/hosting/accomodation-history?hotel=${annonce._id}`}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 32 32'
            aria-hidden='true'
            role='presentation'
            focusable='false'
            style={{
              display: 'block',
              fill: 'none',
              height: '16px',
              width: '16px',
              stroke: 'currentcolor',
              strokeWidth: '3',
              overflow: 'visible',
            }}
          >
            <g fill='none'>
              <path d='M2 16h28M2 24h28M2 8h28'></path>
            </g>
          </svg>
        </Link>
      </div>
    </Tooltip>
  )
}

export default History
