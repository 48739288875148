import { useContext } from 'react'
import styles from '../../css/mains/detail.module.css'
import { Link, Redirect, useRouteMatch } from 'react-router-dom'
import axios from 'axios'
import AppLayout from '../../layouts/AppLayout'
import Loading from '../../components/Loading'
import IdentityVerification from '../../components/users/details/IdentityVerification'
import { useQuery } from 'react-query'
import BasicInfo from '../../components/users/details/BasicInfo'
import ValidationInfo from '../../components/users/details/ValidationInfo'
import ConnexionInfo from '../../components/users/details/ConnexionInfo'
import StripeConnect from '../../components/users/details/StripeConnect'
import { User } from '../../context/UserContext'
import DeleteUser from '../../components/users/details/DeleteUser'

function DetailUser() {
  const user = useContext(User)

  const match = useRouteMatch('/users/details/:slug/')
  const slug = match?.params?.slug
  const { refetch, isLoading, error, data } = useQuery(`getuser${slug}`, () =>
    axios.get(`user/${slug}`).then(res => {
      return res.data
    })
  )

  if (user === null) {
    return <Redirect to='/' />
  }
  if (error) {
    console.log('err')
  }

  return (
    <AppLayout>
      <div className={styles.block_content}>
        <p>Utilisateur détails</p>
        {isLoading ? (
          <Loading />
        ) : (
          <div className={styles.container_bouton}>
            <div className={styles.detail_blog}>
              <div className={styles.detail_img}>
                <img src={data.picture} alt='' />
              </div>
              <div className={styles.detail_profil}>
                <h2>{data.name}</h2>
                <p>
                  Vous trouverez ici toutes les informations concernant le contact cité ci-dessous.
                  Vous avez aussi la possibilité d'afficher et imprimer sa fiche!
                </p>
              </div>
            </div>
            <div className={styles.detail_info}>
              <BasicInfo userData={data} />
              <ValidationInfo userData={data} refetch={refetch} />
              <ConnexionInfo userData={data} />
              <StripeConnect userData={data} />
            </div>
            <IdentityVerification userData={data} refetch={refetch} />
            <div className={styles.modif_link}>
              <Link to={`/users/update/${data.id}/`}>Modifier</Link>
              <Link to={`/users/user-history?user=${data.id}`}>Histriques d'activités</Link>
            </div>
            <DeleteUser userData={data} />
          </div>
        )}
      </div>
    </AppLayout>
  )
}
export default DetailUser
