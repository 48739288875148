import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import Options from './Options'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const DelaiReservation = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [delaiReservation, setDelaiReservation] = useState(Number())
  const { loading, updateData } = useUpdateHotel(setOpen)

  const delai = annonce.availability_calendar?.delai_booking_before_checkin
  const handleSubmit = () => {
    const datas = {
      availability_calendar: {
        ...annonce.availability_calendar,
        delai_booking_before_checkin: Number(delaiReservation),
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setDelaiReservation(annonce.availability_calendar?.delai_booking_before_checkin)
    setOpen(true)
  }
  const reset = () => {
    setDelaiReservation()
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      <h2>Disponibilités du calendrier</h2>
      {!open ? (
        <Section title="Délai de réservation avant l'arrivée" handleOpen={openFuction}>
          <p>
            {delai > 0
              ? `Au moins ${delai} ${delai > 1 ? 'jours' : 'jour'} à l'avance`
              : "Le jour même, jusqu'à 00:00"}
          </p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title="Délai de réservation avant l'arrivée" handleReset={reset}>
            <p>
              {delaiReservation > 0
                ? `Les voyageurs ne peuvent pas réserver instantanément si leur séjour commence dans moins de ${delaiReservation} jours, mais ils peuvent vous envoyer une demande de réservation.`
                : "Les voyageurs peuvent réserver le jour même jusqu'à 00:00"}
            </p>
          </ContenHead>
          <div className={styles.body}>
            <div className={styles.input_block}>
              <select
                defaultValue={delaiReservation}
                onChange={e => setDelaiReservation(e.target.value)}
              >
                <Options />
              </select>
            </div>
          </div>
          <BottomForm
            handleSubmit={handleSubmit}
            disabled={
              delaiReservation === annonce.availability_calendar?.delai_booking_before_checkin
            }
            loading={loading}
            handleReset={reset}
          />
        </div>
      )}
    </div>
  )
}

export default DelaiReservation
