import styles from '../../../../../css/modehotes/reservation/payment_error_status.module.css'
import BigErrorSvg from '../../../../svgs/BigErrorSvg'
import { ONLINE_PAYMENT_STATUS } from '../../../../../enums/booking/onlineStatus'

const StatusPaymentIntent = ({ payOnline, paymentIntentObject }) => {
  if (
    !payOnline ||
    !paymentIntentObject ||
    paymentIntentObject?.status === ONLINE_PAYMENT_STATUS.SUCCEEDED
  )
    return null

  return (
    <div className={styles.container}>
      {paymentIntentObject?.status === ONLINE_PAYMENT_STATUS.PROCESSING && (
        <div className={styles.processing}>
          <h2>Le paiement est en cours de traitement</h2>
          <p>Nous enverron l'état de la réservation au voyageur par e-mail.</p>
        </div>
      )}
      {paymentIntentObject?.status === ONLINE_PAYMENT_STATUS.REJECTED && (
        <div className={styles.echec}>
          <div>
            <BigErrorSvg />
          </div>
          <div>
            <h2>Le paiement n'a pas abouti</h2>
            <p>
              Une erreur s'est produite lors de paiement en ligne veuillez invité le voyageur à
              réessayer avec un autre mode de paiement.
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
export default StatusPaymentIntent
