import { useState } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { getAnnonce } from '../redux/actions'

function useUpdateHotel(setOpen, setError) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const path = location.pathname.split('/')[4]
  const dispatch = useDispatch()

  const updateData = async data => {
    setLoading(true)
    setError && setError(null)
    return axios
      .patch(`/gowifmi_hotels/${path}`, data)
      .then(res => {
        setData(res.data)
        dispatch(getAnnonce(res.data))
        setOpen && setOpen(false)
        return
      })
      .catch(err => {
        setError && setError(err)
        alert("Une erreur s'est produite veuillez réessayer")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updateAndPublish = async data => {
    setLoading(true)
    return axios
      .patch(`/gowifmi_hotels/publish/${path}`, data)
      .then(res => {
        setData(res.data)
        dispatch(getAnnonce(res.data))
        return
      })
      .catch(err => {
        alert("Une erreur s'est produite veuillez réessayer")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return { data, loading, updateData, updateAndPublish }
}

export default useUpdateHotel
