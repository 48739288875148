import React, { useState } from 'react'
import styles from '../../../../../../../css/modehotes/reservation/transfer_modal.module.css'
import axios from 'axios'
import LoadingPoint from '../../../../../../LoadingPoint'
import Toast from '../../../../../../infos/toast'
import { useForm } from 'react-hook-form'
import { STATUS_STRANSFER } from '../../../../../../../enums/booking/transferStatus.enums'
import { STATUS_BOOKING } from '../../../../../../../enums/booking/status.enums'
import CloseButton from '../../../../../../ui/CloseButton'
import Modal from '../../../../../../ui/Modal'

const ManualModal = React.forwardRef(({ reservation, calculatedPrices, refetch }, ref) => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)

  const host_id = reservation.host_id.id
  const traveler_id = reservation.user_id.id
  const reservation_id = reservation._id

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))

  const { totalStayBeforRefund } = calculatedPrices

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange' })
  const onSubmit = Formdata => {
    const data = {
      status: Formdata.status,
      transfer_service: Formdata.transfer_service,
      transfer_id: Formdata.transfer_id,
      prices: {
        total_stay_fee: totalStayBeforRefund.toFixed(2),
        service_fee: Formdata.service_fee,
        host_fee: Formdata.host_fee,
        currency: 'usd',
      },
      comment: Formdata.comment,
    }
    setLoading(true)
    axios
      .post(`/gowifmi_transfer_payment/to_host/${host_id}/${traveler_id}/${reservation_id}`, data)
      .then(async res => {
        Toast.fire({ icon: 'success', title: 'Versement ajouté avec succès' })
        await refetch()
        reset()
      })
      .catch(err => {
        if (err.response?.data?.message === 'transfer_exist') {
          Toast.fire({
            icon: 'error',
            title:
              "Il semble q'un tranfert vers cet hôte est en cours de traitement veuillez actualiser la page",
          })
        } else {
          Toast.fire({ icon: 'error', title: 'Un problème est survenu au niveau de serveur' })
        }
      })
      .finally(() => {
        setLoading(false)
        setModal(false)
      })
  }

  const closeModal = () => {
    setModal(false)
  }

  return (
    <>
      <Modal isOpen={modal} onClose={closeModal}>
        <form autoComplete='off' onSubmit={handleSubmit(onSubmit)} className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={closeModal} />
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div className={styles.m_body_head}>
              <h2>Ajouter un transfert pour l'hôte</h2>
              {STATUS_BOOKING.CANCELLED && (
                <p>
                  Dans le cas d'une annulation enregistrer le Total du séjour après remboursement.
                </p>
              )}
            </div>
            <div>
              <label htmlFor='status'>Statut *</label>
              <select
                id='status'
                defaultValue={reservation?.transfer_payment_to_host?.status}
                {...register('status', {
                  required: 'Status est obligatoire.',
                })}
              >
                <option value=''>Sélectionner le statut</option>
                <option value={STATUS_STRANSFER.COMPLETE}>Réussi</option>
                <option value={STATUS_STRANSFER.IN_PROGRESS}>Traitement</option>
                <option value={STATUS_STRANSFER.CANCELLED}>Annulé</option>
                <option value={STATUS_STRANSFER.REJECTED}>Echoué</option>
              </select>
              {errors.status && <p className={styles.error}>{errors.status.message}</p>}
            </div>
            <div>
              <label htmlFor='transfer_service'>Service *</label>
              <select
                id='transfer_service'
                defaultValue={reservation?.transfer_payment_to_host?.transfer_service}
                {...register('transfer_service', {
                  required: 'Transfert service est obligatoire.',
                })}
              >
                <option value=''>Sélectionner un service</option>
                <option value='stripe'>Stripe</option>
                <option value='paypal'>Paypal</option>
                <option value='payoneer'>Payoneer</option>
                <option value='mercury'>Mercury</option>
                <option value='to_host'>Chez l'hôte</option>
              </select>
              {errors.transfer_service && (
                <p className={styles.error}>{errors.transfer_service.message}</p>
              )}
            </div>

            <div>
              <label htmlFor='transfer_id'>Identifiant du transfert</label>
              <input
                type='text'
                id='transfer_id'
                defaultValue={reservation?.transfer_payment_to_host?.transfer_id}
                {...register('transfer_id')}
              />
            </div>

            <div>
              <label htmlFor='host_fee'>Revenu de l'hôte *</label>
              <input
                type='text'
                id='host_fee'
                defaultValue={reservation?.transfer_payment_to_host?.prices?.host_fee}
                {...register('host_fee', {
                  required: "Revenu de l'hôte est obligatoire.",
                })}
              />
              {errors.host_fee && <p className={styles.error}>{errors.host_fee.message}</p>}
            </div>

            <div>
              <label htmlFor='service_fee'>Frais de service Gowifmi *</label>
              <input
                type='text'
                id='service_fee'
                defaultValue={reservation?.transfer_payment_to_host?.prices?.service_fee}
                {...register('service_fee', {
                  required: 'Frais de service Gowifmi est obligatoire.',
                })}
              />
              {errors.service_fee && <p className={styles.error}>{errors.service_fee.message}</p>}
            </div>

            <div>
              <label htmlFor='comment'>Ajouter un commentaire</label>
              <textarea
                type='text'
                id='comment'
                defaultValue={reservation?.transfer_payment_to_host?.comment}
                placeholder='Commentaire...'
                {...register('comment')}
              ></textarea>
            </div>
          </div>
          <div className={styles.btn_action}>
            <button type='button' onClick={closeModal}>
              Annuler
            </button>
            {loading ? (
              <button type='button' disabled className={styles.loading}>
                <LoadingPoint />
              </button>
            ) : (
              <button type='submit'>Enregistrer le transfert</button>
            )}
          </div>
        </form>
      </Modal>
    </>
  )
})
export default ManualModal
