import { useState, useContext } from 'react'
import styles from '../../../css/modehotes/calendar/calendar.module.css'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { User } from '../../../context/UserContext'
import LoadingPoint from '../../../components/LoadingPoint'
import ListAnnonce from '../../../components/hote_mode/calendars/ListAnnonce'
import Calendar from '../../../components/hote_mode/calendars/CalendarComponent'
import AppLayout from '../../../layouts/AppLayout'

const CanlendarMain = () => {
  const [listActive, setListeActive] = useState(false)
  const user = useContext(User)
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname.split('/')[3]

  const {
    isLoading,
    data: annonce = null,
    error,
    refetch,
  } = useQuery(`hote-get-annonce-calendar-${path}`, () =>
    axios.get(`/gowifmi_hotels/oneAnnonce/${path}`).then(res => {
      return res.data
    })
  )

  if (error) {
    console.log("une erruer s'est produite")
  }
  if (user === null) {
    history.push('/')
  }

  return (
    <AppLayout>
      <title>Calendrier · {annonce?.title} - Gowifmi</title>
      <div className={styles.main}>
        <ListAnnonce listActive={listActive} setListeActive={setListeActive} />
        <div className={styles.droit}>
          {isLoading ? (
            <div className={styles.loading}>
              <LoadingPoint />
            </div>
          ) : (
            <div>
              <Calendar
                annonce={annonce}
                setListeActive={setListeActive}
                path={path}
                refetch={refetch}
              />
            </div>
          )}
        </div>
      </div>
    </AppLayout>
  )
}

export default CanlendarMain
