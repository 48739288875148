import { useEffect, useState } from 'react'
import axios from 'axios'

const useNotification = () => {
  const [annonces, setAnnonces] = useState([])

  useEffect(() => {
    getAllNotification()
  }, [])

  const getAllNotification = () => {
    axios
      .get('/notification-app')
      .then(res => {
        setAnnonces(res.data.annonceEnregistrer)
      })
      .catch(err => {})
  }

  const totalNotification = annonces > 0 ? 1 : 0

  return {
    totalNotification,
  }
}

export default useNotification
