import { useState, useEffect } from 'react'
import styles from '../../../../../css/modehotes/annonce/filtres/filtre.module.css'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import SearchInput from '../../../../ui/SearchInput'

let timeoutId

const ByHotelId = () => {
  const history = useHistory()
  const location = useLocation()
  const pathname = location.pathname
  const Query = location.search
  const params = new URLSearchParams(Query)
  const hotel = params.get('hotel')
  const [search, setSearch] = useState(hotel || '')
  const [searchUpdated, setSearchUpdated] = useState(false)

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    if (searchUpdated) {
      timeoutId = setTimeout(() => {
        if (search.length > 0) {
          params.delete('page')
          params.set('hotel', search)
          history.push(`${pathname}?${params}`)
        } else {
          params.delete('page')
          params.delete('hotel')
          history.push(`${pathname}?${params}`)
        }
      }, 700)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [search])

  return (
    <div className={styles.block_section_filter}>
      <h3>Id de l'annonce</h3>
      <SearchInput
        id='search_hotel'
        value={search}
        onChange={e => {
          setSearchUpdated(true)
          setSearch(e.target.value)
        }}
        onClick={() => {
          setSearchUpdated(true)
          setSearch('')
        }}
      />
    </div>
  )
}

export default ByHotelId
