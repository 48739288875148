import styles from '../../../../css/accounts/profile/profil_description.module.css'

const Verification = ({ getUser }) => {
  return (
    <div>
      <h2>Vérification de l'identité</h2>
      <p>Confirmez l'identité avec le badge de vérification d'identité.</p>
      <button
        className={
          getUser?.identitySubmitted
            ? `${styles.verify_button} ${styles.disabled}`
            : styles.verify_button
        }
      >
        <div>
          <svg
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
            style={{
              display: 'block',
              height: '18px',
              width: '18px',
              fill: 'currentColor',
            }}
          >
            <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z'></path>
          </svg>
        </div>
        {getUser?.identitySubmitted ? (
          <span>En attente d'approbation</span>
        ) : (
          <span>Obtenir le badge</span>
        )}
      </button>
    </div>
  )
}

export default Verification
