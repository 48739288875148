import styles from '../../../../css/affiliations/all_filter.module.css'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'

const ReferralProgression = () => {
  const NumberChambre = ['25', '50', '75', '100']
  const history = useHistory()
  const location = useLocation()
  const pathname = location.pathname
  const Query = location.search
  const params = new URLSearchParams(Query)

  const handleChambre = e => {
    params.delete('page')
    params.set('referral_progression', e.target.value)
    history.push(`${pathname}?${params}`)
  }
  const AllChambre = () => {
    params.delete('page')
    params.delete('referral_progression')
    history.push(`${pathname}?${params}`)
  }

  return (
    <div className={styles.block_section_filter}>
      <h3>Progression des filleuls</h3>
      <div className={styles.radio_container}>
        <label>
          <input checked={!params.get('referral_progression')} type='radio' onChange={AllChambre} />
          <span>Tout</span>
        </label>
        {NumberChambre.map(value => (
          <label key={value}>
            <input
              type='radio'
              value={value}
              checked={params.get('referral_progression') === value}
              onChange={handleChambre}
            />
            <span>{value}</span>
          </label>
        ))}
      </div>
    </div>
  )
}

export default ReferralProgression
