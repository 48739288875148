import { useState, useRef, useEffect } from 'react'
import styles from '../../../css/affiliations/sponsors/header.module.css'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import AllFilter from '../global_components/AllFilter'
import Tooltip from '../../ui/Tooltip'
import SearchInput from '../../ui/SearchInput'

let timeoutId

const Header = ({ isLoading, data }) => {
  const history = useHistory()
  const location = useLocation()
  const pathname = location.pathname
  const Query = location.search
  const params = new URLSearchParams(Query)
  const text = params.get('referrer')
  const [search, setSearch] = useState(text || '')
  const [searchUpdated, setSearchUpdated] = useState(false)

  const AllfilterRef = useRef()
  const Handlfilter = () => {
    AllfilterRef.current.OpenModal()
  }

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    if (searchUpdated) {
      timeoutId = setTimeout(() => {
        if (search.length > 0) {
          params.delete('page')
          params.set('referrer', search)
          history.push(`${pathname}?${params}`)
        } else {
          params.delete('page')
          params.delete('referrer')
          history.push(`${pathname}?${params}`)
        }
      }, 700)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [search])

  params.delete('page')
  const allFilterSelected = Array.from(params).length

  return (
    <>
      <div className={styles.container}>
        <h3>
          {data?.totalRecords} {data?.totalRecords > 1 ? 'Affiliations' : 'Affiliation'}
        </h3>
        <div className={styles.flter_block}>
          <div className={styles.all_filter}>
            <Tooltip title='Filtrer'>
              <button
                className={allFilterSelected > 0 ? styles.active : null}
                onClick={Handlfilter}
              >
                <svg
                  viewBox='0 0 16 16'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <path d='M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z'></path>
                </svg>
                <span className={styles.length}>{allFilterSelected}</span>
              </button>
            </Tooltip>
          </div>
          <div className={styles.search_block}>
            <SearchInput
              id='referrer'
              value={search}
              placeholder='Parrain ID'
              onChange={e => {
                setSearchUpdated(true)
                setSearch(e.target.value)
              }}
              onClick={() => {
                setSearchUpdated(true)
                setSearch('')
              }}
            />
          </div>
        </div>
      </div>
      <AllFilter ref={AllfilterRef} isLoading={isLoading} />
    </>
  )
}

export default Header
