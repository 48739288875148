import styles from '../../../css/mains/detail.module.css'
import { Link, Redirect, useRouteMatch } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import moment from 'moment'
import avatar from '../../../assets/avatar2.png'
import AppLayout from '../../../layouts/AppLayout'
import Loading from '../../../components/Loading'
import { User } from '../../../context/UserContext'

function DetailReportUser() {
  const match = useRouteMatch('/reports/users/:slug/')
  const [signalerUser, setSignalerUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const user = useContext(User)

  const slug = match?.params?.slug
  useEffect(() => {
    axios
      .get('/signalerUser/' + slug)
      .then(res => {
        setSignalerUser(res.data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }, [slug])

  if (user === null) {
    return <Redirect to='/' />
  }
  return (
    <AppLayout>
      <div className={styles.block_content}>
        <p>Détails de la signalisation</p>
        {loading ? (
          <Loading />
        ) : (
          <div className={styles.container_bouton}>
            <div className={styles.bouton}>
              <Link to='/feedbaks' title='Liste des rendez-vous'>
                <i className='fa fa-list-ul'></i>&nbsp; Liste des rendez-vous
              </Link>
              <Link to='/reports/users' title='Liste des Signaler utilisateurs'>
                <i className='fa fa-list-ul'></i>&nbsp; Liste des utilisateurs signalés
              </Link>
              <Link to='/reports/hotels' title='Liste des annonces signalés'>
                <i className='fa fa-list-ul'></i>&nbsp; Liste des annonces signalés
              </Link>
            </div>
            <div className={styles.detail_blog}>
              <div className={styles.detail_img}>
                <img src={avatar} alt='' />
              </div>
              <div className={styles.detail_profil}>
                <h2>{signalerUser.user?.first_name}</h2>
                <p>
                  Vous trouverez ici toutes les informations concernant le contacts cité ci-dessous.
                  Vous avez aussi la possibilité d'afficher et imprimer sa fiche!
                </p>
              </div>
            </div>
            <div className={styles.detail_info}>
              <div className={styles.detail_info_1}>
                <h2>Utilisateur qui à signaler</h2>
                <p>
                  Ici vous trouverez les informations les plus importantes le contacts que vous avez
                  séléctionné
                </p>
                <ul>
                  <li>
                    <i className='fa fa-user'></i> Prénom:{' '}
                    <span>{signalerUser.user?.first_name}</span>
                  </li>
                  <li>
                    <i className='fas fa-envelope'></i> Email:{' '}
                    <span>{signalerUser.user?.email}</span>
                  </li>
                  <li>
                    <i className='fas fa-phone'></i> Téléphone:{' '}
                    <span>{signalerUser.user?.phone_number}</span>
                  </li>
                  <li>
                    <i className='far fa-comment-alt'></i> Raison:{' '}
                  </li>
                  {signalerUser.raison_1 === '1' && <span>Cet utilisateur est offensant</span>}
                  {signalerUser.raison_1 === '2' && (
                    <span>
                      J'ai l'impression que cet utilisateur essaie de m'arnaquer ou de me spammer
                    </span>
                  )}
                  {signalerUser.raison_1 === 'other' && <span>Autre chose</span>}
                  <li>
                    <i className='far fa-comment-alt'></i> Méssage:{' '}
                    <span>{signalerUser.message}</span>
                  </li>
                </ul>
              </div>
              <div className={styles.detail_info_1}>
                <h2>Utilisateur signalé</h2>
                <p>
                  Ici vous trouverez les statistiques les plus importantes concernant le
                  signalerUser que vous avez séléctionné
                </p>
                <ul>
                  <li>
                    <i className='fa fa-user'></i> Prénom:{' '}
                    <span>{signalerUser.user_signal?.first_name}</span>
                  </li>
                  <li>
                    <i className='fas fa-envelope'></i> Email:{' '}
                    <span>{signalerUser.user_signal?.email}</span>
                  </li>
                  <li>
                    <i className='fas fa-phone'></i> Téléphone:{' '}
                    <span>{signalerUser.user_signal?.phone_number}</span>
                  </li>
                  <li>
                    <i className='fa fa-calendar-alt'></i> Date de création:{' '}
                    <span>{moment(signalerUser.createdAt).format('LLL')}</span>
                  </li>
                  <li>
                    <i className='fa fa-edit'></i> Date de modification:{' '}
                    <span>{moment(signalerUser.updatedAt).format('LLL')}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  )
}
export default DetailReportUser
