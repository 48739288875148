import styles from '../../../css/accounts/info_perso.module.css'
import LoadingPoint from '../../LoadingPoint'
import { useForm } from 'react-hook-form'
import useUserHook from '../../../hooks/useUserHook'
import { getMsakedEmail } from '../../../utils/maskString'

const Email = ({ active, chageActive }) => {
  const { userHook, loadingUpdate, updateUserEmail } = useUserHook(chageActive)
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange' })
  const onSubmit = data => {
    updateUserEmail(data)
  }

  return (
    <>
      <div
        id='email'
        className={
          active && active !== 4 ? `${styles.form_content} ${styles.disable}` : styles.form_content
        }
      >
        <div className={styles.text}>
          <h3>Adresse e-mail</h3>
          {active === 4 ? (
            <button type='button' onClick={() => chageActive()}>
              Annuler
            </button>
          ) : (
            <button type='button' disabled={active && active !== 4} onClick={() => chageActive(4)}>
              Modifier
            </button>
          )}
        </div>

        <div className={styles.modif_block}>
          {active !== 4 ? (
            <p>{userHook.email ? getMsakedEmail(userHook.email) : 'Information non fournie'}</p>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
              <p>Utilisez une adresse à laquelle vous aurez toujours accès.</p>
              <div className={styles.input_box_sexe}>
                <input
                  type='email'
                  {...register('email', { value: userHook.email, required: true })}
                />
                {errors.email && <span className={styles.erreur}>Email est obligotoire</span>}
              </div>
              {loadingUpdate ? (
                <button disabled className={styles.loading}>
                  <LoadingPoint />
                </button>
              ) : (
                <button type='submit'>Enregistrer</button>
              )}
            </form>
          )}
        </div>
      </div>
    </>
  )
}

export default Email
