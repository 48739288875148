import { useState, useContext } from 'react'
import styles from '../../css/logins/login.module.css'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { User, Refresh } from '../../context/UserContext'
import { Redirect } from 'react-router'
import LoadingPoint from '../../components/LoadingPoint'
import Toast from '../../components/infos/toast'

function TwoAuthentificator({ userId2FA }) {
  const [chargement, setChargement] = useState(false)

  const user = useContext(User)
  const refresh = useContext(Refresh)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ mode: 'onChange' })

  const onSubmit = formData => {
    const data = {
      userId: userId2FA,
      code: formData.code,
    }
    setChargement(true)
    axios
      .post('/auth/login_app_2fa', data)
      .then(res => {
        localStorage.setItem('jwt', res.data.jwt.token)
        axios.defaults.headers.authorization = 'Bearer ' + res.data.jwt.token
        refresh()
      })
      .catch(err => {
        if (err.response?.data?.message === 'invalid_credentials') {
          Toast.fire({ icon: 'error', title: "Information d'identification invalides" })
        } else {
          Toast.fire({ icon: 'error', title: 'Une erreur est arrivée veulliez réssayer' })
        }
        setChargement(false)
        reset()
      })
  }

  if (user) return <Redirect to='/' />

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.login_inputs}>
        <div>
          <label htmlFor='code'>Code</label>
          <input
            type='tel'
            id='code'
            name='code'
            autoComplete='off'
            placeholder='123 456'
            {...register('code', {
              required: 'code est Obligatoire.',
            })}
          />
          {errors.code && <p className={styles.error}>{errors.code.message}</p>}
        </div>
      </div>
      {chargement ? (
        <button className={styles.buttonload}>
          <LoadingPoint />
        </button>
      ) : (
        <button className={styles.login_submit} type='submit'>
          Se connecter
        </button>
      )}
    </form>
  )
}

export default TwoAuthentificator
