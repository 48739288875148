import styles from '../../../css/modehotes/reservation/component.module.css'
import PendingSvg from '../../svgs/PendingSvg'
import CheckSvg from '../../svgs/CheckSvg'

const BillStatus = ({ reservation }) => {
  return (
    <>
      {reservation.pay_online ? (
        <div className={styles.block}>
          <p className={styles.incomplet}>
            <span>---</span>
          </p>
        </div>
      ) : (
        <div>
          {reservation.bill_paid ? (
            <div className={styles.block}>
              <p className={styles.complet}>
                <span>Réglé</span> <CheckSvg />
              </p>
            </div>
          ) : (
            <div className={styles.block}>
              <p className={styles.incomplet}>
                <span>En attente</span> <PendingSvg />
              </p>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default BillStatus
