import styles from '../../../css/filter_booking/all_filter.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import Checkbox from '../../ui/Checkbox'

const SetupIntentStatus = () => {
  const array = [
    { name: 'Annulé', value: 'canceled' },
    { name: 'En traitement', value: 'processing' },
    { name: 'Action requise', value: 'requires_action' },
    { name: 'Capture requise', value: 'requires_capture' },
    { name: 'Confirmation requise', value: 'requires_confirmation' },
    { name: 'Méthode de paiement requise', value: 'requires_payment_method' },
    { name: 'Réussi', value: 'succeeded' },
  ]

  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  URLSearchParams.prototype.remove = function (key, value) {
    const entries = this.getAll(key)
    const newEntries = entries.filter(entry => entry !== value)
    this.delete(key)
    newEntries.forEach(newEntry => this.append(key, newEntry))
  }
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      Params.delete('page')
      Params.append('setup_intent', e.target.value)
      history.push(`${path}?${Params}`)
    } else {
      Params.delete('page')
      Params.remove('setup_intent', e.target.value)
      history.push(`${path}?${Params}`)
    }
  }

  return (
    <div className={styles.block_section_filter}>
      <h3>Statut du mode de paiement</h3>
      <div className={styles.grid_system}>
        {array.map(item => (
          <div key={item.name} className={styles.checkbox_container}>
            <div>
              <Checkbox
                id={item.name + 'setup'}
                value={item.value}
                checked={Params.getAll('setup_intent').includes(item.value)}
                onChange={onchangeFilters}
              />
            </div>
            <label htmlFor={item.name + 'setup'}>{item.name}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SetupIntentStatus
