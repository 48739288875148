import styles from '../../../css/mains/accounts/extenal_account.module.css'

function AccountConnect({ user }) {
  return (
    <div className={styles.container}>
      <a
        href={`https://dashboard.stripe.com/connect/accounts/${user?.account_connect_stripe}/activity`}
        target='_blanck'
      >
        Stripe connect
      </a>
    </div>
  )
}
export default AccountConnect
