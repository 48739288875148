import { useEffect, useContext, useState } from 'react'
import styles from '../../../css/modehotes/statistics/search_history/home.module.css'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { User } from '../../../context/UserContext'
import LoadingPoint from '../../../components/LoadingPoint'
import AppLayout from '../../../layouts/AppLayout'
import ErrorServer from '../../errors/ErrorServer'
import { useInfiniteQuery } from 'react-query'
import ListHistory from '../../../components/hote_mode/statistics/search_history/ListHistory'
import Header from '../../../components/hote_mode/statistics/Header'
import { useInView } from 'react-intersection-observer'
import SelectDate from '../../../components/hote_mode/statistics/SelectDate'

const SearchHotelHistory = () => {
  const [isFetching, setIsFetching] = useState(false)
  const [selectedDate, setSelectedDate] = useState(() => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1 // Les mois sont indexés de 0 à 11, donc ajoutez 1
    return `${year}-${month.toString().padStart(2, '0')}` // 'YYYY-MM'
  })

  const user = useContext(User)
  const history = useHistory()

  const Query = window.location.search
  const params = new URLSearchParams(Query)
  const userId = params.get('user')

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
  }, [history])

  const fetchHistory = ({ pageParam = 1 }) =>
    axios
      .get(
        `/gowifmi_search_hotel_history?user_id=${
          userId ?? ''
        }&page=${pageParam}&date=${selectedDate}`
      )
      .then(res => res.data)

  const { data, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(['get-search_hotel_history', userId, selectedDate], fetchHistory, {
      getNextPageParam: (lastPage, pages) => {
        const morePagesExist = lastPage.history.length === 10
        if (!morePagesExist) return false
        return pages.length + 1
      },
    })

  const { ref, inView } = useInView({
    threshold: 1.0,
  })

  useEffect(() => {
    if (inView && hasNextPage && !isFetching && !isFetchingNextPage) {
      setIsFetching(true)
      fetchNextPage().finally(() => setIsFetching(false))
    }
  }, [inView, hasNextPage, isFetchingNextPage])

  if (isError) return <ErrorServer />

  return (
    <AppLayout>
      <title>Historique des recherches d'hôtels - Gowifmi</title>
      <div className={styles.container}>
        <Header />
        {isLoading ? (
          <div className={styles.container_load_liste}>
            <LoadingPoint />
          </div>
        ) : (
          <div className={styles.main}>
            <h2 className={styles.head_title}>
              {data.pages[0].monthyTotalHistory} Recherches efféctuées
            </h2>
            <SelectDate selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
            {data.pages[0].history.length > 0 ? (
              <>
                {data.pages.map((page, index) => (
                  <ListHistory key={index} data={page.history} />
                ))}
                <div ref={ref} style={{ height: 1 }} />
                {isFetchingNextPage && (
                  <div className={styles.container_load_liste}>
                    <LoadingPoint />
                  </div>
                )}
              </>
            ) : (
              <div className={styles.no_result}>
                <p>Vous n'avez pas d'historiques pour le moment</p>
              </div>
            )}
          </div>
        )}
      </div>
    </AppLayout>
  )
}

export default SearchHotelHistory
