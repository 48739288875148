import styles from '../../../../../../css/modehotes/annonce/details/information.module.css'
import FreeModeAnnonce from '../../../../default_components/freemode/FreeModeAnnonce'

const Information = ({ annonce }) => {
  return (
    <div className={styles.block}>
      <div className={styles.head}>
        <h3>FreeMode</h3>
      </div>
      <div className={styles.main}>
        <FreeModeAnnonce annonce={annonce} />
      </div>
    </div>
  )
}

export default Information
