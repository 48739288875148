import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import ProcessBooking from '../../../../default_components/ProcessBooking'

const Progress = ({ reservation }) => {
  return (
    <div className={styles.content}>
      <h3>Progression de la réservation</h3>
      <ProcessBooking state={reservation.booking_processing} />
    </div>
  )
}

export default Progress
