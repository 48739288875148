import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import moment from 'moment'
import momentTimeZone from 'moment-timezone'
import { addCurrency } from '../../../../../../utils/addCurrency'
import PaymentIntent from './payments/PaymentIntent'
import RefundAfterModif from './RefundAfterModif'
import ModificationReservationStatus from '../../../../default_components/ModificationReservationStatus'

const Modification = ({ reservation, refetch }) => {
  const modifications = reservation?.modifications
  const userTimezone = momentTimeZone.tz.guess()

  return (
    <>
      <div className={styles.content}>
        <h3>Modification de la réservation</h3>
        {modifications.length >= 1 ? (
          <div className={styles.container_modif}>
            {[...modifications].reverse().map(modif => (
              <div className={styles.container_modif_content} key={modif._id}>
                <div className={styles.statut_modif}>
                  <h4>Statut de la demande</h4>
                  <ModificationReservationStatus state={modif?.modification_status} />
                </div>
                <div>
                  <h4>Dates</h4>
                  <p>Date d'arrivée : {moment(modif?.checkin).format('ll')}</p>
                  <p>Date de départ : {moment(modif?.checkout).format('ll')}</p>
                  <p>Nuits : {modif?.total_nights}</p>
                </div>
                <div>
                  <h4>Détails du prix</h4>
                  {modif?.promotion_fee > 0 && (
                    <p>
                      Réductions : -
                      {addCurrency(modif?.promotion_fee, reservation.prices?.currency)}
                    </p>
                  )}
                  <p>
                    Frais de service :{' '}
                    {addCurrency(modif?.service_fee, reservation.prices?.currency)}
                  </p>
                  <p>
                    Total séjour :{' '}
                    {addCurrency(modif?.total_stay_fee, reservation.prices?.currency)}
                  </p>
                  {modif?.new_price_to_pay > 0 && (
                    <div>
                      <h4>Nouveau payement</h4>
                      <p>
                        Le voyageur doit payer :{' '}
                        <strong>
                          {addCurrency(modif?.new_price_to_pay, reservation.prices?.currency)}
                        </strong>{' '}
                        sur le prix Total initial de{' '}
                        <strong>
                          {addCurrency(modif?.total_initial, reservation.prices?.currency)}
                        </strong>
                      </p>
                      {modif.payment_intent?.stripe_id && (
                        <PaymentIntent
                          reservation={reservation}
                          paymentIntent={modif.payment_intent}
                        />
                      )}
                    </div>
                  )}
                </div>
                <RefundAfterModif reservation={reservation} modif={modif} refetch={refetch} />
                <h4>La date de la demande</h4>
                <p>{momentTimeZone.utc(modif?.createdAt).tz(userTimezone).calendar()}</p>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <p>Pas de demande de modification pour le moment</p>
          </div>
        )}
      </div>
    </>
  )
}

export default Modification
