import { useEffect, useState, useContext } from 'react'
import styles from '../../../css/modehotes/facturation/invoice.module.css'
import axios from 'axios'
import moment from 'moment'
import LoadingPoint from '../../../components/LoadingPoint'
import { User } from '../../../context/UserContext'
import { useInfiniteQuery } from 'react-query'
import { useInView } from 'react-intersection-observer'
import SelectDate from '../../../components/hote_mode/statistics/SelectDate'
import Invoice from './invoices/Invoice'
import FilterInvoice from './FilterInvoice'
import GenerateInvoice from './GenerateInvoice'
import { USER_ROLE } from '../../../enums/user/role.enums'

const ListInvoice = () => {
  const [isFetching, setIsFetching] = useState(false)
  const [invoiceId, setInvoiceId] = useState('')
  const [hostId, setHostId] = useState('')
  const [selectedDate, setSelectedDate] = useState(() => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1 // Les mois sont indexés de 0 à 11, donc ajoutez 1
    return `${year}-${month.toString().padStart(2, '0')}` // 'YYYY-MM'
  })

  const yearMonthDate = moment(selectedDate, 'YYYY-MM').format('MMMM YYYY')

  const user = useContext(User)

  const fetchHistory = ({ pageParam = 1 }) =>
    axios
      .get(
        `/bill/invoice_generate?invoice_id=${invoiceId}&host_id=${hostId}&page=${pageParam}&date=${selectedDate}`
      )
      .then(res => res.data)

  const { data, isLoading, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(['get-invoices', invoiceId, hostId, selectedDate], fetchHistory, {
      getNextPageParam: (lastPage, pages) => {
        const morePagesExist = lastPage.invoices.length === 10
        if (!morePagesExist) return false
        return pages.length + 1
      },
    })

  const { ref, inView } = useInView({
    threshold: 1.0,
  })

  useEffect(() => {
    if (inView && hasNextPage && !isFetching && !isFetchingNextPage) {
      setIsFetching(true)
      fetchNextPage().finally(() => setIsFetching(false))
    }
  }, [inView, hasNextPage, isFetchingNextPage])

  return (
    <>
      {user?.role === USER_ROLE.ADMIN && <GenerateInvoice refetch={refetch} />}
      <SelectDate selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
      <FilterInvoice
        invoiceId={invoiceId}
        setInvoiceId={setInvoiceId}
        hostId={hostId}
        setHostId={setHostId}
      />
      {isLoading ? (
        <div>
          <LoadingPoint />
        </div>
      ) : (
        <>
          {data?.pages[0]?.invoices.length > 0 ? (
            <>
              <h2 className={styles.head_title}>
                {data?.pages[0].invoices.length} Factures générées
              </h2>
              {data?.pages?.map((page, index) => (
                <div key={index} className={styles.block_list_invoice}>
                  {page.invoices.map(item => (
                    <Invoice key={item._id} data={item} refetch={refetch} />
                  ))}
                </div>
              ))}
              <div ref={ref} style={{ height: 1 }} />
              {isFetchingNextPage && <LoadingPoint />}
            </>
          ) : (
            <p>Vous n'avez pas de factures a payé pour {yearMonthDate}</p>
          )}
        </>
      )}
    </>
  )
}

export default ListInvoice
