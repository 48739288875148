import styles from '../../../css/mains/detail.module.css'
import { Link, Redirect, useRouteMatch } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import { User } from '../../../context/UserContext'
import axios from 'axios'
import moment from 'moment'
import avatar from '../../../assets/avatar2.png'
import AppLayout from '../../../layouts/AppLayout'
import Loading from '../../../components/Loading'

function DetailFeedback() {
  const match = useRouteMatch('/feedbaks/:slug/')
  const [contact, setContact] = useState(null)
  const [loading, setLoading] = useState(true)
  const user = useContext(User)

  const slug = match?.params?.slug
  useEffect(() => {
    axios
      .get('/contact/' + slug)
      .then(res => {
        setContact(res.data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }, [slug])

  if (user === null) {
    return <Redirect to='/' />
  }
  return (
    <AppLayout>
      <div className={styles.block_content}>
        <p>Rendez-vous détails</p>
        {loading ? (
          <Loading />
        ) : (
          <div className={styles.container_bouton}>
            <div className={styles.bouton}>
              <Link to='/feedbaks' title='Liste des rendez-vous'>
                <i className='fa fa-list-ul'></i>&nbsp; Liste des rendez-vous
              </Link>
              <Link to='/feedbaks' title='Details contact' className={styles.active}>
                <i className='far fa-eye'></i>&nbsp; Details contact
              </Link>
              <Link to='/reports/users' title='Liste des Signaler utilisateurs'>
                <i className='fa fa-list-ul'></i>&nbsp; Liste des utilisateurs signalés
              </Link>
              <Link to='/reports/hotels' title='Liste des annonces signalés'>
                <i className='fa fa-list-ul'></i>&nbsp; Liste des annonces signalés
              </Link>
            </div>
            <div className={styles.detail_blog}>
              <div className={styles.detail_img}>
                <img src={avatar} alt='' />
              </div>
              <div className={styles.detail_profil}>
                <h2>{contact.nom}</h2>
                <p>
                  Vous trouverez ici toutes les informations concernant le contact cité ci-dessous.
                  Vous avez aussi la possibilité d'afficher et imprimer sa fiche!
                </p>
              </div>
            </div>
            <div className={styles.detail_info}>
              <div className={styles.detail_info_1}>
                <h2>Informations</h2>
                <p>
                  Ici vous trouverez les informations les plus importantes le contact que vous avez
                  séléctionné
                </p>
                <ul>
                  <li>
                    <i className='fa fa-user'></i> Prénom: <span>{contact.user?.first_name}</span>
                  </li>
                  <li>
                    <i className='fas fa-envelope'></i> Email: <span>{contact.user?.email}</span>
                  </li>
                  <li>
                    <i className='fas fa-phone'></i> Téléphone:{' '}
                    <span>{contact.user?.phone_number}</span>
                  </li>
                  <li>
                    <i className='fas fa-home'></i> Type: <span>{contact.type}</span>
                  </li>
                  <li>
                    <i className='fas fa-home'></i> Fonctionnalité:{' '}
                    <span>{contact.fonctionnalite}</span>
                  </li>
                  <li>
                    <i className='far fa-comment-alt'></i> Méssage: <span>{contact.message}</span>
                  </li>
                </ul>
              </div>
              <div className={styles.detail_info_1}>
                <h2>Statistiques</h2>
                <p>
                  Ici vous trouverez les statistiques les plus importantes concernant le contact que
                  vous avez séléctionné
                </p>
                <ul>
                  <li>
                    <i className='fa fa-calendar-alt'></i> Date de création:{' '}
                    <span>{moment(contact.createdAt).format('LLL')}</span>
                  </li>
                  <li>
                    <i className='fa fa-edit'></i> Date de modification:{' '}
                    <span>{moment(contact.updatedAt).format('LLL')}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  )
}
export default DetailFeedback
