import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import DisplayUser from './DisplayUser'
import axios from 'axios'
import SearchInput from '../../ui/SearchInput'

const SearchUser = () => {
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('')
  const [hasSearched, setHasSearched] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearch(search)
      if (search.length > 0 || hasSearched) {
        setHasSearched(true)
      }
    }, 700)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [search])

  const fetchUser = async () => {
    const res = await axios.get(`/user/${debouncedSearch}`)
    return res.data
  }

  const {
    data = null,
    isLoading,
    error,
  } = useQuery(['find-user', debouncedSearch], fetchUser, {
    enabled: hasSearched,
  })

  return (
    <div>
      <SearchInput
        id='find_user'
        placeholder='Utilisateur ID'
        value={search}
        onChange={e => {
          setSearch(e.target.value)
        }}
        onClick={() => {
          setSearch('')
        }}
      />
      <DisplayUser data={data} isLoading={isLoading} error={error} />
    </div>
  )
}

export default SearchUser
