import styles from '../../../css/modehotes/reservation/component.module.css'
import PendingSvg from '../../svgs/PendingSvg'
import { PROCESSING_BOOKING } from '../../../enums/booking/processing.enums'

const ProcessBooking = ({ state }) => {
  return (
    <>
      {state === PROCESSING_BOOKING.COMPLETE ? (
        <div className={styles.block}>
          <p className={styles.complet}>
            <span>Complet</span>
          </p>
        </div>
      ) : (
        <div className={styles.block}>
          <p className={styles.incomplet}>
            <span>Incomplet</span> <PendingSvg />
          </p>
        </div>
      )}
    </>
  )
}

export default ProcessBooking
