import { useState } from 'react'
import styles from '../../../../css/modehotes/statistics/evaluations/comment.module.css'
import moment from 'moment'
import CommentModal from './comments/travelers/CommentModal'

const CommentTraveler = ({ refetch, commentLength, comments }) => {
  const [modal, setModal] = useState(false)
  const handleOpenModal = () => {
    setModal(!modal)
  }

  return (
    <>
      <div className={styles.container}>
        {commentLength > 0 ? (
          <>
            <div className={styles.block}>
              <div className={styles.colone} aria-hidden={true} style={{ flex: '0 0 14px' }}></div>
              {comments.slice(0, 6).map((item, i) => (
                <div className={styles.content} key={i}>
                  <div className={styles.profil_block_avis}>
                    <a
                      href={`/profile/${item.traveler?.id}`}
                      target='_blank'
                      className={styles.image_block}
                      rel='noreferrer'
                    >
                      {item.traveler?.picture ? (
                        <img src={item.traveler.picture} alt='' />
                      ) : (
                        <h2>{item.traveler?.first_name?.slice(0, 1)}</h2>
                      )}
                    </a>
                    <div>
                      {item.traveler?.first_name ? (
                        <h4>{item.traveler?.first_name}</h4>
                      ) : (
                        <h4>{item.traveler?.first_name.split(0, 1)}</h4>
                      )}
                      <p>{moment(item.createdAt).format('LL').slice(2)}</p>
                    </div>
                  </div>
                  <div>
                    <p>{item.comment}</p>
                  </div>
                </div>
              ))}
              <div className={styles.voir_plus} onClick={handleOpenModal}>
                <div className={styles.voir_plus_icon}>
                  <svg
                    viewBox='0 0 32 32'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'
                    role='presentation'
                    focusable='false'
                    style={{
                      display: 'block',
                      height: '16px',
                      width: '16px',
                      stroke: 'currentColor',
                      strokeWidth: '4',
                      overflow: 'visible',
                      fill: 'none',
                    }}
                  >
                    <g>
                      <path d='m12 4 11.2928932 11.2928932c.3905243.3905243.3905243 1.0236893 0 1.4142136l-11.2928932 11.2928932'></path>
                    </g>
                  </svg>
                </div>
                <h5>Afficher tout les commentaires</h5>
              </div>
              <div className={styles.colone} aria-hidden={true} style={{ flex: '0 0 14px' }}></div>
            </div>
            <div className={styles.more}>
              <button onClick={handleOpenModal}>Afficher les {commentLength} commentaires</button>
            </div>
          </>
        ) : (
          <>
            <h3>Aucun commentaire (pour l'instant)</h3>
            <p>Nous vous informerons dès qu'un voyageur aura laissé des remarques.</p>
          </>
        )}
      </div>

      <CommentModal
        refetch={refetch}
        modal={modal}
        handleOpenModal={handleOpenModal}
        comments={comments}
      />
    </>
  )
}

export default CommentTraveler
