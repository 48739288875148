import { useState } from 'react'
import styles from '../../css/ui/copy_to_clipboard.module.css'
import CopyToClipboard from 'react-copy-to-clipboard'
import Snackbar from './Snackbar'

const CopyTextToClipboard = ({ text, title }) => {
  const [copy, setCopy] = useState(false)

  const handleCopy = () => {
    setCopy(true)
  }

  const handleClosecopy = () => {
    setCopy(false)
  }

  return (
    <>
      <CopyToClipboard text={text} onCopy={handleCopy}>
        <span role='button' className={styles.container}>
          <span className={styles.copy_text}>{text}</span>
          <span className={styles.copy_svg}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='none'
              viewBox='0 0 24 24'
            >
              <path
                fill='currentColor'
                fillRule='evenodd'
                d='M7 5a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-2v2a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-9a3 3 0 0 1 3-3h2zm2 2h5a3 3 0 0 1 3 3v5h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-9a1 1 0 0 0-1 1zM5 9a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1z'
                clipRule='evenodd'
              ></path>
            </svg>
          </span>
        </span>
      </CopyToClipboard>
      <Snackbar
        isOpen={copy}
        onClose={handleClosecopy}
        autoHideDuration={1000}
        message={`${title} copié`}
      />
    </>
  )
}

export default CopyTextToClipboard
