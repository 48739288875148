import styles from '../../../../css/navigations/dropdown_link.module.css'
import { useContext } from 'react'
import { Logout } from '../../../../context/UserContext'

const LogoutHost = () => {
  const logout = useContext(Logout)

  return (
    <button onClick={logout} className={styles.block_link}>
      <div className={styles.left_block}>
        <h3>Déconnexion</h3>
      </div>
    </button>
  )
}

export default LogoutHost
