/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useEffect } from 'react'
//moment
import 'moment/locale/fr'
import { User, Logout, Refresh } from './context/UserContext'
import { baseURL } from './config/config'
import axios from 'axios'
import Login from './pages/logins/Login'
import Home from './pages/Home'
import LoadingUser from './pages/user_loading/LoadingUser'
import ListUser from './pages/users/ListUser'
import ListUserConnectedSite from './pages/users/ListUserConnectedSite'
import ListUserConnectedApp from './pages/users/ListUserConnectedApp'
import DetailUser from './pages/users/DetailUser'
import UpdateUser from './pages/users/UpdateUser'
import ListFeedback from './pages/assistances/feedbacks/ListFeedback'
import DetailFeedback from './pages/assistances/feedbacks/DetailFeedback'
import UpdateFeedback from './pages/assistances/feedbacks/UpdateFeedback'
import ListReportUser from './pages/assistances/report_users/ListReportUser'
import DetailReportUser from './pages/assistances/report_users/DetailReportUser'
import ListReportHotel from './pages/assistances/report_hotels/ListReportHotel'
import DetailReportHotel from './pages/assistances/report_hotels/DetailReportHotel'
import ListHostNote from './pages/assistances/host_notes/ListHostNote'
import DetailHostNote from './pages/assistances/host_notes/DetailHostNote'
import ForgotPasswordPage from './pages/accounts/parameters/ForgotPasswordPage'
import AccountSetings from './pages/accounts/AccountSetings'
import ProfileGowifmi from './pages/accounts/parameters/ProfileGowifmi'
import InfoPerso from './pages/accounts/parameters/InfoPerso'
import SecurityAccount from './pages/accounts/parameters/SecurityAccount'
import SetTwoAuthentificator from './pages/accounts/parameters/SetTwoAuthentificator'
import Sponsored from './pages/affiliations/Sponsored'
import SponsorRevenue from './pages/affiliations/Revenue'

import Hosting from './pages/hote_mode/Hosting'
import HostingListProcessReservation from './pages/hote_mode/reservations/process/ListProcess'
import HostingListDeleteReservation from './pages/hote_mode/reservations/request_delete/ListRequestDelete'
import HostingListRequestReservation from './pages/hote_mode/reservations/request/ListeRequest'
import HostingListUpcomingReservation from './pages/hote_mode/reservations/upcoming/ListUpcoming'
import HostingListInprogressReservation from './pages/hote_mode/reservations/inprogress/ListInProgress'
import HostingListCompletedReservation from './pages/hote_mode/reservations/completed/ListCompleted'
import HostingListCancelReservation from './pages/hote_mode/reservations/canceled/ListCancel'
import HostingCancelReservation from './pages/hote_mode/reservations/canceled/HostGoCancel'
import HostingListAllReservation from './pages/hote_mode/reservations/all/ListAll'
import HostingDetailReservation from './pages/hote_mode/reservations/details/DetailReservation'
import HostingReservationDetailHotel from './pages/hote_mode/reservations/details/DetailHotel'
import HostingReservationDetailReglementation from './pages/hote_mode/reservations/details/Reglementation'
import HostingReservationDetailTraveler from './pages/hote_mode/reservations/details/TravelerDetail'
import HostingReservationDetailTransferPayment from './pages/hote_mode/reservations/details/TransferPayment'
import Listing from './pages/hote_mode/annonces/Listing'
import Calendar from './pages/hote_mode/calendar/CalendarMain'
import AllBill from './pages/hote_mode/factures/AllBill'
import PayBill from './pages/hote_mode/factures/PayBill'
import UnpayBill from './pages/hote_mode/factures/UnpayBill'
import BillReport from './pages/hote_mode/factures/BillReport'
import Revenue from './pages/hote_mode/revenues/Revenue'
import SearchHotelHistory from './pages/hote_mode/statistics/SearchHotelHistory'
import ViewListing from './pages/hote_mode/statistics/ViewListing'
import ReservationListing from './pages/hote_mode/statistics/ReservationListing'
import CommentListing from './pages/hote_mode/statistics/CommentListing'
import CommentForTraveler from './pages/hote_mode/statistics/CommentForTraveler'
import DetailAnnonce from './pages/hote_mode/annonces/details/DetailAnnonce'
import PriceAndDisponibilite from './pages/hote_mode/annonces/details/PriceAndDisponibilite'
import ConditionAndReglement from './pages/hote_mode/annonces/details/ConditionAndReglement'
import TravelerInformationEdit from './pages/hote_mode/annonces/details/TravelerInformationEdit'
import DetailAnnoncePhoto from './pages/hote_mode/annonces/details/Photo'
import DetailAnnonceAmenetie from './pages/hote_mode/annonces/details/Amenitie'
import DetailAnnonceSecurityTraveler from './pages/hote_mode/annonces/details/SecurityTraveler'
import HotelHistory from './pages/hote_mode/history/HotelHistory'
import ReservationHistory from './pages/hote_mode/history/ReservationHistory'
import UserHistory from './pages/users/history/UserHistory'

import Page404 from './pages/errors/Page404'
import useConnexion from './hooks/useConnexion'

const App = () => {
  const { loadingUser, user, refreshUser, logout } = useConnexion()

  useEffect(() => {
    axios.defaults.baseURL = baseURL
    refreshUser()
  }, [])

  if (loadingUser) return <LoadingUser />

  return (
    <User.Provider value={user}>
      <Logout.Provider value={logout}>
        <Refresh.Provider value={refreshUser}>
          <Router>
            <Switch>
              <Route path='/' exact>
                <Home />
              </Route>
              <Route path='/login'>
                <Login />
              </Route>

              <Route path='/hosting' exact>
                <Hosting />
              </Route>
              <Route path='/hosting/calendar/'>
                <Calendar />
              </Route>
              <Route path='/hosting/invoice/all'>
                <AllBill />
              </Route>
              <Route path='/hosting/invoice/pay'>
                <PayBill />
              </Route>
              <Route path='/hosting/invoice/unpay'>
                <UnpayBill />
              </Route>
              <Route path='/hosting/infos/earnings'>
                <BillReport />
              </Route>
              <Route path='/hosting/revenues'>
                <Revenue />
              </Route>
              <Route path='/statistics/search-hotel'>
                <SearchHotelHistory />
              </Route>
              <Route path='/statistics/views'>
                <ViewListing />
              </Route>
              <Route path='/statistics/reservation'>
                <ReservationListing />
              </Route>
              <Route path='/statistics/evaluation-hotel'>
                <CommentListing />
              </Route>
              <Route path='/statistics/evaluation-traveler'>
                <CommentForTraveler />
              </Route>
              <Route path='/hosting/reservations/delete'>
                <HostingListDeleteReservation />
              </Route>
              <Route path='/hosting/reservations/process'>
                <HostingListProcessReservation />
              </Route>
              <Route path='/hosting/reservations/request'>
                <HostingListRequestReservation />
              </Route>
              <Route path='/hosting/reservations/upcoming'>
                <HostingListUpcomingReservation />
              </Route>
              <Route path='/hosting/reservations/inprogress'>
                <HostingListInprogressReservation />
              </Route>
              <Route path='/hosting/reservations/completed'>
                <HostingListCompletedReservation />
              </Route>
              <Route path='/hosting/reservations/canceled'>
                <HostingListCancelReservation />
              </Route>
              <Route path='/hosting/reservations/cancellation'>
                <HostingCancelReservation />
              </Route>
              <Route path='/hosting/reservations/all'>
                <HostingListAllReservation />
              </Route>
              <Route path='/hosting/reservations/details/:id' exact>
                <HostingDetailReservation />
              </Route>
              <Route path='/hosting/reservations/details/:id/annonce'>
                <HostingReservationDetailHotel />
              </Route>
              <Route path='/hosting/reservations/details/:id/policies-and-rules'>
                <HostingReservationDetailReglementation />
              </Route>
              <Route path='/hosting/reservations/details/:id/traveler'>
                <HostingReservationDetailTraveler />
              </Route>
              <Route path='/hosting/reservations/details/:id/transfer-payment'>
                <HostingReservationDetailTransferPayment />
              </Route>
              <Route path='/hosting/listings' exact>
                <Listing />
              </Route>
              <Route path='/hosting/listings/details/:id' exact>
                <DetailAnnonce />
              </Route>
              <Route path='/hosting/listings/details/:id/photos'>
                <DetailAnnoncePhoto />
              </Route>
              <Route path='/hosting/listings/details/:id/amenities'>
                <DetailAnnonceAmenetie />
              </Route>
              <Route path='/hosting/listings/details/:id/pricing-and-availability'>
                <PriceAndDisponibilite />
              </Route>
              <Route path='/hosting/listings/details/:id/policies-and-rules'>
                <ConditionAndReglement />
              </Route>
              <Route path='/hosting/listings/details/:id/info-for-guests'>
                <TravelerInformationEdit />
              </Route>
              <Route path='/hosting/listings/details/:id/safety-info'>
                <DetailAnnonceSecurityTraveler />
              </Route>
              <Route path='/hosting/accomodation-history'>
                <HotelHistory />
              </Route>
              <Route path='/hosting/reservation-history'>
                <ReservationHistory />
              </Route>
              <Route path='/users/all'>
                <ListUser />
              </Route>
              <Route path='/users/connect-web-site'>
                <ListUserConnectedSite />
              </Route>
              <Route path='/users/connect-app'>
                <ListUserConnectedApp />
              </Route>
              <Route path='/users/user-history'>
                <UserHistory />
              </Route>
              <Route path='/users/details/:id' exact>
                <DetailUser />
              </Route>
              <Route path='/users/update/:id' exact>
                <UpdateUser />
              </Route>
              <Route path='/feedbaks' exact>
                <ListFeedback />
              </Route>
              <Route path='/feedbaks/:id' exact>
                <DetailFeedback />
              </Route>
              <Route path='/feedbaks/update/:id' exact>
                <UpdateFeedback />
              </Route>
              <Route path='/reports/users' exact>
                <ListReportUser />
              </Route>
              <Route path='/reports/users/:id' exact>
                <DetailReportUser />
              </Route>
              <Route path='/reports/hotels' exact>
                <ListReportHotel />
              </Route>
              <Route path='/reports/hotels/:id' exact>
                <DetailReportHotel />
              </Route>
              <Route path='/notes/host' exact>
                <ListHostNote />
              </Route>
              <Route path='/notes/host/:id' exact>
                <DetailHostNote />
              </Route>
              <Route path='/profile'>
                <ProfileGowifmi />
              </Route>
              <Route path='/account-settings/info-personal'>
                <InfoPerso />
              </Route>
              <Route path='/account-settings/login-and-security'>
                <SecurityAccount />
              </Route>
              <Route path='/account-settings/setup-2fa'>
                <SetTwoAuthentificator />
              </Route>
              <Route path='/account-settings/forgot-password'>
                <ForgotPasswordPage />
              </Route>
              <Route path='/account-settings' exact>
                <AccountSetings />
              </Route>
              <Route path='/sponsorship/sponsors'>
                <Sponsored />
              </Route>
              <Route path='/sponsorship/revenues'>
                <SponsorRevenue />
              </Route>
              <Route>
                <Page404 />
              </Route>
            </Switch>
          </Router>
        </Refresh.Provider>
      </Logout.Provider>
    </User.Provider>
  )
}

export default App
