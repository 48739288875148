import styles from '../../../css/accounts/profile/cover_image.module.css'

const CoverImage = ({ getUser }) => {
  if (!getUser?.cover_picture) return null
  return (
    <div>
      <div className={styles.wrapper_img_profile}>
        <div className={styles.image}>
          {getUser?.cover_picture && <img src={getUser?.cover_picture} alt='' />}
        </div>
      </div>
    </div>
  )
}

export default CoverImage
