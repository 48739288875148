import { useContext } from 'react'
import styles from '../../../../css/navigations/dropdown_link.module.css'
import { Link } from 'react-router-dom'
import { User } from '../../../../context/UserContext'
import SvgIconRightDirector from '../../../svgs/SvgIconRightDirector'

const Profile = () => {
  const user = useContext(User)

  return (
    <Link to={`/profile/${user?.id}`} className={styles.block_link}>
      <div className={styles.left_block}>
        <h3>Profil</h3>
      </div>
      <div className={styles.rigth_block}>
        <SvgIconRightDirector />
      </div>
    </Link>
  )
}

export default Profile
