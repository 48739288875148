import React, { useState } from 'react'
import styles from '../../../../../../../css/modehotes/annonce/details/gowifmi_publish_check_list.module.css'
import CloseButton from '../../../../../../ui/CloseButton'
import Modal from '../../../../../../ui/Modal'

const CheckList = React.forwardRef(({ hostInfo }, ref) => {
  const [modal, setModal] = useState(false)

  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))

  const closeModal = () => {
    setModal(false)
  }

  return (
    <>
      <Modal isOpen={modal} onClose={closeModal}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={closeModal} />
            <div>
              <h2>Vérification obligatoire</h2>
            </div>
            <div></div>
          </div>
          <div className={styles.m_body}>
            <ol>
              {!hostInfo.isIdentityValid && <li>Validation de la pièce d'identité</li>}
              {!hostInfo.picture && <li>Ajouter la photo profil</li>}
              {!hostInfo.isEmailVerified && <li>Vérification de l'e-mail</li>}
              {!hostInfo.isTelephoneVerified && <li>Vérification du numéro de téléphone</li>}
            </ol>
          </div>
        </div>
      </Modal>
    </>
  )
})
export default CheckList
