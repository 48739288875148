import styles from '../../css/home/all_reservation_action.module.css'
import useReservation from '../../hooks/useReservation'
import LoadingPoint from '../LoadingPoint'

function AllReservatoinActionsCount() {
  const {
    loading,
    processPaymentSucceeded,
    refundReservation,
    transferToHost,
    refundModification,
  } = useReservation()
  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <div className={styles.block_icon}>
          <p>Remboursements de la réservation à effectuer</p>
        </div>
        <div className={styles.block_number}>
          {loading ? <LoadingPoint /> : <p className={styles.number}>{refundReservation}</p>}
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.block_icon}>
          <p>Versements des hôtes à effectuer</p>
        </div>
        <div className={styles.block_number}>
          {loading ? <LoadingPoint /> : <p className={styles.number}>{transferToHost}</p>}
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.block_icon}>
          <p>Remboursements des modifications à effectuer</p>
        </div>
        <div className={styles.block_number}>
          {loading ? <LoadingPoint /> : <p className={styles.number}>{refundModification}</p>}
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.block_icon}>
          <p>Process avec les paiements réussis</p>
        </div>
        <div className={styles.block_number}>
          {loading ? <LoadingPoint /> : <p className={styles.number}>{processPaymentSucceeded}</p>}
        </div>
      </div>
    </div>
  )
}
export default AllReservatoinActionsCount
