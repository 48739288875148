import { useState } from 'react'
import styles from '../../../../../../../css/modehotes/annonce/details/detail_head.module.css'
import ProfileByModal from '../../../../../../accounts/ProfileByModal'
import Tooltip from '../../../../../../ui/Tooltip'

const HostProfile = ({ annonce }) => {
  const [modal, setModal] = useState(false)

  return (
    <>
      <Tooltip title="Profil de l'hôte">
        <div>
          <button type='button' onClick={() => setModal(true)} className={styles.host_profile}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 32 32'
              aria-hidden='true'
              role='presentation'
              focusable='false'
              style={{
                display: 'block',
                height: '24px',
                width: '24px',
                stroke: 'currentcolor',
                strokeWidth: '2',
                fill: 'currentcolor',
                overflow: 'visible',
              }}
            >
              <g fill='none'>
                <circle cx='16' cy='16' r='14'></circle>
                <path d='M14.02 19.66a6 6 0 1 1 3.96 0M17.35 19.67H18c3.69.61 6.8 2.91 8.54 6.08m-20.92-.27A12.01 12.01 0 0 1 14 19.67h.62'></path>
              </g>
            </svg>
          </button>
        </div>
      </Tooltip>
      <ProfileByModal userId={annonce.user_id?.id} modal={modal} setModal={setModal} />
    </>
  )
}

export default HostProfile
