import styles from '../../../css/modehotes/reservation/component.module.css'
import { STATUS_BOOKING } from '../../../enums/booking/status.enums'

const ReservationStatus = ({ reservation }) => {
  return (
    <div className={styles.status_reservation}>
      <p
        className={`
      ${reservation.status === STATUS_BOOKING.REQUEST && styles.demande}
      ${reservation.status === STATUS_BOOKING.FUTURE && styles.avenir}
      ${reservation.status === STATUS_BOOKING.IN_PROGRESS && styles.encour}
      ${reservation.status === STATUS_BOOKING.COMPLETE && styles.terminer}
      ${reservation.status === STATUS_BOOKING.CANCELLED && styles.annuler}
      ${reservation.status === STATUS_BOOKING.DELETED && styles.annuler}
      `}
      >
        {reservation.status === STATUS_BOOKING.REQUEST && 'Demande'}
        {reservation.status === STATUS_BOOKING.FUTURE && 'À venir'}
        {reservation.status === STATUS_BOOKING.IN_PROGRESS && 'En cours'}
        {reservation.status === STATUS_BOOKING.COMPLETE && 'Terminée'}
        {reservation.status === STATUS_BOOKING.CANCELLED && 'Annulée'}
        {reservation.status === STATUS_BOOKING.DELETED && 'Supprimée'}
      </p>
    </div>
  )
}

export default ReservationStatus
