import { useEffect, useRef, useState } from 'react'
import styles from '../../../../../../../css/modehotes/annonce/details/adresse.module.css'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import Geocode from 'react-geocode'
import { googleMapApiKey, googleGeocodingApiKey } from '../../../../../../../config/config'
import LoadingPoint from '../../../../../../LoadingPoint'

const Autocomplete = ({
  fullAddress,
  setFullAddress,
  setVille,
  setCodePostal,
  setEtat,
  setPays,
  setCountryAbbreviation,
  setCoords,
}) => {
  const [citemodal, setCitemodal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const contentDestination = useRef(null)
  useEffect(() => {
    const handleClickOutside = event => {
      if (contentDestination.current && !contentDestination.current.contains(event.target)) {
        setCitemodal(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])
  Geocode.setApiKey(googleGeocodingApiKey)
  Geocode.setLanguage('fr')
  Geocode.setLocationType('ROOFTOP')

  const handleSelect = address => {
    setFullAddress(address)
    setCitemodal(false)
    setIsLoading(true)
    Geocode.fromAddress(address)
      .then(res => {
        const { lat, lng } = res.results[0].geometry.location
        setCoords({ lat: lat, lng: lng })
        setFullAddress(res.results[0].formatted_address)
        for (let i = 0; i < res.results[0].address_components.length; i++) {
          for (let j = 0; j < res.results[0].address_components[i].types.length; j++) {
            switch (res.results[0].address_components[i].types[j]) {
              case 'locality':
                setVille(res.results[0].address_components[i].long_name)
                break
              case 'postal_code':
                setCodePostal(res.results[0].address_components[i].long_name)
                break
              case 'administrative_area_level_1':
                setEtat(res.results[0].address_components[i].long_name)
                break
              case 'country':
                setPays(res.results[0].address_components[i].long_name)
                setCountryAbbreviation(res.results[0].address_components[i].short_name)
                break
              default:
            }
          }
        }
      })
      .catch(() => {
        alert(
          'Nous ne parvenons pas à détecter votre emplacement. Essayez de nouveau ou saisissez votre adresse.'
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  //react-google-autocomplete
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: googleMapApiKey,
  })

  return (
    <div className={styles.autocomplete}>
      <div
        className={citemodal ? `${styles.city} ${styles.active}` : styles.city}
        ref={contentDestination}
      >
        <div>
          <label htmlFor='full_address'>Adresse complète</label>
          <input
            autoComplete='off'
            id='full_address'
            onClick={() => setCitemodal(true)}
            className={styles.block_edit}
            placeholder='Saisissez votre adresse complète'
            value={fullAddress}
            onChange={evt => {
              getPlacePredictions({ input: evt.target.value })
              setFullAddress(evt.target.value)
              setCitemodal(true)
            }}
            loading={isPlacePredictionsLoading.toString()}
          />
        </div>
        {isLoading && <LoadingPoint />}
        <div className={styles.cite_search}>
          {!isPlacePredictionsLoading && (
            <>
              {placePredictions.map((item, i) => (
                <button
                  key={i}
                  type='button'
                  className={styles.name}
                  onClick={() => {
                    setFullAddress(item.description)
                    setCitemodal(false)
                    handleSelect(item.description)
                  }}
                >
                  <i className='fas fa-map-marker-alt'></i>
                  <p>{item.description}</p>
                </button>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Autocomplete
