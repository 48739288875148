import React, { useState } from 'react'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import styles from '../../../../../css/modehotes/annonce/details/update_photo.module.css'
import axios from 'axios'
import LoadingPoint from '../../../../LoadingPoint'
import Modal from '../../../../ui/Modal'
import CloseButton from '../../../../ui/CloseButton'

const UpdatePhoto = React.forwardRef(({ refetch }, ref) => {
  const [modal, setModal] = useState(false)
  const [idPhoto, setIdPhoto] = useState('')
  const [photoUrl, setPhotoUrl] = useState('')
  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)

  const annonce = useSelector(state => state.updateAnnonce)

  const location = useLocation()
  const path = location.pathname.split('/')[4]
  //fonction pour ouvrir le  modal partager
  React.useImperativeHandle(ref, () => ({
    OpenModal: (id, photo, caption) => {
      setModal(true)
      setIdPhoto(id)
      setPhotoUrl(photo)
      setText(caption)
    },
  }))
  const reset = () => {
    setModal(false)
  }
  const handleSubmit = () => {
    setLoading(true)
    const data = {
      caption: text,
      url: photoUrl,
    }
    axios
      .patch(`/hotel/Updatephoto/${path}/${idPhoto}/${annonce.user_id.id}`, data)
      .then(() => {
        refetch()
        setLoading(false)
        reset()
      })
      .catch(() => {
        alert('error')
        setLoading(false)
      })
  }
  return (
    <>
      {/*----modal apropos de l'hotel---------*/}
      <Modal isOpen={modal} onClose={reset}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <h3>Modifier la photo</h3>
            <div></div>
          </div>
          <div className={styles.m_body}>
            <div className={styles.img_modal}>
              <img src={photoUrl} alt='' />
            </div>
            <div className={styles.placeHolder}>
              <label htmlFor='text'>
                <h2>Légende</h2>
                <p>
                  Mentionnez ce qui rend ce logement unique, par exemple des meubles confortables ou
                  certains aspects que vous aimez particulièrement.
                </p>
              </label>
              <textarea
                id='text'
                value={text}
                maxLength={250}
                onChange={e => setText(e.target.value)}
              ></textarea>
              <span>{text.length} /250</span>
            </div>
          </div>
          <div className={styles.footer}>
            <button type='button' onClick={reset} className={styles.retour}>
              Retour
            </button>
            {loading ? (
              <button type='button' disabled className={styles.loading_modif}>
                <LoadingPoint />
              </button>
            ) : (
              <button type='button' onClick={handleSubmit} className={styles.next}>
                Enregistrer
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
})

export default UpdatePhoto
