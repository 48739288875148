import { useState, useContext } from 'react'
import styles from '../../css/logins/login.module.css'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { User, Refresh } from '../../context/UserContext'
import { Redirect } from 'react-router'
import LoadingPoint from '../../components/LoadingPoint'
import GowifmiLogoSvg from '../../components/svgs/GowifmiLogoSvg'
import TwoAuthentificator from './TwoAuthentificator'
import Toast from '../../components/infos/toast'

function Login() {
  const [chargement, setChargement] = useState(false)
  const [userId2FA, setuserId2FA] = useState('')

  const user = useContext(User)
  const refresh = useContext(Refresh)

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange' })

  const onSubmit = data => {
    setChargement(true)
    axios
      .post('/auth/login-app', data)
      .then(res => {
        if (res.data.userEnabled2FA) {
          setuserId2FA(res.data.userId)
        } else {
          localStorage.setItem('jwt', res.data.jwt.token)
          axios.defaults.headers.authorization = 'Bearer ' + res.data.jwt.token
          refresh()
        }
      })
      .catch(err => {
        Toast.fire({ icon: 'error', title: 'Mauvais Email ou mot de passe.' })
        setChargement(false)
      })
  }

  if (user) return <Redirect to='/' />

  return (
    <>
      <div className={styles.container}>
        <div className={styles.left_block}>
          <div>
            <GowifmiLogoSvg />
          </div>
          <div className={styles.left_block_bottom}>
            <p>Pas encore de compte ?</p>
          </div>
        </div>
        <div className={styles.login_position}>
          <div className={styles.login}>
            <div className={styles.login_head}>
              <h1>Connectez-vous</h1>
              <p>Content de vous revoir 👋</p>
            </div>
            {userId2FA ? (
              <TwoAuthentificator userId2FA={userId2FA} />
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.login_inputs}>
                  <div>
                    <label htmlFor='email'>Email</label>
                    <input
                      type='email'
                      id='email'
                      name='email'
                      autoComplete='off'
                      placeholder='Example@gmail.com'
                      {...register('email', {
                        required: 'Email est obligatoire.',
                      })}
                    />
                    {errors.email && <p className={styles.error}>{errors.email.message}</p>}
                  </div>
                  <div>
                    <label htmlFor='password'>Mot de passe</label>
                    <input
                      type='password'
                      id='password'
                      name='password'
                      placeholder='Mot de passe'
                      {...register('password', {
                        required: 'Password est obligatoire.',
                      })}
                    />
                    {errors.password && <p className={styles.error}>{errors.password.message}</p>}
                  </div>
                </div>
                {chargement ? (
                  <button className={styles.buttonload}>
                    <LoadingPoint />
                  </button>
                ) : (
                  <button className={styles.login_submit} type='submit'>
                    Se connecter
                  </button>
                )}
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
