import styles from '../../../../../css/modehotes/reservation/detail.module.css'
import DetailPrice from '../reservations/components/DetailPrice'
import TransferManualToHost from './components/TransferManualToHost'
import { STATUS_BOOKING } from '../../../../../enums/booking/status.enums'
import StatusReservation from '../reservations/components/StatusReservation'
import Bill from './components/Bill'
import DateReservation from '../reservations/components/DateReservation'
import AccountConnectHost from './components/AccountConnectHost'
import TransferReferrerManual from './components/TransferReferrerManual'

const AllTransfer = ({ reservation, calculatedPrices, refetch }) => {
  return (
    <div className={styles.block}>
      <h2>Informations sur les versements</h2>
      <StatusReservation reservation={reservation} refetch={refetch} />
      <DateReservation reservation={reservation} />
      <DetailPrice reservation={reservation} calculatedPrices={calculatedPrices} />

      <AccountConnectHost reservation={reservation} />

      {(!reservation.pay_online && reservation?.status === STATUS_BOOKING.COMPLETE) ||
      (!reservation.pay_online && reservation?.status === STATUS_BOOKING.CANCELLED) ? (
        <Bill reservation={reservation} calculatedPrices={calculatedPrices} />
      ) : null}

      {reservation?.status === STATUS_BOOKING.COMPLETE ||
      reservation?.status === STATUS_BOOKING.CANCELLED ? (
        <TransferManualToHost
          reservation={reservation}
          calculatedPrices={calculatedPrices}
          transfer={reservation?.transfer_payment_to_host}
          refetch={refetch}
        />
      ) : null}

      {reservation?.status === STATUS_BOOKING.COMPLETE ||
      reservation?.status === STATUS_BOOKING.CANCELLED ? (
        <TransferReferrerManual reservation={reservation} calculatedPrices={calculatedPrices} />
      ) : null}
    </div>
  )
}

export default AllTransfer
