import styles from '../../../css/navigations/sidebar.module.css'
import Tooltip from './Tooltip'

const LogoutUser = ({ user, logout }) => {
  if (user === null) return null
  return (
    <button onClick={logout}>
      <div className={styles.icon_content}>
        <svg
          className={styles.logout_svg}
          enableBackground='new 0 0 24 24'
          viewBox='0 0 24 24'
          focusable='false'
        >
          <path d='M20 3v18H8v-1h11V4H8V3h12zm-8.9 12.1.7.7 4.4-4.4L11.8 7l-.7.7 3.1 3.1H3v1h11.3l-3.2 3.3z'></path>
        </svg>
      </div>
      <p>Se déconnecter</p>
      <Tooltip title='Se déconnecter' />
    </button>
  )
}

export default LogoutUser
