/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback } from 'react'
import axios from 'axios'
import { getUser } from '../redux/actions'
import { useDispatch, useSelector } from 'react-redux'

function useConnexion() {
  const [loadingUser, setLoadingUser] = useState(true)
  const [user, setUser] = useState(null)
  const dispatch = useDispatch()
  const userReducer = useSelector(state => state.userReducer)

  const updateUser = async () => {
    return axios
      .patch(`/user/disconnect/app/${userReducer?.id}`)
      .then(async res => {
        axios.defaults.headers['authorization'] = ''
        localStorage.removeItem('jwt')
        setLoadingUser(false)
        setUser(null)
        return res.user
      })
      .catch(() => {
        setLoadingUser(false)
      })
  }

  const refreshUser = useCallback(() => {
    const jwt = localStorage.getItem('jwt')
    if (jwt) {
      axios.defaults.headers['authorization'] = 'Bearer ' + jwt
      return axios
        .get(`/auth/app`)
        .then(res => {
          setUser(res.data.user)
          dispatch(getUser(res.data.user))
          const newJWT = res.data.jwt.token
          localStorage.setItem('jwt', newJWT)
          axios.defaults.headers['authorization'] = 'Bearer ' + newJWT
          setLoadingUser(false)
        })
        .catch(() => {
          axios.defaults.headers['authorization'] = ''
          localStorage.removeItem('jwt')
          setLoadingUser(false)
          setUser(null)
        })
    } else {
      updateUser()
    }
    return
  }, [])

  const logout = async () => {
    await updateUser()
    window.location.reload()
  }

  return {
    loadingUser,
    user,
    refreshUser,
    logout,
  }
}

export default useConnexion
