import { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { User } from '../../context/UserContext'
import AppLayout from '../../layouts/AppLayout'
import Dashboard from '../../components/hote_mode/hostings/Dashboard'

const Hosting = () => {
  const user = useContext(User)
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
  }, [history])

  return (
    <AppLayout>
      <title>Tableau de bord de l'hôte - Gowifmi</title>
      <Dashboard />
    </AppLayout>
  )
}

export default Hosting
