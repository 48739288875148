import styles from '../../../css/modehotes/reservation/header.module.css'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import Filter from '../../filter_booking/Filter'

const Header = ({ isLoading, data }) => {
  const location = useLocation()
  const path = location.pathname.split('/')[3]

  return (
    <div>
      <div className={styles.header}>
        <div>
          <h1>
            <Link to='/hosting'>
              <svg
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
                style={{
                  display: 'block',
                  height: '16px',
                  width: '16px',
                  stroke: 'currentcolor',
                  strokeWidth: '3',
                  fill: 'none',
                  overflow: 'visible',
                }}
              >
                <g>
                  <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
                </g>
              </svg>
            </Link>
            {data?.totalRecords} {data?.totalRecords > 1 ? 'Réservations' : 'Réservation'}
          </h1>
          <div className={styles.block_link_search}>
            <div className={styles.link_reservation}>
              <Link
                to='/hosting/reservations/process'
                className={path === 'process' ? styles.active : null}
              >
                Process
              </Link>
              <Link
                to='/hosting/reservations/request'
                className={path === 'request' ? styles.active : null}
              >
                Demandes
              </Link>
              <Link
                to='/hosting/reservations/upcoming'
                className={path === 'upcoming' ? styles.active : null}
              >
                À venir
              </Link>
              <Link
                to='/hosting/reservations/inprogress'
                className={path === 'inprogress' ? styles.active : null}
              >
                Séjours en cours
              </Link>
              <Link
                to='/hosting/reservations/completed'
                className={path === 'completed' ? styles.active : null}
              >
                Terminées
              </Link>
              <Link
                to='/hosting/reservations/canceled'
                className={path === 'canceled' ? styles.active : null}
              >
                Annulées
              </Link>
              <Link
                to='/hosting/reservations/delete'
                className={path === 'delete' ? styles.active : null}
              >
                Supprimées
              </Link>

              <Link
                to='/hosting/reservations/all'
                className={path === 'all' ? styles.active : null}
              >
                Tout
              </Link>
            </div>
            <Filter isLoading={isLoading} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
