import { useState } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const DurationMin = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [durreMin, setDurreMin] = useState()
  const { loading, updateData } = useUpdateHotel(setOpen)

  const minmum = 1
  const maximum = annonce.stay_duration?.maximum
  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      stay_duration: {
        ...annonce.stay_duration,
        minimum: durreMin,
      },
    }
    updateData(datas)
  }
  const openFuction = () => {
    setDurreMin(annonce.stay_duration?.minimum)
    setOpen(true)
  }
  const reset = () => {
    setDurreMin()
    setOpen(false)
  }
  const handleChange = event => {
    const result = event.target.value.replace(/\D/g, '')
    setDurreMin(result)
  }
  return (
    <div className={styles.block}>
      <h2>Durée du séjour</h2>
      {!open ? (
        <Section title='Durée minimale de séjour' handleOpen={openFuction}>
          <p>
            {annonce.stay_duration?.minimum > 1
              ? `${annonce.stay_duration?.minimum} nuits`
              : `${annonce.stay_duration?.minimum} nuit`}
          </p>
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Durée minimale de séjour' handleReset={reset}>
            <p>Nombre minimal de nuits</p>
          </ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div
                className={
                  durreMin < minmum || durreMin > maximum
                    ? `${styles.input_block} ${styles.error}`
                    : styles.input_block
                }
              >
                <input type='tel' value={durreMin} onChange={handleChange} />
                <div className={styles.price}>
                  <h5>
                    {durreMin && durreMin} {durreMin > 1 ? 'nuits' : 'nuit'}
                  </h5>
                  {durreMin < minmum || durreMin > maximum ? (
                    <p>
                      La durée maximale du séjour doit être supérieure ou égale à la durée minimale.
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <BottomForm
              disabled={durreMin < minmum || durreMin > maximum}
              loading={loading}
              handleReset={reset}
            />
          </form>
        </div>
      )}
    </div>
  )
}

export default DurationMin
