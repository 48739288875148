import { useRef } from 'react'
import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import RefundModif from './refunds/RefundModif'
import ValidSvg from '../../../../../svgs/ValidSvg'
import { addCurrency } from '../../../../../../utils/addCurrency'
import RefundPayment from './payments/RefundPayment'
import { UPDATE_BOOKING } from '../../../../../../enums/booking/bookingUpdate.enums'

const RefundAfterModif = ({ reservation, modif, refetch }) => {
  const rembourser = useRef()
  const rembourserFunction = () => {
    rembourser.current.OpenModal()
  }

  return (
    <>
      <div>
        <h4>Remboursement modification </h4>
        {modif?.new_price_to_pay < 0 ? (
          <div>
            <p>
              Nous remboursons le voyageur :{' '}
              <strong>{addCurrency(modif?.new_price_to_pay, reservation.prices?.currency)}</strong>{' '}
              sur le prix Total initial de{' '}
              <strong>{addCurrency(modif?.total_initial, reservation.prices?.currency)}</strong>
            </p>
            <div className={styles.btn_block}>
              {modif?.modification_status === UPDATE_BOOKING.ACCEPTED &&
              !modif.isModification_refunded ? (
                <button type='button' onClick={rembourserFunction}>
                  Rembourser le paiement
                </button>
              ) : null}
              {modif.isModification_refunded && (
                <div>
                  <RefundPayment refund={modif.refund} />
                  <div className={styles.block_valid}>
                    <ValidSvg state={modif.isModification_refunded} />
                    <span>Rembourser</span>
                  </div>
                </div>
              )}
            </div>
            <RefundModif ref={rembourser} reservation={reservation} refetch={refetch} />
          </div>
        ) : (
          <div>
            <p>Pas de remboursement dû à la modification</p>
          </div>
        )}
      </div>
    </>
  )
}

export default RefundAfterModif
