import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import CopyTextToClipboard from '../../../../../ui/CopyTextToClipboard'
import Section from '../../defaults/components/modif_sections/Section'

const IdHotel = ({ annonce }) => {
  return (
    <div className={styles.block}>
      <h2>Informations de base sur le logement</h2>
      <Section title="ID de l'annonce">
        <CopyTextToClipboard text={annonce?._id} title='ID' />
      </Section>
    </div>
  )
}

export default IdHotel
