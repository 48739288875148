import { useState } from 'react'
import styles from '../../../../css/modehotes/facturation/invoice.module.css'
import axios from 'axios'
import Swal from 'sweetalert2'
import Toast from '../../../infos/toast'
import LoadingPoint from '../../../LoadingPoint'
import Switch from '../../../ui/Switch'

const ValidateInvoice = ({ data, refetch }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handlechange = event => {
    let FormData
    if (event.target.checked) {
      FormData = {
        id: data.invoiceId,
        paid: true,
        manualPayment: true,
      }
    } else {
      FormData = {
        id: data.invoiceId,
        paid: false,
        manualPayment: false,
      }
    }
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Pour mettre à jour cette facture',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, modifié !',
    }).then(result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        axios
          .patch(`/bill/update_invoice`, FormData)
          .then(() => {
            refetch()
            Toast.fire({
              icon: 'success',
              title: 'La facture a étè modifiée avec succés.',
            })
          })
          .catch(() => {
            Toast.fire({ icon: 'error', title: 'Un problème est survenu au niveau de serveur' })
          })
          .finally(() => setIsLoading(false))
      }
    })
  }

  return (
    <div className={styles.checkox_container}>
      {isLoading ? <LoadingPoint /> : <Switch checked={data.paid} onChange={handlechange} />}
    </div>
  )
}

export default ValidateInvoice
