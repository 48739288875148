import { useContext } from 'react'
import styles from '../../../css/navigations/dropdown.module.css'
import { User } from '../../../context/UserContext'
import Profile from './dropdown_links/Profile'
import Account from './dropdown_links/Account'
import Reservation from './dropdown_links/Reservation'
import Annonce from './dropdown_links/Annonce'
import LogoutHost from './dropdown_links/LogoutHost'
import Revenue from './dropdown_links/Revenue'
import Buil from './dropdown_links/Buil'
import Statistic from './dropdown_links/Statistic'
import HotelHistory from './dropdown_links/HotelHistory'
import ReservationHistory from './dropdown_links/ReservationHistory'
import CalendarParams from './dropdown_links/CalendarParams'
import Affiliation from './dropdown_links/Affiliation'
import UserManage from './dropdown_links/UserManage'
import Home from './dropdown_links/Home'
import Today from './dropdown_links/Today'
import CloseButton from '../../ui/CloseButton'
import { USER_ROLE } from '../../../enums/user/role.enums'

const Dropdown = ({ togle, setTogle }) => {
  const user = useContext(User)

  const windowViewPort = window.matchMedia('(max-width:770px)')

  return (
    <div className={togle ? `${styles.sidenav} ${styles.avtive}` : styles.sidenav}>
      <div className={styles.side_head}>
        <CloseButton onClick={() => setTogle(false)} />
        <h1>Menu</h1>
        <div></div>
      </div>
      {windowViewPort.matches ? (
        <>
          <Home />
          <Today />
          <Reservation />
          <Annonce />
          <CalendarParams />
          <Affiliation />
          {user?.role === USER_ROLE.ADMIN && <UserManage />}
        </>
      ) : null}
      <Profile />
      <Revenue />
      <Buil />
      <Statistic />
      <Account />
      <HotelHistory />
      <ReservationHistory />
      <LogoutHost />
    </div>
  )
}

export default Dropdown
