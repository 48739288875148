import { useContext } from 'react'
import styles from '../../../css/accounts/profile.module.css'
import { Redirect } from 'react-router-dom'
import ProfilBlock from '../../../components/accounts/profiles/ProfileBlock'
import AppLayout from '../../../layouts/AppLayout'
import { User } from '../../../context/UserContext'

function ProfileGowifmi() {
  const user = useContext(User)

  if (user === null) {
    return <Redirect to='/' />
  }
  return (
    <AppLayout>
      <div className={styles.block_content}>
        <div className={styles.container_bouton}>
          <ProfilBlock />
        </div>
      </div>
    </AppLayout>
  )
}
export default ProfileGowifmi
