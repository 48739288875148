export function getMsakedEmail(email) {
  const skipChars = 2
  const firstChar = email.slice(0, skipChars)

  const domainIndexStart = email.lastIndexOf('@')
  let maskedEmail = email.slice(skipChars, domainIndexStart - skipChars)
  maskedEmail = maskedEmail.replace(/./g, '*')
  const domainPlusPreviousChar = email.slice(domainIndexStart - skipChars, email.length)

  return firstChar.concat(maskedEmail).concat(domainPlusPreviousChar)
}

export function getMsakedString(text, skipChars) {
  const textString = text.toString()
  const firstChar = textString.slice(0, skipChars)

  let maske = textString.slice(skipChars, textString.length - skipChars)
  maske = maske.replace(/./g, '*')
  const previousChar = textString.slice(textString.length - skipChars, textString.length)

  return firstChar.concat(maske).concat(previousChar)
}
