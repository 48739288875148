import { useState, useEffect } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'

const CustomUrl = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [inputFocus, setInputFocus] = useState(false)
  const [error, setError] = useState(null)
  const [lienPersonnaliser, setLienPersonnaliser] = useState('')
  const { loading, updateData } = useUpdateHotel(setOpen, setError)

  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      custom_url: lienPersonnaliser.replace(/\s/g, '-'),
    }
    updateData(datas)
  }
  useEffect(() => {
    setLienPersonnaliser(annonce.custom_url || '')
  }, [annonce])
  const openFuction = () => {
    setLienPersonnaliser(annonce.custom_url || '')
    setOpen(true)
  }
  const reset = () => {
    setLienPersonnaliser('')
    setOpen(false)
    setError(null)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title='Lien personnalisé' handleOpen={openFuction}>
          {annonce.custom_url ? (
            <a
              href={`https://gowifmi.com/h/${annonce.custom_url}`}
              target='_blank'
              rel='noreferrer'
            >
              https://gowifmi.com/h/{annonce.custom_url}
            </a>
          ) : (
            <p>Non défini</p>
          )}
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Lien personnalisé' handleReset={reset}>
            <p>
              En utilisant un lien unique et facile à retenir, vous pourrez plus facilement
              promouvoir votre annonce sur vos cartes de visite, vos sites web ou vos réseaux
              sociaux. Par exemple : gowifmi.com/h/chambre-privée-au-centre-de-barcelone.{' '}
            </p>
          </ContenHead>
          <form onSubmit={handleSubmit} autoComplete='off'>
            <div className={styles.body}>
              <div
                className={
                  error ? `${styles.input_block} ${styles.errorServver}` : styles.input_block
                }
              >
                <div
                  className={
                    inputFocus ? `${styles.create_link} ${styles.focus}` : styles.create_link
                  }
                >
                  <label htmlFor='lienPersonnaliser'>https://gowifmi.com/h/</label>
                  <input
                    type='text'
                    id='lienPersonnaliser'
                    value={lienPersonnaliser}
                    maxLength={100}
                    onFocus={() => setInputFocus(true)}
                    onBlur={() => setInputFocus(false)}
                    onChange={e => setLienPersonnaliser(e.target.value)}
                  />
                </div>
                {error && (
                  <p className={styles.error}>
                    C'est déja utiliser par un autre hôte veuillez ecrire un autre nom personnaliser
                  </p>
                )}
                <span>{lienPersonnaliser.length}/100</span>
              </div>
            </div>
            <BottomForm
              disabled={lienPersonnaliser === annonce.custom_url}
              loading={loading}
              handleReset={reset}
            />
          </form>
        </div>
      )}
    </div>
  )
}

export default CustomUrl
