import { useState, useRef } from 'react'
import styles from '../../../css/accounts/profile/profilAnnonce.module.css'
import axios from 'axios'
import { useQuery } from 'react-query'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import LoadingPoint from '../../LoadingPoint'
import CardComment from './annonce/CardComment'
import Status from './annonce/Status'
import { TYPE_LOGEMENT } from '../../../enums/logement/type_logement.enums'

const Annonces = ({ getUser }) => {
  const [prev, setPrev] = useState(false)
  const [nex, setNex] = useState(true)

  const {
    data: hotels,
    isLoading,
    error,
  } = useQuery(`get-hotel-host-${getUser.id}`, () =>
    axios.get(`/gowifmi_hotels/host/${getUser.id}`).then(res => {
      return res.data
    })
  )

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <LoadingPoint />
      </div>
    )
  }

  if (error) {
    return <div>Les annonces n'arrivent pas à charger veuillez reséyer</div>
  }

  return (
    <>
      {hotels.length > 0 && (
        <div className={styles.Hotel_recomande}>
          <h2 className={styles.heading}>Annonces de {getUser.first_name}</h2>
          <Swiper
            modules={[Navigation, Pagination]}
            speed={500}
            breakpoints={{
              59: {
                slidesPerView: 1.5,
                spaceBetween: 20,
              },
              560: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              912: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 25,
              },
            }}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onBeforeInit={swiper => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
            }}
            onSlideNextTransitionStart={() => setPrev(true)}
            onReachBeginning={() => setPrev(false)}
            onSlidePrevTransitionStart={() => setNex(true)}
            onReachEnd={() => setNex(false)}
            className={styles.mySwiper_block}
          >
            {hotels.map(item => (
              <SwiperSlide key={item._id} className={styles.box_image}>
                <a
                  href={`/hosting/listings/details/${item._id}`}
                  target='_blank'
                  className={styles.image}
                  rel='noreferrer'
                >
                  {item.images[0]?.url ? (
                    <img src={item.images[0].url} alt='couverture' />
                  ) : (
                    <svg
                      viewBox='0 0 16 16'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'
                      role='presentation'
                      focusable='false'
                      style={{
                        display: 'block',
                        height: '34px',
                        width: '34px',
                        fill: '#fff',
                      }}
                    >
                      <path d='M8.602 1.147l.093.08 7.153 6.914-.696.718L14 7.745V14.5a.5.5 0 0 1-.41.492L13.5 15H10V9.5a.5.5 0 0 0-.41-.492L9.5 9h-3a.5.5 0 0 0-.492.41L6 9.5V15H2.5a.5.5 0 0 1-.492-.41L2 14.5V7.745L.847 8.86l-.696-.718 7.153-6.915a1 1 0 0 1 1.297-.08z'></path>
                    </svg>
                  )}
                </a>
                <a href={`/hosting/listings/details/${item._id}`} target='_blank' rel='noreferrer'>
                  <div className={styles.recomande_desc}>
                    <div>
                      <div className={styles.annonce_head}>
                        <CardComment item={item} />
                        <Status annonce={item} />
                      </div>
                      <div className={styles.descrip_detail}>
                        {item.accommodation_type === TYPE_LOGEMENT.ENTIRE_HOME && (
                          <p>Logement entier</p>
                        )}
                        {item.accommodation_type === TYPE_LOGEMENT.PRIVATE_ROOM && (
                          <p>Chambre privée</p>
                        )}
                        {item.accommodation_type === TYPE_LOGEMENT.SHARED_ROOM && (
                          <p>Chambre partagée</p>
                        )}
                      </div>
                      <h5>{item.title}</h5>
                    </div>
                  </div>
                </a>
              </SwiperSlide>
            ))}
            <div
              ref={navigationPrevRef}
              className={prev ? styles.prev : `${styles.prev} ${styles.disable}`}
            >
              <svg
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
                style={{
                  display: 'block',
                  fill: 'none',
                  height: '12px',
                  width: '12px',
                  stroke: 'currentcolor',
                  strokeWidth: '4',
                  overflow: 'visible',
                }}
              >
                <g>
                  <path d='m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932'></path>
                </g>
              </svg>
            </div>
            <div
              ref={navigationNextRef}
              className={nex ? styles.next : `${styles.next} ${styles.disable}`}
            >
              <svg
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
                style={{
                  display: 'block',
                  fill: 'none',
                  height: '12px',
                  width: '12px',
                  stroke: 'currentcolor',
                  strokeWidth: '4',
                  overflow: 'visible',
                }}
              >
                <g>
                  <path d='m12 4 11.2928932 11.2928932c.3905243.3905243.3905243 1.0236893 0 1.4142136l-11.2928932 11.2928932'></path>
                </g>
              </svg>
            </div>
          </Swiper>
        </div>
      )}
    </>
  )
}

export default Annonces
