import styles from '../../../css/filter_booking/all_filter.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import Checkbox from '../../ui/Checkbox'

const ProcessPaymentStatus = () => {
  const Query = window.location.search
  const Params = new URLSearchParams(Query)

  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      Params.delete('page')
      Params.append('process_setup_intent_succeeded', true)
      history.push(`${path}?${Params}`)
    } else {
      Params.delete('page')
      Params.delete('process_setup_intent_succeeded')
      history.push(`${path}?${Params}`)
    }
  }
  const onchangeFilters2 = e => {
    if (e.target.checked === true) {
      Params.delete('page')
      Params.append('process_payment_intent_succeeded', true)
      history.push(`${path}?${Params}`)
    } else {
      Params.delete('page')
      Params.delete('process_payment_intent_succeeded')
      history.push(`${path}?${Params}`)
    }
  }
  return (
    <div className={styles.block_section_filter}>
      <h3>Paiement réussi en process</h3>
      <div className={styles.grid_system}>
        <div className={styles.checkbox_container}>
          <div>
            <Checkbox
              id='process_setup_intent_succeeded'
              checked={Params.get('process_setup_intent_succeeded')}
              onChange={onchangeFilters}
            />
          </div>
          <label htmlFor='process_setup_intent_succeeded'>Setup intent</label>
        </div>
        <div className={styles.checkbox_container}>
          <div>
            <Checkbox
              id='process_payment_intent_succeeded'
              checked={Params.get('process_payment_intent_succeeded')}
              onChange={onchangeFilters2}
            />
          </div>
          <label htmlFor='process_payment_intent_succeeded'>Payment intent</label>
        </div>
      </div>
    </div>
  )
}

export default ProcessPaymentStatus
