import { useState, useContext } from 'react'
import axios from 'axios'
import { useQuery } from 'react-query'
import { User } from '../context/UserContext'

function useUserHook(chageActive = () => {}) {
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [errorUpdate, setErrorUpdate] = useState(false)
  const user = useContext(User)

  const { data: userHook = {}, refetch } = useQuery(`get-user`, () =>
    axios.get(`/user/${user.id}`).then(res => {
      return res.data
    })
  )

  const updateUser = data => {
    setErrorUpdate(false)
    setLoadingUpdate(true)

    axios
      .patch(`/user/${user.id}`, data)
      .then(() => {
        refetch()
        setLoadingUpdate(false)
        chageActive()
      })
      .catch(() => {
        setErrorUpdate(true)
      })
      .finally(() => {
        setLoadingUpdate(false)
      })
  }

  const updateUserEmail = data => {
    setLoadingUpdate(true)
    axios
      .patch(`/user/updateEmail/${user.id}`, data)
      .then(res => {
        refetch()
        setLoadingUpdate(false)
        chageActive()
        if (res.data.verfifEmailSended) {
          alert(
            'Veuillez consulter votre boîte de réception, un e-mail de confirmation doit s’y trouver. Cliquez sur le button pour confirmer votre adresse e-mail.'
          )
        } else {
          alert('Cette adresse e-mail est déjà utilisée')
        }
      })
      .catch(() => {
        alert('Cette adresse e-mail est déjà utilisée')
        setLoadingUpdate(false)
      })
      .finally(() => {
        setErrorUpdate(false)
        setLoadingUpdate(false)
      })
  }

  return {
    userHook,
    loadingUpdate,
    updateUser,
    updateUserEmail,
    errorUpdate,
  }
}

export default useUserHook
