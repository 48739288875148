import styles from '../../../../css/modehotes/statistics/search_history/list_history.module.css'
import { Link } from 'react-router-dom'
import momentTimeZone from 'moment-timezone'
import ChangeZone from './ChangeZone'
import { USER_ROLE } from '../../../../enums/user/role.enums'

const ListHistory = ({ data }) => {
  const userTimezone = momentTimeZone.tz.guess()

  return (
    <div className={styles.container}>
      {data.map(item => (
        <div className={styles.container_block} key={item._id}>
          <p className={styles.p_date}>
            {momentTimeZone.utc(item.createdAt).tz(userTimezone).calendar()}
          </p>
          <h2>Rechercher par · {item.user?.first_name ?? 'Nouveau'}</h2>

          <ChangeZone history={item} />
          {item.user && item.user.role === USER_ROLE.ADMIN ? (
            <div className={styles.footer}>
              <Link to={`/users/${item.user?.id}`} target='_blanck'>
                Voir l'utilisateur
              </Link>
            </div>
          ) : null}
        </div>
      ))}
    </div>
  )
}

export default ListHistory
