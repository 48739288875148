import styles from '../../../css/mains/detail.module.css'
import moment from 'moment'
import ValidSvg from '../../svgs/ValidSvg'
import SwitchInfo from './switch_update_infos/SwitchInfo'

function ValidationInfo({ userData, refetch }) {
  return (
    <div className={styles.detail_info_1}>
      <h2>Infos à vérifiées</h2>
      <p>
        Ici vous trouverez les statistiques les plus importantes concernant le contact que vous avez
        séléctionné
      </p>
      <ul>
        <li>
          <span>
            <ValidSvg state={userData.isCompany_account} />
          </span>
          Est une entreprise
        </li>
        <li>
          Role: <span>{userData.role}</span>
        </li>
        <li>
          <span>
            <ValidSvg state={userData.sessions?.active_site} />
          </span>
          Enligne
        </li>
        <li>
          <ValidSvg state={userData.isIdentityValid} />
          Identité verifiée
        </li>
        <li>
          <ValidSvg state={userData.isEmailVerified} />
          Email: <span>{userData.email}</span>
        </li>
        <li>
          <ValidSvg state={userData.isTelephoneVerified} />
          Téléphone: <span>{userData.telephone}</span>
        </li>
        <li>
          <span>
            <ValidSvg state={userData.isGowifmiEmployee} />
          </span>
          Gowifmi employé
        </li>
        <li>
          <div className={styles.detail_info_1_switch}>
            <div className={styles.detail_info_1_switch_left}>
              <span>
                <ValidSvg state={userData.twoAuthentificator?.enabled} />
              </span>
              2FA activé
            </div>
            {userData.twoAuthentificator?.enabled && (
              <SwitchInfo
                url={`/user/reset_2fa/${userData.id}`}
                state={userData.twoAuthentificator?.enabled}
                refetch={refetch}
              />
            )}
          </div>
        </li>
        <li>
          <span>
            <ValidSvg state={userData.isHost} />
          </span>
          Hôte
        </li>
        <li>
          <span>
            <ValidSvg state={userData.superhost} />
          </span>
          Superhote
        </li>
        <li>
          <span>
            <ValidSvg state={userData.isDisabled} />
          </span>
          Bloqué
        </li>
        <li>
          isDisabled_reason_1: <span>{userData.disabled_reason_1}</span>
        </li>
        <li>
          isDisabled_reason_2: <span>{userData.disabled_reason_2}</span>
        </li>
        <li>
          <i className='fa fa-calendar-alt'></i> Date de création:{' '}
          <span>{moment(userData.createdAt).format('LLL')}</span>
        </li>
        <li>
          <i className='fa fa-edit'></i> Date de modification:{' '}
          <span>{moment(userData.updatedAt).format('LLL')}</span>
        </li>
      </ul>
    </div>
  )
}
export default ValidationInfo
