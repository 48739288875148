import styles from '../../../../css/accounts/profile/profilAnnonce.module.css'
import { SATATUS_ANNONCE } from '../../../../enums/logement/status.enums'

const Status = ({ annonce }) => {
  return (
    <div className={styles.status_block}>
      {annonce.status === SATATUS_ANNONCE.IN_PROGRESS ? (
        <div title='En cours'>
          <svg
            className={styles.encours}
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
          >
            <ellipse cx='8' cy='8' fillRule='evenodd' rx='8' ry='8'></ellipse>
          </svg>
        </div>
      ) : null}
      {annonce.status === SATATUS_ANNONCE.SAVE ? (
        <div title='Enregistrer'>
          <svg
            className={styles.enregistrer}
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
          >
            <ellipse cx='8' cy='8' fillRule='evenodd' rx='8' ry='8'></ellipse>
          </svg>
        </div>
      ) : null}
      {annonce.status === SATATUS_ANNONCE.HIDE ? (
        <div title='Masquée'>
          <svg
            className={styles.masquer}
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
          >
            <ellipse cx='8' cy='8' fillRule='evenodd' rx='8' ry='8'></ellipse>
          </svg>
        </div>
      ) : null}
      {annonce.status === SATATUS_ANNONCE.PUBLISH ? (
        <div title='Publiée'>
          <svg
            className={styles.publier}
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
          >
            <ellipse cx='8' cy='8' fillRule='evenodd' rx='8' ry='8'></ellipse>
          </svg>
        </div>
      ) : null}
      {annonce.status === SATATUS_ANNONCE.DEACTIVATE && (
        <div title='Désactiver'>
          <svg
            className={styles.desactiver}
            viewBox='0 0 32 32'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
          >
            <path d='M16 1c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C1 7.716 7.716 1 16 1zm8.122 11.12L12.12 24.122l.136.065c1.055.483 2.2.76 3.387.806L16 25a9 9 0 0 0 9-9c0-1.32-.284-2.598-.818-3.757zM16 7a9 9 0 0 0-9 9c0 1.32.284 2.598.818 3.757l.059.122L19.879 7.877l-.135-.064a8.953 8.953 0 0 0-3.387-.806z'></path>
          </svg>
        </div>
      )}
    </div>
  )
}

export default Status
