import styles from '../../../css/accounts/profile/profil_description.module.css'
import moment from 'moment'
import { Photo } from './profile_descr/Photo'
import ParagrapheTronque from '../../ui/ParagrapheTronque'
import Verification from './profile_descr/Verification'
import CopyTextToClipboard from '../../ui/CopyTextToClipboard'
import Category from './profile_descr/Category'

const CheckEdSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
      aria-hidden='true'
      role='presentation'
      focusable='false'
      style={{
        display: 'block',
        fill: 'none',
        height: '20px',
        width: '20px',
        stroke: 'currentcolor',
        strokeWidth: '2.66667',
        overflow: 'visible',
      }}
    >
      <path fill='none' d='m4 16.5 8 8 16-16'></path>
    </svg>
  )
}

const ProfilDescription = ({ getUser }) => {
  return (
    <>
      <div className={styles.profil_block}>
        <Photo getUser={getUser} />
        <div className={styles.badge}>
          <CopyTextToClipboard text={getUser?.id} title='ID' />
          {getUser.isIdentityValid ? (
            <div className={styles.identity_verif}>
              <div className={styles.icon}>
                <svg
                  viewBox='0 0 16 16'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                  style={{
                    display: 'block',
                    height: '18px',
                    width: '18px',
                    fill: 'currentColor',
                  }}
                >
                  <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z'></path>
                </svg>
              </div>
              <h2>identité vérifiée</h2>
            </div>
          ) : (
            <div>
              <Verification getUser={getUser} />
            </div>
          )}
          <Category getUser={getUser} />
          <div className={styles.about_me_desc}>
            <h2>Bonjour, je m'appelle {getUser.first_name}</h2>
            {getUser.about_me && <ParagrapheTronque text={getUser.about_me} lengthMax={200} />}
          </div>
        </div>
        <div className={styles.verify}>
          {getUser.first_name && (
            <h2>
              <strong>{getUser.first_name}</strong> : identification vérifiée
            </h2>
          )}
          {getUser.isIdentityValid && (
            <div className={styles.verify_item}>
              <CheckEdSvg />
              <span>Identité</span>
            </div>
          )}
          {getUser.isEmailVerified && (
            <div className={styles.verify_item}>
              <CheckEdSvg />
              <CopyTextToClipboard text={getUser?.email} title='Email' />
            </div>
          )}
          {getUser.isTelephoneVerified && (
            <div className={styles.verify_item}>
              <CheckEdSvg />
              <CopyTextToClipboard text={getUser?.phone_number} title='Téléphone' />
            </div>
          )}
          {getUser.work_email && (
            <div className={styles.verify_item}>
              <CheckEdSvg />
              <CopyTextToClipboard text={getUser?.work_email} title='Email pro' />
            </div>
          )}
          <h3>Membre depuis {moment(getUser.createdAt).format('LL').slice(2)}</h3>
        </div>
      </div>
    </>
  )
}

export default ProfilDescription
