import styles from '../../../css/users/history/list_history.module.css'
import { TYPE_HISTORY, MODIFY_BY } from '../../../enums/user/type_history.enums'
import { Link } from 'react-router-dom'
import momentTimeZone from 'moment-timezone'
import ChangeZone from './ChangeZone'

const ListHistory = ({ data }) => {
  const userTimezone = momentTimeZone.tz.guess()

  return (
    <div className={styles.container}>
      {data.map(item => (
        <div className={styles.container_block} key={item._id}>
          <p className={styles.p_date}>
            {momentTimeZone.utc(item.createdAt).tz(userTimezone).calendar()}
          </p>
          {item.type === TYPE_HISTORY.CREATE_USER && (
            <h2>Création du compte · {item.user?.first_name ?? 'Nouveau'}</h2>
          )}
          {item.type === TYPE_HISTORY.UPDATE_USER && (
            <h2>Modification du compte · {item.user?.first_name ?? 'Nouveau'}</h2>
          )}
          {item.type === TYPE_HISTORY.UPDATE_EMAIL && (
            <h2>Modification du email · {item.user?.first_name ?? 'Nouveau'}</h2>
          )}
          {item.type === TYPE_HISTORY.UPDATE_PASSWORD && (
            <h2>Modification du mot de passe · {item.user?.first_name ?? 'Nouveau'}</h2>
          )}
          {item.type === TYPE_HISTORY.FORGOT_PASSWORD && (
            <h2>
              Modification du mot de passe aprés l'oublie · {item.user?.first_name ?? 'Nouveau'}
            </h2>
          )}
          {item.type === TYPE_HISTORY.GENERATE_2FA_QRCODE && (
            <h2>Géneration de qrcode pour 2FA · {item.user?.first_name ?? 'Nouveau'}</h2>
          )}
          {item.type === TYPE_HISTORY.SETUP_2FA && (
            <h2>Ajout de 2FA · {item.user?.first_name ?? 'Nouveau'}</h2>
          )}
          {item.type === TYPE_HISTORY.RESET_2FA && (
            <h2>Suppression de 2FA · {item.user?.first_name ?? 'Nouveau'}</h2>
          )}
          {item.type === TYPE_HISTORY.ADD_PROFILE_IMAGE && (
            <h2>Ajout de la photo profil · {item.user?.first_name ?? 'Nouveau'}</h2>
          )}
          {item.type === TYPE_HISTORY.ADD_COVER_IMAGE && (
            <h2>Ajout de la photo de couverture · {item.user?.first_name ?? 'Nouveau'}</h2>
          )}
          {item.type === TYPE_HISTORY.DISABLE_ACCOUNT && (
            <h2>Désactivation du compte · {item.user?.first_name ?? 'Nouveau'}</h2>
          )}
          {item.type === TYPE_HISTORY.DELETE_USER && (
            <h2>Suppression du compte · {item.user?.first_name ?? 'Nouveau'}</h2>
          )}
          {item.modifyBy === MODIFY_BY.HOST && (
            <p className={styles.p_profil}>Modifié par {item.user?.first_name}</p>
          )}
          {item.modifyBy === MODIFY_BY.GOWIFMI && (
            <p className={styles.p_profil}>
              Modifié par Gowifmi · {item.gowifmi_employee?.first_name}
            </p>
          )}
          <ChangeZone history={item} />
          <div className={styles.footer}>
            <Link to={`/users/${item.user?.id}`}>Voir l'utilisateur</Link>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ListHistory
