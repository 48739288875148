import styles from '../../css/mains/add.module.css'
import { Redirect } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import { useLocation } from 'react-router'
import AppLayout from '../../layouts/AppLayout'
import Loading from '../../components/Loading'
import { User } from '../../context/UserContext'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: toast => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

function UpdateUser() {
  const [redirect, SetRedirect] = useState(false)
  const [utilisateur, setUtilisateur] = useState(null)
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const user = useContext(User)

  const path = location.pathname.split('/')[3]
  const { register, handleSubmit } = useForm()
  const onSubmit = data => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Pour mettre à jour ce utilisateur',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, modifié !',
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .patch('/user/' + path, data)
          .then(() => {
            Toast.fire({ icon: 'success', text: 'utilisateur modifié avec succès' })
            SetRedirect(true)
          })
          .catch(() => {
            Toast.fire({ icon: 'error', text: 'échec de la mise à jour' })
          })
      }
    })
  }

  useEffect(() => {
    axios
      .get('/user/' + path)
      .then(res => {
        setUtilisateur(res.data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }, [path])

  if (redirect) return <Redirect to='/users/all' />

  if (user === null) {
    return <Redirect to='/' />
  }

  return (
    <AppLayout>
      <div className={styles.block_content}>
        <p>Utilisateur</p>
        {loading ? (
          <Loading />
        ) : (
          <div className={styles.container_bouton}>
            <div className={styles.container_text}>
              <h2>
                <span className={styles.modif}>Modification</span> d'un utilisateur
              </h2>
              <p>
                Veuillez <span className={styles.modif}>Corriger</span> le formulaire
              </p>
            </div>
            <form action='' onSubmit={handleSubmit(onSubmit)} autoComplete='false'>
              <div className={styles.form}>
                <div className={styles.form_position}>
                  <div>
                    <label htmlFor='nom'>
                      Nom <span className={styles.etoil}>*</span>
                    </label>
                    <input
                      type='text'
                      id='nom'
                      {...register('name', { value: utilisateur.name })}
                    />
                  </div>
                  <div>
                    <label htmlFor='prenom'>
                      Prénom <span className={styles.etoil}>*</span>
                    </label>
                    <input
                      type='text'
                      id='prenom'
                      {...register('first_name', { value: utilisateur.first_name })}
                    />
                  </div>
                  <div>
                    <label htmlFor='langue_1'>Langue 1</label>
                    <input
                      type='text'
                      id='langue_1'
                      {...register('language_1', { value: utilisateur.language_1 })}
                    />
                  </div>
                  <div>
                    <label htmlFor='langue_2'>Langue 2</label>
                    <input
                      type='text'
                      id='langue_2'
                      {...register('language_2', { value: utilisateur.language_2 })}
                    />
                  </div>
                  <div>
                    <label htmlFor='date_naissance'>Date de naissance</label>
                    <input
                      type='date'
                      id='date_naissance'
                      {...register('date_of_birth', { value: utilisateur.date_of_birth })}
                    />
                  </div>
                  <div>
                    <label htmlFor='pays'>Pays</label>
                    <input
                      type='text'
                      id='pays'
                      disabled
                      {...register('country', { value: utilisateur.country })}
                    />
                    <label htmlFor='adresse_postale'>Adresse_postale</label>
                    <input
                      type='text'
                      id='adresse_postale'
                      {...register('postal_address', { value: utilisateur.postal_address })}
                    />
                  </div>
                  <div>
                    <label htmlFor='isHote'>Est hote ?</label>
                    <select id='isHote' {...register('isHost', { value: utilisateur.isHost })}>
                      <option value='true'>Oui</option>
                      <option value='false'>Non</option>
                    </select>
                  </div>
                </div>
                <div className={styles.form_position}>
                  <div>
                    <label htmlFor='email'>
                      Email <span className={styles.etoil}>*</span>
                    </label>
                    <input
                      type='email'
                      id='email'
                      {...register('email', { required: true, value: utilisateur.email })}
                    />
                  </div>
                  <div>
                    <label htmlFor='email_pro'>Email professionnelle</label>
                    <input
                      type='email'
                      id='email_pro'
                      {...register('work_email', {
                        required: false,
                        value: utilisateur.work_email,
                      })}
                    />
                  </div>
                  <div>
                    <label htmlFor='code_postale'>Code_postale</label>
                    <input
                      type='text'
                      id='code_postale'
                      {...register('postal_code', { value: utilisateur.postal_code })}
                    />
                  </div>
                  <div>
                    <label htmlFor='ville'>Ville</label>
                    <input
                      type='text'
                      id='ville'
                      {...register('city', { value: utilisateur.city })}
                    />
                  </div>
                  <div>
                    <label htmlFor='superhote'>Superhote</label>
                    <select
                      id='superhote'
                      {...register('superhost', { value: utilisateur.superhost })}
                    >
                      <option value='true'>Oui</option>
                      <option value='false'>Non</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor='isDisabled'>Bloquer</label>
                    <select
                      id='isDisabled'
                      {...register('isDisabled', { value: utilisateur.isDisabled })}
                    >
                      <option value='true'>Oui</option>
                      <option value='false'>Non</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor='picture'>Photo profil</label>
                    <input
                      type='text'
                      id='picture'
                      {...register('picture', { value: utilisateur.picture })}
                    />
                  </div>
                </div>
                <div className={styles.form_position}>
                  <div>
                    <label htmlFor='isEmailVerified'>E-mail est vérifier ?</label>
                    <select
                      id='isEmailVerified'
                      {...register('isEmailVerified', { value: utilisateur.isEmailVerified })}
                    >
                      <option value='true'>Oui</option>
                      <option value='false'>Non</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor='telephone'>Télephone</label>
                    <input
                      type='text'
                      id='telephone'
                      {...register('phone_number', { value: utilisateur.phone_number })}
                    />
                  </div>
                  <div>
                    <label htmlFor='isTelephoneVerified'>Téléphone est vérifier ?</label>
                    <select
                      id='isTelephoneVerified'
                      {...register('isTelephoneVerified', {
                        value: utilisateur.isTelephoneVerified,
                      })}
                    >
                      <option value='true'>Oui</option>
                      <option value='false'>Non</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor='role'>
                      Role <span className={styles.etoil}>*</span>
                    </label>
                    <select id='role' {...register('role', { value: utilisateur.role })}>
                      <option value='user'>utilisateur</option>
                      <option value='marketer'>marketeur</option>
                      <option value='user_moderator'>modérateur</option>
                      <option value='admin'>admin</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor='sexe'>sexe</label>
                    <select id='sexe' {...register('gender', { value: utilisateur.gender })}>
                      <option value='homme'>Homme</option>
                      <option value='femme'>Femme</option>
                      <option value='autre'>Autre</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor='isGowifmiEmploye'>Est gowifmi employe ?</label>
                    <select
                      id='isGowifmiEmploye'
                      {...register('isGowifmiEmployee', { value: utilisateur.isGowifmiEmployee })}
                    >
                      <option value='true'>Oui</option>
                      <option value='false'>Non</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor='apropos_de_moi'>Apropos</label>
                    <textarea {...register('about_me', { value: utilisateur.about_me })}></textarea>
                  </div>
                </div>
              </div>
              <div className={styles.button_modif}>
                <button type='submit'>modifier</button>
              </div>
            </form>
          </div>
        )}
      </div>
    </AppLayout>
  )
}
export default UpdateUser
