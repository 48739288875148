import styles from '../../../css/modehotes/reservation/component.module.css'
import PendingSvg from '../../svgs/PendingSvg'
import CheckSvg from '../../svgs/CheckSvg'
import { STATUS_BOOKING } from '../../../enums/booking/status.enums'
import { STATUS_STRANSFER } from '../../../enums/booking/transferStatus.enums'

const TransferSatus = ({ state }) => {
  return (
    <>
      {state === STATUS_STRANSFER.COMPLETE && (
        <div className={styles.block}>
          <p className={styles.complet}>
            <span>Réussi</span> <CheckSvg />
          </p>
        </div>
      )}
      {state === STATUS_STRANSFER.IN_PROGRESS && (
        <div className={styles.block}>
          <p className={styles.traitement}>
            <span>Traitement</span> <PendingSvg />
          </p>
        </div>
      )}
      {state === STATUS_STRANSFER.CANCELLED && (
        <div className={styles.block}>
          <p className={styles.try}>
            <span>Annuler</span> <PendingSvg />
          </p>
        </div>
      )}
      {state === STATUS_BOOKING.REJECTED && (
        <div className={styles.block}>
          <p className={styles.mal_passe}>
            <span>Echoué</span> <PendingSvg />
          </p>
        </div>
      )}
      {!state && (
        <div className={styles.block}>
          <p className={styles.incomplet}>
            <span>À venir</span>
          </p>
        </div>
      )}
    </>
  )
}

export default TransferSatus
