const STATUS_BOOKING = {
  REQUEST: 'request',
  FUTURE: 'future',
  IN_PROGRESS: 'in_progress',
  COMPLETE: 'complete',
  REJECTED: 'rejected',
  CANCELLED: 'cancelled',
  DELETED: 'deleted',
}
export { STATUS_BOOKING }
