import { useState, useEffect, useContext } from 'react'
import styles from '../../../css/mains/liste.module.css'
import axios from 'axios'
import { Link, Redirect } from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'
import Loading from '../../../components/Loading'
import AppLayout from '../../../layouts/AppLayout'
import { User } from '../../../context/UserContext'

function ListFeedback() {
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [contact, setContact] = useState([])
  const user = useContext(User)

  useEffect(() => {
    getData()
  }, [])
  const getData = () => {
    axios
      .get('/contact')
      .then(res => {
        setContact(res.data)
        setLoading(false)
      })
      .catch(err => {
        setContact([])
      })
  }

  const deleteContact = id => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Vous ne pourrez pas annuler cela!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimé!',
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .delete('/contact/' + id)
          .then(() => {
            Swal.fire('Supprimé!', 'Votre fichier a été supprimé.', 'success')
            getData()
          })
          .catch()
      }
    })
  }
  if (user === null) {
    return <Redirect to='/' />
  }
  return (
    <AppLayout>
      <div className={styles.block_content}>
        <p>Liste des rendez-vous</p>
        <div className={styles.container_bouton}>
          <div className={styles.bouton}>
            <Link to='/feedbaks' className={styles.active} title='Liste des rendez-vous'>
              <i className='fa fa-list-ul'></i>&nbsp; Liste des rendez-vous
            </Link>
            <Link to='/reports/users' title='Liste des Signaler utilisateurs'>
              <i className='fa fa-list-ul'></i>&nbsp; Liste des utilisateurs signalés
            </Link>
            <Link to='/reports/hotels' title='Liste des annonces signalés'>
              <i className='fa fa-list-ul'></i>&nbsp; Liste des annonces signalés
            </Link>
            <Link to='/notes/host' title='Liste des rémarques'>
              <i className='fa fa-list-ul'></i>&nbsp; Liste des rémarques
            </Link>
          </div>
          <div className={styles.search}>
            <div className={styles.search_input}>
              <input
                value={search}
                onChange={event => setSearch(event.target.value)}
                type='search'
                placeholder='Rechercher...'
              />
              <i className='fa fa-search'></i>
            </div>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <>
              <div className={styles.liste}>
                <table className={styles.customers}>
                  <tr>
                    <th>N°</th>
                    <th>Utilisateur</th>
                    <th>Type</th>
                    <th>Fonctionnalité</th>
                    <th>Message</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                  {contact
                    .filter(e => e.user?.first_name.toLowerCase().includes(search.toLowerCase()))
                    .map((e, k) => (
                      <tr key={k}>
                        <td>{k + 1}</td>
                        <td>{e.user?.first_name}</td>
                        <td>{e.type}</td>
                        <td>{e.fonctionnalite}</td>
                        <td>{e.message}</td>
                        <td>{moment(e.createdAt).calendar()}</td>
                        <td className={styles.action}>
                          <Link to={`/feedbaks/${e._id}/`}>
                            <i className='far fa-eye' title='Details'></i>
                          </Link>
                          <Link to={`/feedbaks/update/${e._id}`}>
                            <i className='fa fa-edit' title='Modifier'></i>
                          </Link>
                          <button type='button' onClick={() => deleteContact(e._id)}>
                            <i className='fa fa-trash' title='supprimer'></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                </table>
                {contact.length === 0 ? (
                  <p className={styles.aucun_resultat}>Aucun resultat trouvé</p>
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
    </AppLayout>
  )
}
export default ListFeedback
