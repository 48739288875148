import { useEffect, useState, useContext } from 'react'
import styleGlobal from '../../../../css/modehotes/annonce/details/detail.module.css'
import styles from '../../../../css/modehotes/annonce/details/equipement.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { getAnnonce } from '../../../../redux/actions'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { User } from '../../../../context/UserContext'
import LoadingPoint from '../../../../components/LoadingPoint'
import ListAnnonce from '../../../../components/hote_mode/annonce/details/defaults/ListAnnonce'
import HeadDetail from '../../../../components/hote_mode/annonce/details/defaults/HeadDetail'
import MuchRequest from '../../../../components/hote_mode/annonce/details/amenities/MuchRequest'
import Bathroom from '../../../../components/hote_mode/annonce/details/amenities/Bathroom'
import RoomAnLine from '../../../../components/hote_mode/annonce/details/amenities/RoomAnLine'
import Entertainment from '../../../../components/hote_mode/annonce/details/amenities/Entertainment'
import Family from '../../../../components/hote_mode/annonce/details/amenities/Family'
import AirConditioner from '../../../../components/hote_mode/annonce/details/amenities/AirConditioner'
import Security from '../../../../components/hote_mode/annonce/details/amenities/Security'
import Internet from '../../../../components/hote_mode/annonce/details/amenities/Internet'
import Kitchen from '../../../../components/hote_mode/annonce/details/amenities/Kitchen'
import Exterior from '../../../../components/hote_mode/annonce/details/amenities/Exterior'
import Parking from '../../../../components/hote_mode/annonce/details/amenities/Parking'
import Service from '../../../../components/hote_mode/annonce/details/amenities/Service'
import AppLayout from '../../../../layouts/AppLayout'
import HeadPage from '../../../../components/hote_mode/annonce/details/defaults/HeadPage'

const AmenetiePage = () => {
  const [loading, setLoading] = useState(true)
  const [equipements, setEquipements] = useState([])
  const user = useContext(User)
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname.split('/')[4]
  const dispatch = useDispatch()
  const annonce = useSelector(state => state.updateAnnonce)
  useEffect(() => {
    axios
      .get(`/gowifmi_hotels/oneAnnonce/${path}`)
      .then(res => {
        dispatch(getAnnonce(res.data))
        setEquipements(res.data.amenities)
        setLoading(false)
      })
      .catch(() => {
        setLoading(true)
        alert("une erruer s'est produite")
      })
    return () => {
      setLoading(false)
    }
  }, [dispatch, path])

  if (user === null) {
    history.push('/')
  }

  return (
    <AppLayout>
      <title>Modifier l'annonce : {annonce?.title} - Gowifmi</title>
      <div className={styleGlobal.voyage_container}>
        <ListAnnonce />
        <div className={styleGlobal.block_details}>
          <HeadDetail annonce={annonce} />
          {loading ? (
            <div className={styleGlobal.loading}>
              <LoadingPoint />
            </div>
          ) : (
            <div>
              <div className={styles.page}>
                <HeadPage annonce={annonce} />
                <div className={styles.dispositif}>
                  <h3>Équipements</h3>
                  <MuchRequest equipements={equipements} setEquipements={setEquipements} />
                  <Bathroom equipements={equipements} setEquipements={setEquipements} />
                  <RoomAnLine equipements={equipements} setEquipements={setEquipements} />
                  <Entertainment equipements={equipements} setEquipements={setEquipements} />
                  <Family equipements={equipements} setEquipements={setEquipements} />
                  <AirConditioner equipements={equipements} setEquipements={setEquipements} />
                  <Security equipements={equipements} setEquipements={setEquipements} />
                  <Internet equipements={equipements} setEquipements={setEquipements} />
                  <Kitchen equipements={equipements} setEquipements={setEquipements} />
                  <Exterior equipements={equipements} setEquipements={setEquipements} />
                  <Parking equipements={equipements} setEquipements={setEquipements} />
                  <Service equipements={equipements} setEquipements={setEquipements} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </AppLayout>
  )
}

export default AmenetiePage
