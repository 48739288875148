import styles from '../../../../css/navigations/dropdown_link.module.css'
import { Link } from 'react-router-dom'
import SvgIconRightDirector from '../../../svgs/SvgIconRightDirector'

const Account = () => {
  return (
    <Link to='/account-settings' className={styles.block_link}>
      <div className={styles.left_block}>
        <h3>Compte</h3>
      </div>
      <div className={styles.rigth_block}>
        <SvgIconRightDirector />
      </div>
    </Link>
  )
}

export default Account
