import { useState } from 'react'
import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import axios from 'axios'
import Swal from 'sweetalert2'
import Toast from '../../../../../infos/toast'
import LoadingPoint from '../../../../../LoadingPoint'
import { STATUS_BOOKING } from '../../../../../../enums/booking/status.enums'
import { PROCESSING_BOOKING } from '../../../../../../enums/booking/processing.enums'

const tostMessage = (icon, title) => {
  Toast.fire({
    icon: icon,
    title: title,
  })
}

const ChangeStatus = ({ reservation, refetch }) => {
  const [loading, setLoading] = useState(false)
  const status = reservation.status

  const handleChangeStatuts = url => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Vous ne pourrez pas annuler cela!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Oui, modifiée!',
    }).then(result => {
      if (result.isConfirmed) {
        setLoading(true)
        axios
          .patch(`/gowifmi_reservations/${url}/${reservation._id}`)
          .then(() => {
            tostMessage('success', 'La réservation a été modifiée.')
            refetch()
          })
          .catch(err => {
            if (err.response?.data?.message === 'status_invalid') {
              tostMessage(
                'error',
                'Vous ne pouvez pas modifier, parce que le status est incorrecte veuilliez actualiser la page.'
              )
            } else {
              tostMessage('error', 'Une erreur est survenue veuillez réessayer')
            }
          })
          .finally(() => {
            setLoading(false)
          })
      }
    })
  }

  const handleChangeProcessing = () => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Vous ne pourrez pas annuler cela!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Oui, modifiée!',
    }).then(result => {
      if (result.isConfirmed) {
        setLoading(true)
        axios
          .patch(`/gowifmi_reservations/change_processing_to_complete/${reservation._id}`)
          .then(() => {
            tostMessage('success', 'La réservation a été modifiée.')
            refetch()
          })
          .catch(err => {
            tostMessage('error', 'Une erreur est survenue veuillez réessayer')
          })
          .finally(() => {
            setLoading(false)
          })
      }
    })
  }

  return (
    <div className={styles.content}>
      <h3>Actions requises</h3>
      {loading ? (
        <LoadingPoint />
      ) : (
        <div className={styles.btn_block}>
          {reservation.booking_processing === PROCESSING_BOOKING.INCOMPLETE && (
            <button onClick={handleChangeProcessing}>Passer le processus au complet</button>
          )}
          {status === STATUS_BOOKING.FUTURE && (
            <button onClick={() => handleChangeStatuts('change_to_inprogress')}>
              Passer au statut en cours
            </button>
          )}
          {status === STATUS_BOOKING.IN_PROGRESS && (
            <button onClick={() => handleChangeStatuts('change_to_complete')}>
              Passer au statut terminé
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default ChangeStatus
