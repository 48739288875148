import styles from '../../../../../css/modehotes/annonce/filtres/filtre.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import Checkbox from '../../../../ui/Checkbox'

const Gowifmi = () => {
  const Query = window.location.search
  const Params = new URLSearchParams(Query)

  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      Params.delete('page')
      Params.append('gowifmi_published', true)
      history.push(`${path}?${Params}`)
    } else {
      Params.delete('page')
      Params.delete('gowifmi_published')
      history.push(`${path}?${Params}`)
    }
  }
  const onchangeFilters2 = e => {
    if (e.target.checked === true) {
      Params.delete('page')
      Params.append('gowifmi_no_published', true)
      history.push(`${path}?${Params}`)
    } else {
      Params.delete('page')
      Params.delete('gowifmi_no_published')
      history.push(`${path}?${Params}`)
    }
  }
  return (
    <div className={styles.block_section_filter}>
      <h3>Gowifmi</h3>
      <div className={styles.grid_system}>
        <div className={styles.checkox_contener}>
          <div>
            <Checkbox
              id='gowifmi_publie'
              checked={Params.get('gowifmi_publie')}
              onChange={onchangeFilters}
            />
          </div>
          <label htmlFor='gowifmi_publie'>Gowifmi publie</label>
        </div>
        <div className={styles.checkox_contener}>
          <div>
            <Checkbox
              id='gowifmi_no_publie'
              checked={Params.get('gowifmi_no_publie')}
              onChange={onchangeFilters2}
            />
          </div>
          <label htmlFor='gowifmi_no_publie'>Gowifmi ne publie pas</label>
        </div>
      </div>
    </div>
  )
}

export default Gowifmi
