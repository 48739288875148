import styles from '../../../css/mains/verifIdentite.module.css'
import axios from 'axios'
import Swal from 'sweetalert2'
import Switch from '../../ui/Switch'

const IdentityVerification = ({ userData, refetch }) => {
  const handlechange = (event, typeButton) => {
    const submiteData = {
      identitySubmitted: event.target.checked,
    }
    const validData = {
      isIdentityValid: event.target.checked,
    }
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Pour mettre à jour ce utilisateur',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, modifié !',
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .patch(`/user/${userData.id}`, typeButton === 'submit' ? submiteData : validData)
          .then(() => {
            Swal.fire('success', 'utilisateur modifié avec succès', 'success')
            refetch()
          })
          .catch(() => {
            Swal.fire('error', 'échec de la mise à jour', 'error')
          })
      }
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.checkox_container}>
        <div className={styles.text}>
          <label htmlFor='submited'>L'utilisateur à soumis sa pièce d'identité ?</label>
          {userData?.identitySubmitted ? <p>Oui</p> : <p>Non pas encore</p>}
        </div>
        <div>
          <Switch checked={userData?.identitySubmitted} onChange={e => handlechange(e, 'submit')} />
        </div>
      </div>
      <div className={styles.checkox_container}>
        <div className={styles.text}>
          <label htmlFor='publish'>Gowifmi valide cette identité ?</label>
          {userData?.isIdentityValid ? (
            <p>Oui Gowifmi valide cette identité.</p>
          ) : (
            <p>Non Gowifmi ne valide cette identité.</p>
          )}
        </div>
        <div>
          <Switch checked={userData?.isIdentityValid} onChange={e => handlechange(e, 'valid')} />
        </div>
      </div>
    </div>
  )
}

export default IdentityVerification
