import styles from '../../../css/mains/detail.module.css'
import moment from 'moment'

function BasicInfo({ userData }) {
  return (
    <div className={styles.detail_info_1}>
      <h2>Informations</h2>
      <p>
        Ici vous trouverez les informations les plus importantes le contact que vous avez
        séléctionné
      </p>
      <ul>
        <li>
          Prénom: <span>{userData.first_name}</span>
        </li>
        <li>
          Nom: <span>{userData.name}</span>
        </li>
        <li>
          Email pro: <span>{userData.work_email}</span>
        </li>
        <li>
          Adresse complete: <span>{userData.address?.full_address}</span>
        </li>
        <li>
          Pays: <span>{userData.address?.country}</span>
        </li>
        <li>
          Abbreviation pays: <span>{userData.address?.country_abbreviation}</span>
        </li>
        <li>
          Ville: <span>{userData.address?.city}</span>
        </li>
        <li>
          Etat: <span>{userData.ddress?.state}</span>
        </li>
        <li>
          Code postale: <span>{userData.address?.postal_code}</span>
        </li>
        <li>
          Date de naissance: <span>{moment(userData.date_of_birth).format('ll')}</span>
        </li>
        <li>
          Sexe: <span>{userData.gender}</span>
        </li>
        <li>
          Langue_1: <span>{userData.language_1}</span>
        </li>
        <li>
          Langue_2: <span>{userData.language_2}</span>
        </li>
        <li>
          Categories:{' '}
          {userData.profile_categories.map(item => (
            <span key={item}>{item}</span>
          ))}
        </li>
        <li>Apropos de moi:</li>
        <p>{userData.about_me}</p>
      </ul>
    </div>
  )
}
export default BasicInfo
