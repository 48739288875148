import styles from '../../../css/mains/verifIdentite.module.css'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'

const DeleteUser = ({ userData }) => {
  const history = useHistory()

  const handlechange = () => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Pour supprimer cet utilisateur',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimé !',
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .delete(`/user/${userData.id}`)
          .then(() => {
            Swal.fire('success', 'utilisateur supprimé avec succès', 'success')
            history.push('/users/all')
          })
          .catch(() => {
            Swal.fire('error', 'échec de la mise à jour', 'error')
          })
      }
    })
  }

  return (
    <div className={styles.container}>
      <button type='button' onClick={handlechange}>
        Supprimer l'utilisateur
      </button>
    </div>
  )
}

export default DeleteUser
