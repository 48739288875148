import styles from '../../../../../../css/modehotes/annonce/filtres/filtre.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import Checkbox from '../../../../../ui/Checkbox'

const Caracteristique = () => {
  const array = [
    { name: 'Petit déjeuner', value: '17' },
    { name: 'Logement fumeur', value: '39' },
    { name: 'Cheminée', value: '16' },
    { name: 'Jacuzzi', value: '14' },
    { name: 'Salle de sport', value: '15' },
  ]
  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  URLSearchParams.prototype.remove = function (key, value) {
    const entries = this.getAll(key)
    const newEntries = entries.filter(entry => entry !== value)
    this.delete(key)
    newEntries.forEach(newEntry => this.append(key, newEntry))
  }
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      Params.delete('page')
      Params.append('amenities', e.target.value)
      history.push(`${path}?${Params}`)
    } else {
      Params.delete('page')
      Params.remove('amenities', e.target.value)
      history.push(`${path}?${Params}`)
    }
  }
  return (
    <div>
      <p>Caractéristiques</p>
      <div className={styles.grid_system}>
        {array.map(item => (
          <div key={item.name} className={styles.checkox_contener}>
            <div>
              <Checkbox
                id={item.name}
                value={item.value}
                checked={Params.getAll('amenities').includes(item.value)}
                onChange={onchangeFilters}
              />
            </div>
            <label htmlFor={item.name}>{item.name}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Caracteristique
