import { useRef } from 'react'
import styles from '../../../../../../../css/modehotes/annonce/details/gowifmi_publish.module.css'
import moment from 'moment'
import Swal from 'sweetalert2'
import useUpdateHotel from '../../../../../../../hooks/useUpdateHotel'
import LoadingPoint from '../../../../../../LoadingPoint'
import CheckList from './CheckList'
import Switch from '../../../../../../ui/Switch'

const GowifmiPublie = ({ annonce }) => {
  const { loading, updateAndPublish } = useUpdateHotel()
  const hostInfo = annonce.user_id

  const checkListRef = useRef(null)
  const handleCheckList = () => {
    checkListRef.current.OpenModal()
  }

  const handleUpdate = event => {
    Swal.fire({
      title: `Attention! Vous voulez ${
        annonce.gowifmi_published ? 'désactiver' : 'publier'
      } cette annonce ?`,
      text: `Une fois l'annonce' ${
        annonce.gowifmi_published ? 'désactivée' : 'publiée'
      }, les voyageurs pourront reserver`,
      showCancelButton: true,
      confirmButtonText: annonce.gowifmi_published ? "Désactiver l'annonce" : "Publier l'annonce",
      cancelButtonText: 'Annuler',
      reverseButtons: true,
      customClass: {
        actions: 'actions_class',
        confirmButton: 'confirm_button_class',
        cancelButton: 'cancel_button_class',
      },
    }).then(result => {
      if (result.isConfirmed) {
        if (!annonce.gowifmi_publish_date) {
          const datas = {
            gowifmi_published: true,
            gowifmi_publish_date: new Date(),
          }
          updateAndPublish(datas)
        } else {
          const datas = {
            gowifmi_published: !event.target.checked,
          }
          updateAndPublish(datas)
        }
      }
    })
  }

  const handlechange = event => {
    if (event.target.checked === true) {
      if (
        hostInfo.isIdentityValid &&
        hostInfo.picture &&
        hostInfo.isEmailVerified &&
        hostInfo.isTelephoneVerified
      ) {
        handleUpdate(event)
      } else {
        handleCheckList()
      }
    } else {
      handleUpdate(event)
    }
  }

  return (
    <>
      <div className={styles.checkox_contener}>
        <div className={styles.text}>
          <label htmlFor='gowifmi_publish'>Gowifmi publie cette annonce ?</label>
          {annonce.gowifmi_published ? (
            <p>
              Oui Gowifmi publie cette annonce le {moment(annonce.gowifmi_publish_date).calendar()}
            </p>
          ) : (
            <p>Non Gowifmi ne publie pas cette annonce.</p>
          )}
        </div>
        <div>
          {loading ? (
            <LoadingPoint />
          ) : (
            <Switch checked={annonce.gowifmi_published} onChange={handlechange} />
          )}
        </div>
      </div>
      <CheckList ref={checkListRef} hostInfo={hostInfo} />
    </>
  )
}
export default GowifmiPublie
