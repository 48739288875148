import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import AccountConnect from '../../../../../users/externals_accounts/AccountConnect'

const AccountConnectHost = ({ reservation }) => {
  return (
    <>
      {reservation.pay_online ? (
        <div className={styles.content}>
          <h3>Partenaire de transfert pour l'hôte</h3>
          <AccountConnect user={reservation.host_id} />
        </div>
      ) : (
        <div className={styles.content}>
          <h3>Envoyez une facture à l'hôte</h3>
          <a href={`https://dashboard.stripe.com/invoices`} target='_blanck'>
            Créer une facture
          </a>
        </div>
      )}
    </>
  )
}

export default AccountConnectHost
