import styles from '../../../../../css/modehotes/reservation/detail.module.css'
import CopyTextToClipboard from '../../../../ui/CopyTextToClipboard'
import AccountCustomer from '../../../../users/externals_accounts/AccountCustomer'

const TravelerInformation = ({ reservation }) => {
  return (
    <div className={styles.block}>
      <h2>Informations personnelles</h2>
      <div className={styles.content}>
        <h3>ID</h3>
        <CopyTextToClipboard text={reservation?.user_id?.id} title='ID' />
      </div>
      <div className={styles.content}>
        <h3>Prénom</h3>
        <p>{reservation?.user_id?.first_name || 'Non défini'}</p>
      </div>
      <div>
        <div className={styles.content}>
          <h3>E-mail</h3>
          <p>{reservation?.user_id?.email || 'Non défini'}</p>
        </div>
        <div className={styles.content}>
          <h3>Téléphone</h3>
          <p>{reservation?.client_infos?.phone || 'Non défini'}</p>
        </div>
      </div>
      <div className={styles.content}>
        <h3>Pays</h3>
        <p>{reservation?.client_infos?.client_country || 'Non défini'}</p>
      </div>
      <div className={styles.content}>
        <h3>Comptes client connectés</h3>
        <AccountCustomer user={reservation.user_id} />
      </div>
      <div className={styles.content}>
        <h3>Plateforme utilisée lors de la réservation</h3>
        <p>Navigateur : {reservation.platform_infos?.browser_name || 'non défini'}</p>
        <p>Version du navigateur : {reservation.platform_infos?.browser_version || 'non défini'}</p>
        <p>Type d'appareil : {reservation.platform_infos?.device_type || 'non défini'}</p>
        <p>Fournisseur d'appareil : {reservation.platform_infos?.device_vendor || 'non défini'}</p>
        <p>Modèle d'appareil : {reservation.platform_infos?.device_model || 'non défini'}</p>
        <p>Système d'exploitation : {reservation.platform_infos?.os_name || 'non défini'}</p>
        <p>
          Version du système d'exploitation :{' '}
          {reservation.platform_infos?.os_version || 'non défini'}
        </p>
      </div>
      <div className={styles.content}>
        <h3>Emplacement utilisé lors de la réservation</h3>
        <p>Country : {reservation.location_infos?.country || 'non défini'}</p>
        <p>Country code : {reservation?.location_infos?.countryCode || 'non défini'}</p>
        <p>City : {reservation.location_infos?.city || 'non défini'}</p>
        <p>Région : {reservation.location_infos?.regionName || 'non défini'}</p>
        <p>Timezone : {reservation.location_infos?.timezone || 'non défini'}</p>
        <p>continent : {reservation.location_infos?.continent || 'non défini'}</p>
        <p>Continent code : {reservation.location_infos?.continentCode || 'non défini'}</p>
        <p>Adresse ip : {reservation.location_infos?.ip || 'non défini'}</p>
        <p>is proxy : {reservation.location_infos?.isProxy.toString()}</p>
      </div>
    </div>
  )
}

export default TravelerInformation
