import { useRef } from 'react'
import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { STATUS_BOOKING } from '../../../../../../enums/booking/status.enums'
import AddRefundTravelModal from './refunds/AddRefundTravelModal'
import RefundPayment from './payments/RefundPayment'
import { addCurrencyComplet } from '../../../../../../utils/addCurrency'

const Cancellation = ({ reservation, calculatedPrices, refetch }) => {
  const refundRef = useRef()
  const handleRefundTravel = () => {
    refundRef.current.OpenModal()
  }

  const { totalRefund } = calculatedPrices

  return (
    <>
      <div className={styles.content}>
        <h3>Annulation</h3>
        {reservation.status === STATUS_BOOKING.CANCELLED ? (
          <div>
            {reservation.isReservation_refunded ? (
              <p>
                Total remboursé au voyageur :{' '}
                {addCurrencyComplet(
                  reservation.refund_total?.refund_amount,
                  reservation.prices?.currency
                )}
              </p>
            ) : (
              <p>
                Total à rembourser au voyageur :{' '}
                {addCurrencyComplet(totalRefund, reservation.prices?.currency)}
              </p>
            )}
            <h4>Motifs d'annulation</h4>
            {reservation.cancellations?.cancel_reason_1 === 'canceled_by_gowifmi' && (
              <p>- Gowifmi qui annule la réservation</p>
            )}
            {reservation.cancellations?.cancel_reason_1 === 'canceled_by_host' && (
              <p>- Hôte qui annule la réservation</p>
            )}
            <p>- {reservation.cancellations?.cancel_reason_2 || 'Non défini'}</p>
            <p>- {reservation.cancellations?.cancel_reason_3 || 'Non défini'}</p>
            <p>- {reservation.cancellations?.cancel_reason_4 || 'Non défini'}</p>
            <p>- {reservation.cancellations?.cancel_explanation || 'Non défini'}</p>
            <p>Annuler Le {moment(reservation.cancellations?.date).format('ll')}</p>
            <div>
              {reservation.isReservation_refunded && (
                <div>
                  <RefundPayment refund={reservation.refund_total} />
                </div>
              )}
            </div>
            <button type='button' onClick={handleRefundTravel}>
              {reservation.isReservation_refunded
                ? 'Modifier le remboursement'
                : 'Enregistrer le remboursement'}
            </button>
          </div>
        ) : (
          <div className={styles.sup}>
            <Link to={`/hosting/reservations/cancellation/${reservation._id}`}>
              Annuler la réservation
            </Link>
          </div>
        )}
      </div>
      <AddRefundTravelModal reservation={reservation} refetch={refetch} ref={refundRef} />
    </>
  )
}

export default Cancellation
