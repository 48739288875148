import React, { useState } from 'react'
import styles from '../../../../../../../css/modehotes/reservation/transfer_modal.module.css'
import axios from 'axios'
import LoadingPoint from '../../../../../../LoadingPoint'
import Toast from '../../../../../../infos/toast'
import { useForm } from 'react-hook-form'
import { STATUS_STRANSFER } from '../../../../../../../enums/booking/transferStatus.enums'
import CloseButton from '../../../../../../ui/CloseButton'
import Modal from '../../../../../../ui/Modal'

const AffiliationModal = React.forwardRef(
  ({ reservation, calculatedPrices, affiliation, refetchAffiliation }, ref) => {
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)

    React.useImperativeHandle(ref, () => ({
      OpenModal: () => {
        setModal(true)
      },
    }))

    const {
      register,
      reset,
      formState: { errors },
      handleSubmit,
    } = useForm({ mode: 'onChange' })
    const onSubmit = Formdata => {
      const data = {
        traveler: reservation.user_id.id,
        reservation: reservation._id,
        transfer_status: Formdata.transfer_status,
        transfer_service: Formdata.transfer_service,
        transfer_id: Formdata.transfer_id,
        total_fee: Formdata.total_fee,
        currency: 'usd',
        comment: Formdata.comment,
      }
      setLoading(true)
      axios
        .patch(`/gowifmi_transfer_payment/to_referrer/${affiliation._id}`, data)
        .then(async res => {
          Toast.fire({ icon: 'success', title: 'Versement ajouté avec succès' })
          await refetchAffiliation()
          reset()
        })
        .catch(err => {
          if (err.response?.data?.message === 'transfer_exist') {
            Toast.fire({
              icon: 'error',
              title:
                "Il semble q'un tranfert vers cet hôte est en cours de traitement veuillez actualiser la page",
            })
          } else {
            Toast.fire({ icon: 'error', title: 'Un problème est survenu au niveau de serveur' })
          }
        })
        .finally(() => {
          setLoading(false)
          setModal(false)
        })
    }

    const closeModal = () => {
      setModal(false)
    }

    return (
      <>
        <Modal isOpen={modal} onClose={closeModal}>
          <form
            autoComplete='off'
            onSubmit={handleSubmit(onSubmit)}
            className={styles.modal_content}
          >
            <div className={styles.m_head}>
              <CloseButton onClick={closeModal} />
              <div></div>
            </div>
            <div className={styles.m_body}>
              <div className={styles.m_body_head}>
                <h2>Ajouter le transfert du parrainage</h2>
              </div>
              <div>
                <label htmlFor='transfer_status'>Statut *</label>
                <select
                  id='transfer_status'
                  defaultValue={affiliation.transfer_status}
                  {...register('transfer_status', {
                    required: 'Transfert statut est obligatoire.',
                  })}
                >
                  <option value=''>Sélectionner le statut</option>
                  <option value={STATUS_STRANSFER.COMPLETE}>Réussi</option>
                  <option value={STATUS_STRANSFER.IN_PROGRESS}>Traitement</option>
                  <option value={STATUS_STRANSFER.CANCELLED}>Annulé</option>
                  <option value={STATUS_STRANSFER.REJECTED}>Echoué</option>
                </select>
                {errors.transfer_status && (
                  <p className={styles.error}>{errors.transfer_status.message}</p>
                )}
              </div>
              <div>
                <label htmlFor='transfer_service'>Service *</label>
                <select
                  id='transfer_service'
                  defaultValue={affiliation.transfer_service}
                  {...register('transfer_service', {
                    required: 'Transfert service est obligatoire.',
                  })}
                >
                  <option value=''>Sélectionner un service</option>
                  <option value='stripe'>Stripe</option>
                  <option value='paypal'>Paypal</option>
                  <option value='payoneer'>Payoneer</option>
                  <option value='mercury'>Mercury</option>
                </select>
                {errors.transfer_service && (
                  <p className={styles.error}>{errors.transfer_service.message}</p>
                )}
              </div>

              <div>
                <label htmlFor='transfer_id'>Identifiant du transfert *</label>
                <input
                  type='text'
                  id='transfer_id'
                  defaultValue={affiliation.transfer_id}
                  {...register('transfer_id', {
                    required: 'Transfert id est obligatoire.',
                  })}
                />
                {errors.transfer_id && <p className={styles.error}>{errors.transfer_id.message}</p>}
              </div>

              <div>
                <label htmlFor='total_fee'>Total *</label>
                <input
                  type='text'
                  id='total_fee'
                  defaultValue={affiliation.total_fee}
                  {...register('total_fee', {
                    required: 'Total est obligatoire.',
                  })}
                />
                {errors.total_fee && <p className={styles.error}>{errors.total_fee.message}</p>}
              </div>

              <div>
                <label htmlFor='comment'>Ajouter un commentaire</label>
                <textarea
                  type='text'
                  id='comment'
                  defaultValue={affiliation.comment}
                  placeholder='commentaire...'
                  {...register('comment')}
                ></textarea>
              </div>
            </div>
            <div className={styles.btn_action}>
              <button type='button' onClick={closeModal}>
                Annuler
              </button>
              {loading ? (
                <button type='button' disabled className={styles.loading}>
                  <LoadingPoint />
                </button>
              ) : (
                <button type='submit'>Enregister le transfert</button>
              )}
            </div>
          </form>
        </Modal>
      </>
    )
  }
)
export default AffiliationModal
