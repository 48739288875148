import { useQuery } from 'react-query'
import axios from 'axios'

function useReservation() {
  const { data = {}, isLoading } = useQuery(
    'dashboard_reservation_and_chart',
    () => axios.get('/gowifmi_reservations/dashboard').then(res => res.data),
    {
      refetchOnWindowFocus: false,
    }
  )

  const {
    allReservations = [],
    requests = [],
    future = [],
    inProgress = [],
    complete = [],
    cancelled = [],
    deleted = [],
    processPaymentSucceeded = [],
    refundReservation = [],
    transferToHost = [],
    refundModification = [],
  } = data

  return {
    loading: isLoading,
    allReservations,
    requests,
    future,
    inProgress,
    complete,
    cancelled,
    deleted,
    processPaymentSucceeded,
    refundReservation,
    transferToHost,
    refundModification,
  }
}

export default useReservation
