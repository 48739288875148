import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import moment from 'moment'

const DateReservation = ({ reservation }) => {
  return (
    <div>
      <div className={styles.content}>
        <h3>Dates</h3>
        <div className={styles.dates_block}>
          <div>
            <h5>Arrivée</h5>
            <p>{moment(reservation.reservation_infos?.checkin).format('ll')}</p>
          </div>
          <div>
            <h5>Depart</h5>
            <p>{moment(reservation.reservation_infos?.checkout).format('ll')}</p>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <h3>Durée totale du séjour</h3>
        <p>
          {reservation?.reservation_infos?.number_of_nights}{' '}
          {reservation?.reservation_infos?.number_of_nights > 1 ? 'Nuits' : 'Nuit'}
        </p>
      </div>
    </div>
  )
}

export default DateReservation
