import React, { useState } from 'react'
import styles from '../../css/filter_booking/all_filter.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import Modal from '../ui/Modal'
import CloseButton from '../ui/CloseButton'
import Status from './filtres/Status'
import ByReference from './filtres/ByReference'
import ByReservationId from './filtres/ByReservationId'
import ByTravelerId from './filtres/ByTravelerId'
import ByHotelId from './filtres/ByHotelId'
import ByHostId from './filtres/ByHostId'
import RefundTraveler from './filtres/RefundTraveler'
import PaymentToHost from './filtres/PaymentToHost'
import PaymentMode from './filtres/PaymentMode'
import RefundStatus from './filtres/RefundStatus'
import PaymentIntentStatus from './filtres/PaymentIntentStatus'
import SetupIntentStatus from './filtres/SetupIntentStatus'
import RefundModification from './filtres/RefundModification'
import ProcessPaymentStatus from './filtres/ProcessPaymentStatus'
import LoadingPoint from '../LoadingPoint'

const Filter = React.forwardRef(({ isLoading }, ref) => {
  const [modal, setModal] = useState(false)
  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))
  const history = useHistory()
  const location = useLocation()
  const page = location.pathname
  const path = location.pathname.split('/')[3]

  const deleteAllFilters = () => {
    history.push(`${page}`)
    setModal(false)
  }

  return (
    <>
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <div className={styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <h3>Filtres</h3>
            <div></div>
          </div>
          <div className={styles.m_body}>
            <ByReference />
            {path === 'all' && <Status />}
            <PaymentMode />
            {path === 'process' || path === 'all' ? <ProcessPaymentStatus /> : null}
            {path === 'upcoming' || path === 'inprogress' || path === 'all' ? (
              <RefundModification />
            ) : null}
            {path === 'request' || path === 'all' ? <SetupIntentStatus /> : null}
            {path === 'upcoming' || path === 'all' ? <PaymentIntentStatus /> : null}
            {path === 'canceled' || path === 'all' ? (
              <>
                <RefundTraveler />
                <RefundStatus />
              </>
            ) : null}
            {path === 'completed' || path === 'canceled' || path === 'all' ? (
              <PaymentToHost />
            ) : null}
            <ByReservationId />
            <ByTravelerId />
            <ByHotelId />
            <ByHostId />
          </div>
          <div className={styles.m_footer}>
            <button type='button' className={styles.effacher} onClick={deleteAllFilters}>
              Tout effacer
            </button>

            <button type='button' className={styles.appliquer} onClick={() => setModal(false)}>
              {isLoading ? <LoadingPoint /> : 'Afficher les résultats'}
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
})

export default Filter
