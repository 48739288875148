import styles from '../../../../css/modehotes/history/list_history.module.css'
import { TYPE_HISTORY, MODIFY_BY } from '../../../../enums/logement/type_history.enums'
import { Link } from 'react-router-dom'
import momentTimeZone from 'moment-timezone'
import ChangeZone from './ChangeZone'

const ListHistory = ({ data }) => {
  const userTimezone = momentTimeZone.tz.guess()

  return (
    <div className={styles.container}>
      {data.map(item => (
        <div className={styles.container_block} key={item._id}>
          <p className={styles.p_date}>
            {momentTimeZone.utc(item.createdAt).tz(userTimezone).calendar()}
          </p>
          {item.type === TYPE_HISTORY.CREATE_HOTEL && (
            <h2>Création de l'annonce · {item.hotel?.title ?? 'Nouvelle'}</h2>
          )}
          {item.type === TYPE_HISTORY.UPDATE_HOTEL && (
            <h2>Modification de l'annonce · {item.hotel?.title ?? 'Nouvelle'}</h2>
          )}
          {item.type === TYPE_HISTORY.ADD_IMAGE && (
            <h2>Ajout des images · {item.hotel?.title ?? 'Nouvelle'}</h2>
          )}
          {item.type === TYPE_HISTORY.UPDATE_IMAGE && (
            <h2>Modification de l'image · {item.hotel?.title ?? 'Nouvelle'}</h2>
          )}
          {item.type === TYPE_HISTORY.DELETE_IMAGE && (
            <h2>Suppresion de l'image · {item.hotel?.title ?? 'Nouvelle'}</h2>
          )}
          {item.type === TYPE_HISTORY.DEACTIVETE_HOTEL && (
            <h2>Désactivation de l'annonce · {item.hotel?.title ?? 'Nouvelle'}</h2>
          )}
          {item.modifyBy === MODIFY_BY.HOST && (
            <p className={styles.p_profil}>Modifié par {item.user?.first_name}</p>
          )}
          {item.modifyBy === MODIFY_BY.GOWIFMI && (
            <p className={styles.p_profil}>Modifié par Gowifmi · {item.user?.first_name}</p>
          )}
          <ChangeZone history={item} />
          <div className={styles.footer}>
            <Link to={`/hosting/listings/details/${item.hotel?._id}`}>Voir l'annonce</Link>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ListHistory
