import styles from '../../css/users/list_user.module.css'
import { Link } from 'react-router-dom'
import moment from 'moment'
import LoadingPoint from '../LoadingPoint'
import ValidSvg from '../svgs/ValidSvg'
import Header from './Header'
import Pagination from '../ui/Pagination'

const UserList = ({ isLoading, error, data }) => {
  return (
    <div className={styles.container}>
      <Header isLoading={isLoading} data={data} />
      {isLoading ? (
        <div className={styles.container_load_list}>
          <LoadingPoint />
        </div>
      ) : (
        <>
          {error && (
            <div className={styles.no_result}>
              <p>Une erreur est survenue veuilllez réessayer</p>
            </div>
          )}
          {data?.totalRecords < 1 && (
            <div className={styles.no_result}>
              <h2>Aucun résultat trouvé</h2>
              <p>Veuillez essayer de sélectionner différents mots-clés ou filtres.</p>
            </div>
          )}
          {data?.totalRecords > 0 && (
            <div className={styles.container_arry}>
              <table className={styles.customers}>
                <thead>
                  <tr>
                    <th className={styles.image}>Profil</th>
                    <th>Email</th>
                    <th>Hôte</th>
                    <th>Entréprise</th>
                    <th>Employée Gowifmi</th>
                    <th>Désactivé</th>
                    <th>Identité vérifié</th>
                    <th>Date de modification</th>
                    <th>Date de création</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.users?.map(item => (
                    <tr key={item.id}>
                      <td className={styles.image}>
                        <Link to={`/users/details/${item.id}`}>
                          <div>
                            <div className={styles.card}>
                              {item.picture ? (
                                <img src={item.picture} alt='couverture' />
                              ) : (
                                <h2>{item.first_name?.slice(0, 1)}</h2>
                              )}
                            </div>
                          </div>
                          <p>{item.first_name}</p>
                        </Link>
                      </td>
                      <td className={styles.rows}>{item.email}</td>
                      <td className={styles.rows}>
                        <div className={styles.valid_block}>
                          <ValidSvg state={item.isHost} />
                          <span>{item.isHost ? 'Oui' : 'Non'}</span>
                        </div>
                      </td>
                      <td className={styles.rows}>
                        <div className={styles.valid_block}>
                          <ValidSvg state={item.isCompany_account} />
                          <span>{item.isCompany_account ? 'Oui' : 'Non'}</span>
                        </div>
                      </td>
                      <td className={styles.rows}>
                        <div className={styles.valid_block}>
                          <ValidSvg state={item.isGowifmiEmployee} />
                          <span>{item.isGowifmiEmployee ? 'Oui' : 'Non'}</span>
                        </div>
                      </td>
                      <td className={styles.rows}>
                        <div className={styles.valid_block}>
                          <ValidSvg state={item.isDisabled} />
                          <span>{item.isDisabled ? 'Oui' : 'Non'}</span>
                        </div>
                      </td>
                      <td className={styles.rows}>
                        <div className={styles.valid_block}>
                          <ValidSvg state={item.isIdentityValid} />
                          <span>{item.isIdentityValid ? 'Oui' : 'Non'}</span>
                        </div>
                      </td>
                      <td className={styles.rows}>{moment(item.updatedAt).format('ll')}</td>
                      <td className={styles.rows}>{moment(item.createdAt).format('ll')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination data={data} />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default UserList
