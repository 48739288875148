import styles from '../../../css/accounts/info_perso.module.css'
import LoadingPoint from '../../LoadingPoint'
import { useForm } from 'react-hook-form'
import { CountryNameOption } from '../../country_options/CountryName'
import useUserHook from '../../../hooks/useUserHook'

const Addresse = ({ active, chageActive }) => {
  const { userHook, loadingUpdate, updateUser, errorUpdate } = useUserHook(chageActive)
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange' })
  const onSubmit = data => {
    const selectedCountryInfo = JSON.parse(data.country)
    const newData = {
      ...data,
      country: selectedCountryInfo.country,
      countryCode: selectedCountryInfo.countryAbbreviation,
    }
    updateUser(newData)
  }
  if (errorUpdate) alert('error connexion retry')
  return (
    <>
      <div
        className={
          active && active !== 6 ? `${styles.form_content} ${styles.disable}` : styles.form_content
        }
      >
        <div className={styles.text}>
          <h3>Adresse</h3>
          {active === 6 ? (
            <button type='button' onClick={() => chageActive()}>
              Annuler
            </button>
          ) : (
            <button type='button' disabled={active && active !== 6} onClick={() => chageActive(6)}>
              Modifier
            </button>
          )}
        </div>

        <div className={styles.modif_block}>
          {active !== 6 ? (
            <p>
              {userHook.postal_address
                ? `${userHook.postal_address}, ${userHook.city}, ${userHook.postal_code}, ${userHook.country}`
                : 'Information non fournie'}
            </p>
          ) : (
            <>
              <p>Utilisez une adresse permanente où vous pouvez recevoir du courrier.</p>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                <div className={styles.input_box}>
                  <div>
                    <label htmlFor='country'>Pays</label>
                    <select
                      id='country'
                      defaultValue={JSON.stringify({
                        country: userHook.country,
                        countryAbbreviation: userHook.countryCode,
                      })}
                      {...register('country')}
                    >
                      <CountryNameOption />
                    </select>
                  </div>
                  <div>
                    <label htmlFor='adresse'>Adresse</label>
                    <input
                      type='text'
                      id='adresse'
                      defaultValue={userHook.postal_address}
                      {...register('postal_address', { required: true })}
                      placeholder='Adresse postale, ex: 12 Rue de paris'
                    />
                    {errors.postal_address && (
                      <span className={styles.erreur}>Adresse postale manquante</span>
                    )}
                  </div>
                  <div>
                    <label htmlFor='postal_code'>Code postale</label>
                    <input
                      type='text'
                      id='postal_code'
                      placeholder='Code postale'
                      defaultValue={userHook.postal_code}
                      {...register('postal_code', { required: true })}
                    />
                    {errors.postal_code && (
                      <span className={styles.erreur}>Code postale manquant</span>
                    )}
                  </div>
                  <div>
                    <label htmlFor='city'>Ville</label>
                    <input
                      type='text'
                      id='city'
                      placeholder='city'
                      defaultValue={userHook.city}
                      {...register('city', { required: true })}
                    />
                    {errors.city && <span className={styles.erreur}>Ville manquante</span>}
                  </div>
                </div>
                {loadingUpdate ? (
                  <button disabled className={styles.loading}>
                    <LoadingPoint />
                  </button>
                ) : (
                  <button type='submit'>Enregistrer</button>
                )}
              </form>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Addresse
