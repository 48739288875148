import { useState } from 'react'
import styles from '../../../css/modehotes/facturation/invoice.module.css'
import axios from 'axios'
import LoadingPoint from '../../../components/LoadingPoint'
import Toast from '../../../components/infos/toast'
import Swal from 'sweetalert2'

const GenerateInvoice = ({ refetch }) => {
  const [loading, setLoading] = useState(false)

  const handleGenerateInvoice = () => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'De vouloir généré des facture pour le mois passé',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, généré !',
    }).then(result => {
      if (result.isConfirmed) {
        setLoading(true)
        axios
          .post(`/bill/generate`)
          .then(() => {
            refetch()
            Toast.fire({
              icon: 'success',
              title: 'Les factures ont étè générer avec succés.',
            })
          })
          .catch(err => {
            Toast.fire({ icon: 'error', title: 'Un problème est survenu au niveau de serveur' })
          })
          .finally(() => {
            setLoading(false)
          })
      }
    })
  }

  return (
    <div className={styles.generate_block}>
      {loading ? (
        <button type='button' disabled className={styles.loading}>
          <LoadingPoint />
        </button>
      ) : (
        <button type='button' onClick={handleGenerateInvoice}>
          Génerer les factures
        </button>
      )}
    </div>
  )
}

export default GenerateInvoice
