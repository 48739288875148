export function addCurrency(price = 0, currency) {
  return price.toLocaleString(undefined, {
    style: 'currency',
    currency: currency,
  })
}

export function addCurrencyComplet(price = 0, currency) {
  return price.toLocaleString(undefined, {
    style: 'currency',
    currency: 'usd',
    maximumFractionDigits: 2,
  })
}
