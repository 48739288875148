import styles from '../../../../css/modehotes/statistics/search_history/list_history.module.css'
import moment from 'moment-timezone'

const ChangeZone = ({ history }) => {
  return (
    <div className={styles.modified_block}>
      {history.user && (
        <p>
          Utilisateur : <span>{history.user?.first_name}</span>
        </p>
      )}
      {history.place && (
        <p>
          Lieu : <span>{history.place}</span>
        </p>
      )}
      {history.place_id && (
        <p>
          ID du Lieu : <span>{history.place_id}</span>
        </p>
      )}
      {history.search_by_map !== undefined && (
        <p>
          Recherche par Carte : <span>{history.search_by_map ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {history.page !== undefined && (
        <p>
          Page : <span>{history.page}</span>
        </p>
      )}
      {history.adults !== undefined && (
        <p>
          Adultes : <span>{history.adults}</span>
        </p>
      )}
      {history.children !== undefined && (
        <p>
          Enfants : <span>{history.children}</span>
        </p>
      )}
      {history.pets !== undefined && (
        <p>
          Animaux : <span>{history.pets}</span>
        </p>
      )}
      {history.lng && (
        <p>
          Longitude : <span>{history.lng}</span>
        </p>
      )}
      {history.lat && (
        <p>
          Latitude : <span>{history.lat}</span>
        </p>
      )}
      {history.checkin && (
        <p>
          Date d'Arrivée : <span>{moment(history.checkin).format('ll')}</span>
        </p>
      )}
      {history.checkout && (
        <p>
          Date de Départ : <span>{moment(history.checkout).format('ll')}</span>
        </p>
      )}
      {history.amenities && (
        <p>
          Équipements : <span>{history.amenities.join(', ')}</span>
        </p>
      )}
      {history.min_price !== undefined && (
        <p>
          Prix Minimum : <span>{history.min_price}</span>
        </p>
      )}
      {history.max_price !== undefined && (
        <p>
          Prix Maximum : <span>{history.max_price}</span>
        </p>
      )}
      {history.number_of_rooms !== undefined && (
        <p>
          Nombre de Chambres : <span>{history.number_of_rooms}</span>
        </p>
      )}
      {history.number_of_beds !== undefined && (
        <p>
          Nombre de Lits : <span>{history.number_of_beds}</span>
        </p>
      )}
      {history.number_of_bathrooms !== undefined && (
        <p>
          Nombre de Salles de Bain : <span>{history.number_of_bathrooms}</span>
        </p>
      )}
      {history.arrival_flexible && (
        <p>
          Arrivée Flexible : <span>{history.arrival_flexible}</span>
        </p>
      )}
      {history.property_type_group && (
        <p>
          Groupes de Types de Propriété : <span>{history.property_type_group.join(', ')}</span>
        </p>
      )}
      {history.languages && (
        <p>
          Langues : <span>{history.languages.join(', ')}</span>
        </p>
      )}
      {history.accommodation_type && (
        <p>
          Types d'Hébergement : <span>{history.accommodation_type.join(', ')}</span>
        </p>
      )}
      {history.cancellation_policy !== undefined && (
        <p>
          Annulation gratuite : <span>{history.cancellation_policy ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {history.pay_online !== undefined && (
        <p>
          Paiement en Ligne : <span>{history.pay_online ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {history.pay_at_host !== undefined && (
        <p>
          Paiement chez l'Hôte : <span>{history.pay_at_host ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {history.instant_booking !== undefined && (
        <p>
          Réservation Instantanée : <span>{history.instant_booking ? 'Oui' : 'Non'}</span>
        </p>
      )}
    </div>
  )
}

export default ChangeZone
