import styles from '../../css/ui/switch.module.css'

const Switch = ({ checked, disabled, onChange }) => {
  return (
    <label className={disabled ? `${styles.container} ${styles.disable}` : styles.container}>
      <input type='checkbox' disabled={disabled} checked={checked} onChange={onChange} />
      <span className={styles.slider}></span>
    </label>
  )
}

export default Switch
