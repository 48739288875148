import { Link } from 'react-router-dom'
import Tooltip from '../../../../../../ui/Tooltip'
import CloseSvg from '../../../../../../svgs/CloseSvg'

const ClosePage = () => {
  return (
    <Tooltip title='Fermer'>
      <div>
        <Link to='/hosting/listings'>
          <CloseSvg />
        </Link>
      </div>
    </Tooltip>
  )
}

export default ClosePage
