import { useState, useEffect } from 'react'
import styles from '../../../css/affiliations/revenues/header.module.css'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import SelectDate from '../../ui/SelectDate'

let timeoutId

const Header = ({ selectedDate, setSelectedDate }) => {
  const history = useHistory()
  const location = useLocation()
  const pathname = location.pathname
  const Query = location.search
  const params = new URLSearchParams(Query)
  const text = params.get('referrer')
  const [search, setSearch] = useState(text || '')
  const [searchUpdated, setSearchUpdated] = useState(false)

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    if (searchUpdated) {
      timeoutId = setTimeout(() => {
        if (search.length > 0) {
          params.delete('page')
          params.set('referrer', search)
          history.push(`${pathname}?${params}`)
        } else {
          params.delete('page')
          params.delete('referrer')
          history.push(`${pathname}?${params}`)
        }
      }, 700)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [search])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.search_block}>
          <label htmlFor='search'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 32 32'
              aria-hidden='true'
              role='presentation'
              focusable='false'
              style={{
                display: 'block',
                fill: 'none',
                height: '16px',
                width: '16px',
                stroke: 'currentcolor',
                strokeWidth: '4',
                overflow: 'visible',
              }}
            >
              <path fill='none' d='M13 24a11 11 0 1 0 0-22 11 11 0 0 0 0 22zm8-3 9 9'></path>
            </svg>
            <input
              type='search'
              id='search'
              placeholder='Parrain ID'
              value={search}
              onChange={e => {
                setSearchUpdated(true)
                setSearch(e.target.value)
              }}
            />
            <div className={search.length > 0 ? styles.close_active : styles.close_disable}>
              <button
                type='button'
                className={styles.close}
                onClick={() => {
                  setSearchUpdated(true)
                  setSearch('')
                }}
              >
                <svg
                  viewBox='0 0 32 32'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                >
                  <path d='m6 6 20 20'></path>
                  <path d='m26 6-20 20'></path>
                </svg>
              </button>
            </div>
          </label>
        </div>
        <SelectDate selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
      </div>
    </>
  )
}

export default Header
