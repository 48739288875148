import styles from '../../../css/affiliations/navigation.module.css'
import { Link } from 'react-router-dom'

const Navigation = ({}) => {
  const path = window.location.pathname.split('/')[2]

  return (
    <>
      <div className={styles.home_nav}>
        <div></div>
        <div className={styles.links_block}>
          <Link to='/sponsorship/sponsors' className={path === 'sponsors' ? styles.active : ''}>
            Vos filleuls
          </Link>
          <Link to='/sponsorship/revenues' className={path === 'revenues' ? styles.active : ''}>
            Vos revenus
          </Link>
        </div>
      </div>
    </>
  )
}

export default Navigation
