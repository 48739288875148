import styles from '../../../css/modehotes/reservation/component.module.css'
import { UPDATE_BOOKING } from '../../../enums/booking/bookingUpdate.enums'

const ModificationReservationStatus = ({ state }) => {
  return (
    <>
      {state === UPDATE_BOOKING.REQUEST && (
        <div className={styles.block}>
          <p className={styles.incomplet}>
            <span>Demande</span>
          </p>
        </div>
      )}
      {state === UPDATE_BOOKING.ACCEPTED && (
        <div className={styles.block}>
          <p className={styles.complet}>
            <span>Acceptée</span>
          </p>
        </div>
      )}
      {state === UPDATE_BOOKING.REJECTED && (
        <div className={styles.block}>
          <p className={styles.mal_passe}>
            <span>Refusée</span>
          </p>
        </div>
      )}
      {!state && (
        <div className={styles.block}>
          <p className={styles.mal_passe}>
            <span>---</span>
          </p>
        </div>
      )}
    </>
  )
}

export default ModificationReservationStatus
