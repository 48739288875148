import styles from '../../../css/mains/detail.module.css'
import moment from 'moment'
import axios from 'axios'
import ValidSvg from '../../svgs/ValidSvg'
import LoadingPoint from '../../LoadingPoint'
import { useQuery } from 'react-query'

function StripeConnect({ userData }) {
  const { isLoading, error, data } = useQuery(
    `connect_strope_ecount-${userData.account_connect_stripe}`,
    () =>
      axios
        .get(`stripe_connect_account/${userData.account_connect_stripe}/${userData.id}`)
        .then(res => {
          return res.data
        })
  )

  return (
    <div className={styles.detail_info_1}>
      <h2>Stripe compte connecte</h2>
      <p>Ici vous trouverez les informations sur son compte connect stripe</p>
      {isLoading ? (
        <LoadingPoint />
      ) : (
        <div>
          {error ? (
            <p className={styles.error_message}>{error.toString()}</p>
          ) : (
            <ul>
              <li>
                id: <span>{data?.id}</span>
              </li>
              <li>
                type: <span>{data?.type}</span>
              </li>
              <li>
                business_type: <span>{data?.business_type}</span>
              </li>
              <li>
                Pays: <span>{data?.country}</span>
              </li>
              <li>
                <span>
                  <ValidSvg state={data?.details_submitted} />
                </span>
                details_submitted
              </li>
              <li>
                <span>
                  <ValidSvg state={data?.charges_enabled} />
                </span>
                charges_enabled
              </li>
              <li>
                <span>
                  <ValidSvg state={data?.payouts_enabled} />
                </span>
                payouts_enabled
              </li>
              <li>
                disabled_reason:
                <span>{data?.disabled_reason}</span>
              </li>
              <li>
                external_accounts total_count: <span>{data?.external_accounts.total_count}</span>
              </li>
              <li>
                capabilities card_payments: <span>{data?.capabilities.card_payments}</span>
              </li>
              <li>
                capabilities transfers: <span>{data?.capabilities.transfers}</span>
              </li>
              <li>
                aller sur son profil:{' '}
                <span>
                  <a
                    href={`https://dashboard.stripe.com/connect/accounts/${data?.account_connect_stripe}/activity`}
                    target='_blanck'
                  >
                    Voir le details du compte
                  </a>
                </span>
              </li>
              <li>
                created: <span>{moment(data?.created * 1000).format('ll')}</span>
              </li>
            </ul>
          )}
        </div>
      )}
    </div>
  )
}
export default StripeConnect
