import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import AppLayout from '../../layouts/AppLayout'
import { User } from '../../context/UserContext'
import UserList from '../../components/users/UserList'
import { useQuery } from 'react-query'

function ListUserConnectedSite() {
  const [searchParams, setSearchParams] = useState(window.location.search)

  const user = useContext(User)
  const history = useHistory()

  useEffect(() => {
    if (user === null) {
      history.push('/')
    }
    const unlisten = history.listen(location => {
      setSearchParams(location.search)
    })

    return () => {
      unlisten()
    }
  }, [history])

  const fetchData = async () => {
    const { data } = await axios.get(`/user/connected_site${searchParams}`)
    return data
  }

  const {
    data = {},
    isLoading,
    error,
  } = useQuery([`users_connected_site`, searchParams], fetchData)

  return (
    <AppLayout>
      <UserList isLoading={isLoading} data={data} error={error} />
    </AppLayout>
  )
}
export default ListUserConnectedSite
