import React, { useState } from 'react'
import styles from '../../../../css/modehotes/annonce/filtres/filtre.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import Chambre from './filtres/Chambre'
import Equipement from './filtres/Equipement'
import OptionReservation from './filtres/OptionReservation'
import Statut from './filtres/Statut'
import ModalCustomisable from '../../../ui/ModalCustomisable'
import CloseButton from '../../../ui/CloseButton'
import Gowifmi from './filtres/Gowifmi'
import ByHostId from './filtres/ByHostId'
import ByHotelId from './filtres/ByHotelId'
import LoadingPoint from '../../../LoadingPoint'

const Filter = React.forwardRef(({ isLoading }, ref) => {
  const [modal, setModal] = useState(false)
  React.useImperativeHandle(ref, () => ({
    OpenModal: () => {
      setModal(true)
    },
  }))
  const history = useHistory()
  const location = useLocation()
  const page = location.pathname
  const deleteAllFilters = () => {
    history.push(`${page}`)
    setModal(false)
  }

  return (
    <>
      <ModalCustomisable isOpen={modal} onClose={() => setModal(false)}>
        <div className={modal ? `${styles.modal_content} ${styles.active}` : styles.modal_content}>
          <div className={styles.m_head}>
            <CloseButton onClick={() => setModal(false)} />
            <h3>Filtres</h3>
            <div></div>
          </div>
          <div className={styles.m_body}>
            <ByHotelId />
            <Statut />
            <Gowifmi />
            <ByHostId />
            <Chambre />
            <Equipement />
            <OptionReservation />
          </div>
          <div className={styles.m_footer}>
            <button type='button' className={styles.effacher} onClick={deleteAllFilters}>
              Tout effacer
            </button>

            <button type='button' className={styles.appliquer} onClick={() => setModal(false)}>
              {isLoading ? <LoadingPoint /> : 'Afficher les résultats'}
            </button>
          </div>
        </div>
      </ModalCustomisable>
    </>
  )
})

export default Filter
