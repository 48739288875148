import { useState, useEffect } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import MainStatut from './components/MainStatut'
import GowifmiPublish from './components/GowifmiPublish'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'
import { SATATUS_ANNONCE } from '../../../../../../enums/logement/status.enums'
import RadioInput from '../../../../../ui/RadioInput'

const Status = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [statut, setStatut] = useState('')
  const { loading, updateData } = useUpdateHotel(setOpen)

  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      status: statut,
    }
    updateData(datas)
  }
  useEffect(() => {
    setStatut(annonce.status || '')
  }, [annonce])
  const openFuction = () => {
    setStatut(annonce.status || '')
    setOpen(true)
  }
  const reset = () => {
    setStatut()
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      {!open ? (
        <Section title="Statut de l'annonce" handleOpen={openFuction}>
          <MainStatut annonce={annonce} />
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title="Statut de l'annonce" handleReset={reset}></ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div className={styles.input_block}>
                <div className={styles.radio_block_statut}>
                  <div>
                    <RadioInput
                      id='encours'
                      value={SATATUS_ANNONCE.IN_PROGRESS}
                      disabled
                      checked={statut === SATATUS_ANNONCE.IN_PROGRESS}
                      onChange={e => setStatut(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor='encours'>
                      <div className={styles.statut}>
                        <span>
                          <svg
                            className={styles.encours}
                            viewBox='0 0 16 16'
                            xmlns='http://www.w3.org/2000/svg'
                            aria-hidden='true'
                            role='presentation'
                            focusable='false'
                          >
                            <ellipse cx='8' cy='8' fillRule='evenodd' rx='8' ry='8'></ellipse>
                          </svg>
                        </span>
                        <h5>En cours</h5>
                      </div>
                      <p>L'annonce est en cours d'enregistrement</p>
                    </label>
                  </div>
                </div>
                <div className={styles.radio_block_statut}>
                  <div>
                    <RadioInput
                      id='enregistrer'
                      value={SATATUS_ANNONCE.SAVE}
                      disabled
                      checked={statut === SATATUS_ANNONCE.SAVE}
                      onChange={e => setStatut(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor='enregistrer'>
                      <div className={styles.statut}>
                        <span>
                          <svg
                            className={styles.enregistrer}
                            viewBox='0 0 16 16'
                            xmlns='http://www.w3.org/2000/svg'
                            aria-hidden='true'
                            role='presentation'
                            focusable='false'
                          >
                            <ellipse cx='8' cy='8' fillRule='evenodd' rx='8' ry='8'></ellipse>
                          </svg>
                        </span>
                        <h5>Enregistrée</h5>
                      </div>
                      <p>
                        L'hôte a enregistré l'annonce il lui reste à renseigner tous les détails
                        importants, une étape obligatoire pour publier cette annonce.
                      </p>
                    </label>
                  </div>
                </div>
                <div className={styles.radio_block_statut}>
                  <div>
                    <RadioInput
                      id='publier'
                      value={SATATUS_ANNONCE.PUBLISH}
                      checked={statut === SATATUS_ANNONCE.PUBLISH}
                      onChange={e => setStatut(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor='publier'>
                      <div className={styles.statut}>
                        <span>
                          <svg
                            className={styles.publier}
                            viewBox='0 0 16 16'
                            xmlns='http://www.w3.org/2000/svg'
                            aria-hidden='true'
                            role='presentation'
                            focusable='false'
                          >
                            <ellipse cx='8' cy='8' fillRule='evenodd' rx='8' ry='8'></ellipse>
                          </svg>
                        </span>
                        <h5>Publiée</h5>
                      </div>
                      <p>
                        L'annonce apparaît dans les résultats de recherche et les voyageurs peuvent
                        envoyer une demande de réservation ou réserver le logement aux dates
                        disponibles.
                      </p>
                    </label>
                  </div>
                </div>
                <div className={styles.radio_block_statut}>
                  <div>
                    <RadioInput
                      id='masque'
                      value={SATATUS_ANNONCE.HIDE}
                      disabled
                      checked={statut === SATATUS_ANNONCE.HIDE}
                      onChange={e => setStatut(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor='masque'>
                      <div className={styles.statut}>
                        <span>
                          <svg
                            className={styles.masquer}
                            viewBox='0 0 16 16'
                            xmlns='http://www.w3.org/2000/svg'
                            aria-hidden='true'
                            role='presentation'
                            focusable='false'
                          >
                            <ellipse cx='8' cy='8' fillRule='evenodd' rx='8' ry='8'></ellipse>
                          </svg>
                        </span>
                        <h5>Masquée</h5>
                      </div>
                      <p>
                        L'annonce n'apparaît pas dans les résultats de recherche et les voyageurs ne
                        peuvent pas réserver le logement.
                      </p>
                    </label>
                  </div>
                </div>
                <div className={styles.radio_block_statut}>
                  <div>
                    <RadioInput
                      id='desactiver'
                      value={SATATUS_ANNONCE.DEACTIVATE}
                      disabled
                      checked={statut === SATATUS_ANNONCE.DEACTIVATE}
                      onChange={e => setStatut(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor='desactiver'>
                      <div className={styles.statut}>
                        <span>
                          <svg
                            className={styles.desactiver}
                            viewBox='0 0 32 32'
                            xmlns='http://www.w3.org/2000/svg'
                            aria-hidden='true'
                            role='presentation'
                            focusable='false'
                          >
                            <path d='M16 1c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C1 7.716 7.716 1 16 1zm8.122 11.12L12.12 24.122l.136.065c1.055.483 2.2.76 3.387.806L16 25a9 9 0 0 0 9-9c0-1.32-.284-2.598-.818-3.757zM16 7a9 9 0 0 0-9 9c0 1.32.284 2.598.818 3.757l.059.122L19.879 7.877l-.135-.064a8.953 8.953 0 0 0-3.387-.806z'></path>
                          </svg>
                        </span>
                        <h5>Désactivée</h5>
                      </div>
                      <p>Retirer définitivement cette annonce du site Gowifmi.</p>
                    </label>
                  </div>
                </div>
                {annonce.status === SATATUS_ANNONCE.PUBLISH ||
                annonce.status === SATATUS_ANNONCE.HIDE ||
                annonce.status === SATATUS_ANNONCE.DEACTIVATE ? (
                  <GowifmiPublish annonce={annonce} />
                ) : null}
              </div>
            </div>

            <BottomForm
              disabled={statut === annonce.status}
              loading={loading}
              handleReset={reset}
            />
          </form>
        </div>
      )}
    </div>
  )
}

export default Status
