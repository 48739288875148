import { useContext } from 'react'
import styles from '../css/home/home.module.css'
import { User } from '../context/UserContext'
import { Redirect } from 'react-router-dom'
import AllReservatoinActionsCount from '../components/homes/AllReservatoinActionsCount'
import AllHotelCount from '../components/homes/AllHotelCount'
import ChartReservationHotel from '../components/homes/charts/ChartReservationHotel'
import AppLayout from '../layouts/AppLayout'

function Home() {
  const user = useContext(User)
  if (user === null) {
    return <Redirect to='login' />
  }

  return (
    <AppLayout>
      <div className={styles.container}>
        <p className={styles.title_name}>
          Welcome <span>{user.first_name}</span>
        </p>
        <AllReservatoinActionsCount />
        <AllHotelCount />
        <ChartReservationHotel />
      </div>
    </AppLayout>
  )
}
export default Home
