const TYPE_HISTORY = {
  // by traveler
  CREATE_RESERVATION: 'create_reservation',
  CREATE_REQUEST_RESERVATION: 'create_request_reservation',
  CONFIRM_PAYMENT: 'confirm_payment',
  CONFIRM_SETUP_PAYMENT: 'confirm_setup_payment',
  UPDATE_TRAVELERS: 'update_travelers',
  UPDATE_PROCESS: 'update_process',
  CREATE_REQUEST_UPDATE_DATE: 'create_request_update_date',
  CANCEL_REQUEST_UPDATE_DATE: 'cancel_request_update_date',
  CANCEL_REQUEST_RESERVATION: 'cancel_request_reservation',
  CANCEL_RESERVATION: 'cancel_reservation',

  // by host
  ACCEPT_REQUEST_RESERVATION: 'accept_request_reservation',
  ACCEPT_REQUEST_UPDATE_DATE: 'accept_request_update_date',
  REJECT_REQUEST_UPDATE_DATE: 'reject_request_update_date',

  // by gowifmi
  GOWIFMI_UPDATE_RESERVATION: 'gowifmi_update_reservation',
  CHANGE_STATUS_PROCESSING_TO_COMPLETE: 'change_status_processing_to_complete',
  CHANGE_STATUS_FUTURE_TO_IN_PROGRESS: 'change_status_future_to_in_progress',
  CHANGE_STATUS_IN_PROGRESS_TO_COMPLETE: 'change_status_in_progress_to_complete',
  CHANGE_STATUS_TO_DELETE: 'change_status_to_delete',
}

const MODIFY_BY = {
  HOST: 'host',
  TRAVELER: 'traveler',
  GOWIFMI: 'gowifmi',
}

module.exports = { TYPE_HISTORY, MODIFY_BY }
