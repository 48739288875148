import { useState, useEffect, useContext } from 'react'
import styles from '../../css/affiliations/sponsor_container.module.css'
import Transaction from '../../components/affiliations/revenues/Transaction'
import Navigation from '../../components/affiliations/global_components/Navigation'
import { User } from '../../context/UserContext'
import AppLayout from '../../layouts/AppLayout'
import Header from '../../components/affiliations/revenues/Header'
import { useHistory } from 'react-router-dom'

const Revenue = () => {
  const [selectedDate, setSelectedDate] = useState(() => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1 // Les mois sont indexés de 0 à 11, donc ajoutez 1
    return `${year}-${month.toString().padStart(2, '0')}` // 'YYYY-MM'
  })
  const user = useContext(User)
  const history = useHistory()

  useEffect(() => {
    if (user === null) {
      history.push('/')
    }
  }, [])

  return (
    <AppLayout>
      <title>Vos revenus de parrainage | Gowifmi</title>
      <Navigation />
      <div className={styles.main_home}>
        <Header selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
        <Transaction selectedDate={selectedDate} />
      </div>
    </AppLayout>
  )
}

export default Revenue
