import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import ReservationStatus from '../../../../default_components/ReservationStatus'

const StatusReservation = ({ reservation }) => {
  return (
    <div className={styles.content}>
      <h3>Statut de la réservation</h3>
      <ReservationStatus reservation={reservation} />
    </div>
  )
}

export default StatusReservation
