import styles from '../../../css/filter_booking/all_filter.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import Checkbox from '../../ui/Checkbox'

const RefundModification = () => {
  const Query = window.location.search
  const Params = new URLSearchParams(Query)

  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      Params.delete('page')
      Params.delete('modification_refunded')
      Params.append('modification_not_refunded', true)
      history.push(`${path}?${Params}`)
    } else {
      Params.delete('page')
      Params.delete('modification_not_refunded')
      history.push(`${path}?${Params}`)
    }
  }
  const onchangeFilters2 = e => {
    if (e.target.checked === true) {
      Params.delete('page')
      Params.delete('modification_not_refunded')
      Params.append('modification_refunded', true)
      history.push(`${path}?${Params}`)
    } else {
      Params.delete('page')
      Params.delete('modification_refunded')
      history.push(`${path}?${Params}`)
    }
  }

  return (
    <div className={styles.block_section_filter}>
      <h3>Remboursement dû a la modification</h3>
      <div className={styles.grid_system}>
        <div className={styles.checkbox_container}>
          <div>
            <Checkbox
              id='modification_not_refunded'
              checked={Params.get('modification_not_refunded')}
              onChange={onchangeFilters}
            />
          </div>
          <label htmlFor='modification_not_refunded'>Non remboursé</label>
        </div>
        <div className={styles.checkbox_container}>
          <div>
            <Checkbox
              id='modification_refunded'
              checked={Params.get('modification_refunded')}
              onChange={onchangeFilters2}
            />
          </div>
          <label htmlFor='modification_refunded'>Remboursé</label>
        </div>
      </div>
    </div>
  )
}

export default RefundModification
