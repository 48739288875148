import { useState } from 'react'
import styles from '../../../../../../css/modehotes/statistics/evaluations/comment_modal.module.css'
import axios from 'axios'

const GowifmiPublish = ({ refetch, comment }) => {
  const [loading, setLoading] = useState(false)
  const handlechange = e => {
    setLoading(true)
    const data = {
      published: e.target.checked,
    }
    axios
      .patch(`/traveler_comment/${comment._id}`, data)
      .then(async () => {
        await refetch()
        setLoading(false)
      })
      .catch(() => {
        alert("Une erreur s'est produite")
      })
  }

  return (
    <div className={styles.checkox_contener}>
      <div className={styles.text}>
        <label htmlFor='publish'>Gowifmi publie ce commentaire ?</label>
        {comment.published ? (
          <p>Oui Gowifmi publie ce commentaire.</p>
        ) : (
          <p>Non Gowifmi ne publie pas ce commentaire.</p>
        )}
      </div>

      <div>
        <label className={loading ? `${styles.switch} ${styles.disable}` : styles.switch}>
          <input
            id='publish'
            type='checkbox'
            disabled={loading}
            checked={comment.published}
            onChange={e => handlechange(e)}
          />
          <span className={styles.slider}></span>
        </label>
      </div>
    </div>
  )
}

export default GowifmiPublish
