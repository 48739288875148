import styles from '../../../../../css/modehotes/reservation/payment_error_status.module.css'
import { STATUS_BOOKING } from '../../../../../enums/booking/status.enums'
import BigErrorSvg from '../../../../svgs/BigErrorSvg'
import { ONLINE_PAYMENT_STATUS } from '../../../../../enums/booking/onlineStatus'

const StatusSetupIntent = ({ status, payOnline, setupIntentObject }) => {
  if (status !== STATUS_BOOKING.REQUEST) return null
  if (
    !payOnline ||
    !setupIntentObject ||
    setupIntentObject?.status === ONLINE_PAYMENT_STATUS.SUCCEEDED
  )
    return null

  return (
    <div className={styles.container}>
      {setupIntentObject?.status === ONLINE_PAYMENT_STATUS.PROCESSING && (
        <div className={styles.processing}>
          <h2>L'ajout du mode de paiement est en cours de traitement</h2>
          <p>Nous informerons le voyageur par e-mail lorsque le traitement sera terminé.</p>
        </div>
      )}
      {setupIntentObject?.status === ONLINE_PAYMENT_STATUS.REJECTED && (
        <div className={styles.echec}>
          <div>
            <BigErrorSvg />
          </div>
          <div>
            <h2>Échec lors de l'ajout du mode de paiement</h2>
            <p>
              Une erreur s'est produite de l'ajout du mode de paiement en ligne veuillez invité le
              voyageur à réessayer avec autre mode de paiement.
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
export default StatusSetupIntent
