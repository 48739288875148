import styles from '../../../css/filter_booking/all_filter.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import Checkbox from '../../ui/Checkbox'
import { REFUND_SATATUS } from '../../../enums/booking/refundStatus.enums'

const RefundStatus = () => {
  const array = [
    { name: 'Réussi', value: REFUND_SATATUS.COMPLETE },
    { name: 'En traitement', value: REFUND_SATATUS.IN_PROGRESS },
    { name: 'Echoué', value: REFUND_SATATUS.REJECTED },
  ]

  const Query = window.location.search
  const Params = new URLSearchParams(Query)
  URLSearchParams.prototype.remove = function (key, value) {
    const entries = this.getAll(key)
    const newEntries = entries.filter(entry => entry !== value)
    this.delete(key)
    newEntries.forEach(newEntry => this.append(key, newEntry))
  }
  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      Params.delete('page')
      Params.append('refund_total_status', e.target.value)
      history.push(`${path}?${Params}`)
    } else {
      Params.delete('page')
      Params.remove('refund_total_status', e.target.value)
      history.push(`${path}?${Params}`)
    }
  }

  return (
    <div className={styles.block_section_filter}>
      <h3>Statut du remboursement</h3>
      <div className={styles.grid_system}>
        {array.map(item => (
          <div key={item.name} className={styles.checkbox_container}>
            <div>
              <Checkbox
                id={item.name + 'refund'}
                value={item.value}
                checked={Params.getAll('refund_total_status').includes(item.value)}
                onChange={onchangeFilters}
              />
            </div>
            <label htmlFor={item.name + 'refund'}>{item.name}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RefundStatus
