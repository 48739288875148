import { useRef } from 'react'
import styles from '../../../../../../css/modehotes/reservation/detail.module.css'
import { addCurrency } from '../../../../../../utils/addCurrency'
import TransferSatus from '../../../../default_components/TransferSatus'
import ManualModal from './manual_modals/ManualModal'

const TransferManualToHost = ({ reservation, calculatedPrices, transfer, refetch }) => {
  const totalsejour = transfer?.prices?.total_stay_fee
  const hostRevenu = transfer?.prices?.host_fee
  const serviceFee = transfer?.prices?.service_fee
  const currency = transfer?.prices?.currency

  const tranferRef = useRef()
  const handleTransfer = () => {
    tranferRef.current.OpenModal()
  }

  return (
    <div className={styles.content}>
      <h3>Détails du versement pour l'hôte</h3>
      {reservation?.transfer_payment_to_host && (
        <>
          <TransferSatus state={transfer?.status} />
          <div>
            <p>Service : {transfer?.transfer_service}</p>
            {transfer?.transfer_id && (
              <p>
                Identifiant du transfert :{' '}
                <a
                  href={`https://dashboard.stripe.com/connect/transfers/${transfer?.transfer_id}`}
                  target='_blanck'
                >
                  {transfer?.transfer_id}
                </a>
              </p>
            )}
            <p>Total du séjour : {addCurrency(totalsejour, currency)}</p>
            <p>Frais de service Gowifmi : {addCurrency(serviceFee, currency)}</p>
            <p>Revenus de l'hôte : {addCurrency(hostRevenu, currency)}</p>
            {transfer?.comment && <p>Commentaire : {transfer?.comment}</p>}
          </div>
        </>
      )}
      <div className={styles.btn_block}>
        <button type='button' onClick={handleTransfer}>
          {reservation.transfer_payment_to_host
            ? 'Modifier le transfert'
            : 'Enregistrer un transfert'}
        </button>
      </div>
      <ManualModal
        ref={tranferRef}
        reservation={reservation}
        calculatedPrices={calculatedPrices}
        refetch={refetch}
      />
    </div>
  )
}

export default TransferManualToHost
