import styles from '../../../css/filter_booking/all_filter.module.css'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import Checkbox from '../../ui/Checkbox'

const RefundTraveler = () => {
  const Query = window.location.search
  const Params = new URLSearchParams(Query)

  const history = useHistory()
  const location = useLocation()
  const path = location.pathname
  const onchangeFilters = e => {
    if (e.target.checked === true) {
      Params.delete('page')
      Params.delete('no_refund_total')
      Params.append('refund_total', true)
      history.push(`${path}?${Params}`)
    } else {
      Params.delete('page')
      Params.delete('refund_total')
      history.push(`${path}?${Params}`)
    }
  }
  const onchangeFilters2 = e => {
    if (e.target.checked === true) {
      Params.delete('page')
      Params.delete('refund_total')
      Params.append('no_refund_total', true)
      history.push(`${path}?${Params}`)
    } else {
      Params.delete('page')
      Params.delete('no_refund_total')
      history.push(`${path}?${Params}`)
    }
  }
  return (
    <div className={styles.block_section_filter}>
      <h3>Remboursement</h3>
      <div className={styles.grid_system}>
        <div className={styles.checkbox_container}>
          <div>
            <Checkbox
              id='refund_total'
              checked={Params.get('refund_total')}
              onChange={onchangeFilters}
            />
          </div>
          <label htmlFor='refund_total'>Enregistré</label>
        </div>
        <div className={styles.checkbox_container}>
          <div>
            <Checkbox
              id='no_refund_total'
              checked={Params.get('no_refund_total')}
              onChange={onchangeFilters2}
            />
          </div>
          <label htmlFor='no_refund_total'>Non enregistré</label>
        </div>
      </div>
    </div>
  )
}

export default RefundTraveler
