import styles from '../../../../css/modehotes/history/list_history.module.css'
import ProcessBooking from '../../default_components/ProcessBooking'
import ReservationStatus from '../../default_components/ReservationStatus'
import PaymentStatus from '../../default_components/PaymentStatus'
import SetupIntentStatus from '../../default_components/SetupStatus'
import ModificationReservationStatus from '../../default_components/ModificationReservationStatus'

const ReservationDetails = ({ history }) => {
  const changes = history.changes
  const reservation = { status: changes?.status }

  return (
    <div className={styles.modified_block}>
      {changes?.user_id && (
        <p>
          ID du voyageur : <span>{changes?.user_id}</span>
        </p>
      )}
      {changes?.host_id && (
        <p>
          ID de l'hôte : <span>{changes?.host_id}</span>
        </p>
      )}

      {changes?.free_mode !== undefined && (
        <p>
          FreeMode : <span>{changes?.free_mode ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {changes?.status && (
        <p>
          Statut : <ReservationStatus reservation={reservation} />
        </p>
      )}
      {changes?.host_accept_reservation !== undefined && (
        <p>
          Hôte accepte la réservation :{' '}
          <span>{changes?.host_accept_reservation ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {changes?.message && (
        <p>
          Message : <span>{changes?.message}</span>
        </p>
      )}
      {changes?.client_infos && (
        <div>
          {changes?.client_infos?.phone && (
            <p>
              Téléphone : <span>{changes?.client_infos?.phone}</span>
            </p>
          )}
          {changes?.client_infos?.client_country && (
            <p>
              Pays du client : <span>{changes?.client_infos?.client_country}</span>
            </p>
          )}
        </div>
      )}
      {changes?.reservation_infos && (
        <div>
          {changes?.reservation_infos?.total_adults !== undefined && (
            <p>
              Total Adultes : <span>{changes?.reservation_infos?.total_adults}</span>
            </p>
          )}
          {changes?.reservation_infos?.total_children !== undefined && (
            <p>
              Total Enfants : <span>{changes?.reservation_infos?.total_children}</span>
            </p>
          )}
          {changes?.reservation_infos?.total_babies !== undefined && (
            <p>
              Total Bébés : <span>{changes?.reservation_infos?.total_babies}</span>
            </p>
          )}
          {changes?.reservation_infos?.total_pets !== undefined && (
            <p>
              Total Animaux : <span>{changes?.reservation_infos?.total_pets}</span>
            </p>
          )}
          {changes?.reservation_infos?.checkin && (
            <p>
              Check-in :{' '}
              <span>{new Date(changes?.reservation_infos?.checkin).toLocaleDateString()}</span>
            </p>
          )}
          {changes?.reservation_infos?.checkout && (
            <p>
              Check-out :{' '}
              <span>{new Date(changes?.reservation_infos?.checkout).toLocaleDateString()}</span>
            </p>
          )}
          {changes?.reservation_infos?.number_of_nights !== undefined && (
            <p>
              Nombre de nuits : <span>{changes?.reservation_infos?.number_of_nights}</span>
            </p>
          )}
          {changes?.reservation_infos?.reference && (
            <p>
              Référence : <span>{changes?.reservation_infos?.reference}</span>
            </p>
          )}
        </div>
      )}
      {changes?.prices && (
        <div>
          {changes?.prices?.currency && (
            <p>
              Devise : <span>{changes?.prices?.currency}</span>
            </p>
          )}
          {changes?.prices?.nightly_price !== undefined && (
            <p>
              Prix par nuit : <span>${changes?.prices?.nightly_price}</span>
            </p>
          )}
          {changes?.prices?.cleaning_fee !== undefined && (
            <p>
              Frais de ménage : <span>${changes?.prices?.cleaning_fee}</span>
            </p>
          )}
          {changes?.prices?.security_deposit !== undefined && (
            <p>
              Frais de garantit : <span>${changes?.prices?.security_deposit}</span>
            </p>
          )}
          {changes?.prices?.additional_guest_fee !== undefined && (
            <p>
              Frais par voyageur suplémentaire :{' '}
              <span>${changes?.prices?.additional_guest_fee}</span>
            </p>
          )}
          {changes?.prices?.stay_fee !== undefined && (
            <p>
              Tax frais de sèjour : <span>${changes?.prices?.stay_fee}</span>
            </p>
          )}
          {changes?.prices?.total_stay_fee_before_reduction !== undefined && (
            <p>
              Frais de séjour total avant réduction :{' '}
              <span>${changes?.prices?.total_stay_fee_before_reduction}</span>
            </p>
          )}
          {changes?.prices?.promotion_fee !== undefined && (
            <p>
              Frais de promotion : <span>${changes?.prices?.promotion_fee}</span>
            </p>
          )}
          {changes?.prices?.service_fee !== undefined && (
            <p>
              Frais de service voyageur : <span>${changes?.prices?.service_fee}</span>
            </p>
          )}
          {changes?.prices?.promotion_percentage !== undefined && (
            <p>
              Pourcentage de promotion : <span>{changes?.prices?.promotion_percentage}%</span>
            </p>
          )}
          {changes?.prices?.total_stay_fee !== undefined && (
            <p>
              Frais de séjour total : <span>${changes?.prices?.total_stay_fee}</span>
            </p>
          )}
        </div>
      )}
      {changes?.conditions && (
        <div>
          {changes?.conditions?.check_in_1 && (
            <p>
              Check-in 1 : <span>{changes?.conditions?.check_in_1}</span>
            </p>
          )}
          {changes?.conditions?.check_in_2 && (
            <p>
              Check-in 2 : <span>{changes?.conditions?.check_in_2}</span>
            </p>
          )}
          {changes?.conditions?.check_out && (
            <p>
              Check-out : <span>{changes?.conditions?.check_out}</span>
            </p>
          )}
          {changes?.conditions?.smoking_allowed !== undefined && (
            <p>
              Fumeur : <span>{changes?.conditions?.smoking_allowed ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.conditions?.pets_allowed !== undefined && (
            <p>
              Animaux autorisés : <span>{changes?.conditions?.pets_allowed ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.conditions?.cancellation_policy && (
            <p>
              Politique d'annulation : <span>{changes?.conditions?.cancellation_policy}</span>
            </p>
          )}
          {changes?.conditions?.accept_children !== undefined && (
            <p>
              Acceptation des enfants :{' '}
              <span>{changes?.conditions?.accept_children ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.conditions?.accept_babies !== undefined && (
            <p>
              Acceptation des bébés :{' '}
              <span>{changes?.conditions?.accept_babies ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.conditions?.event_allowed !== undefined && (
            <p>
              Événements autorisés :{' '}
              <span>{changes?.conditions?.event_allowed ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes?.conditions?.accommodation_rules && (
            <p>
              Règles de l'hébergement : <span>{changes?.conditions?.accommodation_rules}</span>
            </p>
          )}
        </div>
      )}
      {changes?.availability_calendar && (
        <div>
          {changes?.availability_calendar?.preparation_time !== undefined && (
            <p>
              Temps de préparation : <span>{changes?.availability_calendar?.preparation_time}</span>
            </p>
          )}
        </div>
      )}
      {changes?.hotel && (
        <p>
          ID Hôtel : <span>{changes?.hotel}</span>
        </p>
      )}
      {changes?.pay_online !== undefined && (
        <p>
          Paiement en ligne : <span>{changes?.pay_online ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {changes?.bill_paid !== undefined && (
        <p>
          Facture payée : <span>{changes?.bill_paid ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {changes?.booking_processing && (
        <p>
          Traitement de la réservation : <ProcessBooking state={changes?.booking_processing} />
        </p>
      )}
      {changes?.booking_processing_error && (
        <div>
          <h3>Erreur de traitement de réservation :</h3>
          {changes?.booking_processing_error?.code && (
            <p>
              Code : <span>{changes?.booking_processing_error?.code}</span>
            </p>
          )}
          {changes?.booking_processing_error?.message && (
            <p>
              Message : <span>{changes?.booking_processing_error?.message}</span>
            </p>
          )}
          {changes?.booking_processing_error?.type && (
            <p>
              Type : <span>{changes?.booking_processing_error?.type}</span>
            </p>
          )}
        </div>
      )}
      {changes?.payment_intent && (
        <div>
          {changes?.payment_intent?.stripe_id && (
            <p>
              Stripe ID : <span>{changes?.payment_intent?.stripe_id}</span>
            </p>
          )}
          {changes?.payment_intent?.status && (
            <p>
              Statut : <PaymentStatus state={changes?.payment_intent?.status} />
            </p>
          )}
        </div>
      )}
      {changes?.setup_intent && (
        <div>
          {changes?.setup_intent?.stripe_id && (
            <p>
              Stripe ID : <span>{changes?.setup_intent?.stripe_id}</span>
            </p>
          )}
          {changes?.setup_intent?.status && (
            <p>
              Statut : <SetupIntentStatus state={changes?.setup_intent?.status} />
            </p>
          )}
        </div>
      )}
      {changes?.refund_total && (
        <div>
          {changes?.refund_total?.refund_service && (
            <p>
              Service de remboursement : <span>{changes?.refund_total?.refund_service}</span>
            </p>
          )}
          {changes?.refund_total?.refund_id && (
            <p>
              ID de remboursement : <span>{changes?.refund_total?.refund_id}</span>
            </p>
          )}
          {changes?.refund_total?.status && (
            <p>
              Statut : <PaymentStatus state={changes?.refund_total?.status} />
            </p>
          )}
          {changes?.refund_total?.refund_amount !== undefined && (
            <p>
              Montant remboursé : <span>${changes?.refund_total?.refund_amount}</span>
            </p>
          )}
          {changes?.refund_total?.comment && (
            <p>
              Commentaire : <span>{changes?.refund_total?.comment}</span>
            </p>
          )}
        </div>
      )}
      {changes?.isReservation_refunded !== undefined && (
        <p>
          Réservation remboursée : <span>{changes?.isReservation_refunded ? 'Oui' : 'Non'}</span>
        </p>
      )}
      {changes?.modifications && (
        <div>
          {changes.modifications?.user_update && (
            <p>
              Utilisateur modificateur : <span>{changes.modifications?.user_update}</span>
            </p>
          )}
          {changes.modifications?.modification_status && (
            <p>
              Statut de la modification :{' '}
              <ModificationReservationStatus state={changes.modifications?.modification_status} />
            </p>
          )}
          {changes.modifications?.payment_intent?.stripe_id && (
            <p>
              Stripe ID (Modification) :{' '}
              <span>{changes.modifications?.payment_intent?.stripe_id}</span>
            </p>
          )}
          {changes.modifications?.payment_intent?.status && (
            <p>
              Statut (Modification) :{' '}
              <PaymentStatus state={changes?.modifications?.payment_intent?.status} />
            </p>
          )}
          {changes.modifications?.refund?.refund_id && (
            <p>
              ID de remboursement (Modification) :{' '}
              <span>{changes.modifications?.refund?.refund_id}</span>
            </p>
          )}
          {changes.modifications?.refund?.refund_service && (
            <p>
              Service de remboursement (Modification) :{' '}
              <span>{changes.modifications?.refund?.refund_service}</span>
            </p>
          )}
          {changes.modifications?.refund?.status && (
            <p>
              Statut (Modification) :{' '}
              <PaymentStatus state={changes?.modifications?.refund?.status} />
            </p>
          )}
          {changes.modifications?.refund?.comment && (
            <p>
              Commentaire (Modification) : <span>{changes.modifications?.refund?.comment}</span>
            </p>
          )}
          {changes.modifications?.isModification_refunded !== undefined && (
            <p>
              Modification remboursée :{' '}
              <span>{changes.modifications?.isModification_refunded ? 'Oui' : 'Non'}</span>
            </p>
          )}
          {changes.modifications?.total_initial !== undefined && (
            <p>
              Total initial : <span>${changes.modifications?.total_initial}</span>
            </p>
          )}
          {changes.modifications?.checkin && (
            <p>
              Check-in (Modification) :{' '}
              <span>{new Date(changes.modifications?.checkin).toLocaleDateString()}</span>
            </p>
          )}
          {changes.modifications?.checkout && (
            <p>
              Check-out (Modification) :{' '}
              <span>{new Date(changes.modifications?.checkout).toLocaleDateString()}</span>
            </p>
          )}
          {changes.modifications?.total_nights !== undefined && (
            <p>
              Total Nuits : <span>{changes.modifications?.total_nights}</span>
            </p>
          )}
          {changes?.modifications?.nightly_price !== undefined && (
            <p>
              Prix par nuit : <span>${changes?.modifications?.nightly_price}</span>
            </p>
          )}
          {changes?.modifications?.cleaning_fee !== undefined && (
            <p>
              Frais de ménage : <span>${changes?.modifications?.cleaning_fee}</span>
            </p>
          )}
          {changes?.modifications?.security_deposit !== undefined && (
            <p>
              Frais de garantit : <span>${changes?.modifications?.security_deposit}</span>
            </p>
          )}
          {changes?.modifications?.additional_guest_fee !== undefined && (
            <p>
              Frais par voyageur suplémentaire :{' '}
              <span>${changes?.modifications?.additional_guest_fee}</span>
            </p>
          )}
          {changes?.modifications?.stay_fee !== undefined && (
            <p>
              Tax frais de sèjour : <span>${changes?.modifications?.stay_fee}</span>
            </p>
          )}
          {changes?.modifications?.total_stay_fee_before_reduction !== undefined && (
            <p>
              Frais de séjour total avant réduction :{' '}
              <span>${changes?.modifications?.total_stay_fee_before_reduction}</span>
            </p>
          )}
          {changes.modifications?.promotion_percentage !== undefined && (
            <p>
              Pourcentage de promotion : <span>{changes.modifications?.promotion_percentage}%</span>
            </p>
          )}
          {changes.modifications?.promotion_fee !== undefined && (
            <p>
              Frais de promotion : <span>${changes.modifications?.promotion_fee}</span>
            </p>
          )}
          {changes.modifications?.service_fee !== undefined && (
            <p>
              Frais de service : <span>${changes.modifications?.service_fee}</span>
            </p>
          )}
          {changes.modifications?.total_stay_fee !== undefined && (
            <p>
              Frais de séjour total : <span>${changes.modifications?.total_stay_fee}</span>
            </p>
          )}
          {changes.modifications?.new_price_to_pay !== undefined && (
            <p>
              Nouveau prix à payer : <span>${changes.modifications?.new_price_to_pay}</span>
            </p>
          )}
          {changes.modifications?.currency && (
            <p>
              Devise : <span>{changes.modifications?.currency}</span>
            </p>
          )}
          {changes.modifications?.createdAt && (
            <p>
              Créé le :{' '}
              <span>{new Date(changes.modifications?.createdAt).toLocaleDateString()}</span>
            </p>
          )}
        </div>
      )}
      {changes?.comment && (
        <p>
          Commentaire : <span>{changes?.comment}</span>
        </p>
      )}
      {changes?.traveler_comment && (
        <p>
          Commentaire du voyageur : <span>{changes?.traveler_comment}</span>
        </p>
      )}
      {changes?.cancelation_request && (
        <p>
          Demande d'annulation : <span>{changes?.cancelation_request}</span>
        </p>
      )}
      {changes?.cancellations && (
        <div>
          {changes?.cancellations?.cancel_reason_1 && (
            <p>
              Raison d'annulation 1 : <span>{changes?.cancellations?.cancel_reason_1}</span>
            </p>
          )}
          {changes?.cancellations?.cancel_reason_2 && (
            <p>
              Raison d'annulation 2 : <span>{changes?.cancellations?.cancel_reason_2}</span>
            </p>
          )}
          {changes?.cancellations?.cancel_reason_3 && (
            <p>
              Raison d'annulation 3 : <span>{changes?.cancellations?.cancel_reason_3}</span>
            </p>
          )}
          {changes?.cancellations?.cancel_reason_4 && (
            <p>
              Raison d'annulation 4 : <span>{changes?.cancellations?.cancel_reason_4}</span>
            </p>
          )}
          {changes?.cancellations?.cancel_explanation && (
            <p>
              Explication de l'annulation :{' '}
              <span>{changes?.cancellations?.cancel_explanation}</span>
            </p>
          )}
          {changes?.cancellations?.date && (
            <p>
              Date d'annulation :{' '}
              <span>{new Date(changes?.cancellations?.date).toLocaleDateString()}</span>
            </p>
          )}
        </div>
      )}
      {changes?.platform_infos && (
        <div>
          {changes?.platform_infos?.browser_name && (
            <p>
              Nom du navigateur : <span>{changes?.platform_infos?.browser_name}</span>
            </p>
          )}
          {changes?.platform_infos?.browser_version && (
            <p>
              Version du navigateur : <span>{changes?.platform_infos?.browser_version}</span>
            </p>
          )}
          {changes?.platform_infos?.device_type && (
            <p>
              Type d'appareil : <span>{changes?.platform_infos?.device_type}</span>
            </p>
          )}
          {changes?.platform_infos?.device_vendor && (
            <p>
              Fabricant de l'appareil : <span>{changes?.platform_infos?.device_vendor}</span>
            </p>
          )}
          {changes?.platform_infos?.device_model && (
            <p>
              Modèle de l'appareil : <span>{changes?.platform_infos?.device_model}</span>
            </p>
          )}
          {changes?.platform_infos?.os_name && (
            <p>
              Nom du système d'exploitation : <span>{changes?.platform_infos?.os_name}</span>
            </p>
          )}
          {changes?.platform_infos?.os_version && (
            <p>
              Version du système d'exploitation : <span>{changes?.platform_infos?.os_version}</span>
            </p>
          )}
        </div>
      )}
      {changes?.location_infos && (
        <div>
          {changes?.location_infos?.ip && (
            <p>
              IP : <span>{changes?.location_infos?.ip}</span>
            </p>
          )}
          {changes?.location_infos?.city && (
            <p>
              Ville : <span>{changes?.location_infos?.city}</span>
            </p>
          )}
          {changes?.location_infos?.country && (
            <p>
              Pays : <span>{changes?.location_infos?.country}</span>
            </p>
          )}
          {changes?.location_infos?.countryCode && (
            <p>
              Code du pays : <span>{changes?.location_infos?.countryCode}</span>
            </p>
          )}
          {changes?.location_infos?.regionName && (
            <p>
              Région : <span>{changes?.location_infos?.regionName}</span>
            </p>
          )}
          {changes?.location_infos?.timezone && (
            <p>
              Fuseau horaire : <span>{changes?.location_infos?.timezone}</span>
            </p>
          )}
          {changes?.location_infos?.continent && (
            <p>
              Continent : <span>{changes?.location_infos?.continent}</span>
            </p>
          )}
          {changes?.location_infos?.continentCode && (
            <p>
              Code du continent : <span>{changes?.location_infos?.continentCode}</span>
            </p>
          )}
          {changes?.location_infos?.lat !== undefined && (
            <p>
              Latitude : <span>{changes?.location_infos?.lat}</span>
            </p>
          )}
          {changes?.location_infos?.lng !== undefined && (
            <p>
              Longitude : <span>{changes?.location_infos?.lng}</span>
            </p>
          )}
          {changes?.location_infos?.isProxy !== undefined && (
            <p>
              Utilisation d'un proxy :{' '}
              <span>{changes?.location_infos?.isProxy ? 'Oui' : 'Non'}</span>
            </p>
          )}
        </div>
      )}
      {changes?.transfer_payment_to_host && (
        <p>
          Transfert de paiement à l'hôte : <span>{changes?.transfer_payment_to_host}</span>
        </p>
      )}
    </div>
  )
}

export default ReservationDetails
