import { useState, useEffect } from 'react'
import styles from '../../../../css/users/filters/all_filter.module.css'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import SearchInput from '../../../ui/SearchInput'

let timeoutId

const ByName = () => {
  const history = useHistory()
  const location = useLocation()
  const pathname = location.pathname
  const Query = location.search
  const Params = new URLSearchParams(Query)
  const text = Params.get('name')
  const [search, setSearch] = useState(text || '')
  const [searchUpdated, setSearchUpdated] = useState(false)

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    if (searchUpdated) {
      timeoutId = setTimeout(() => {
        if (search.length > 0) {
          Params.delete('page')
          Params.set('name', search)
          history.push(`${pathname}?${Params}`)
        } else {
          Params.delete('page')
          Params.delete('name')
          history.push(`${pathname}?${Params}`)
        }
      }, 700)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [search])

  return (
    <div className={styles.block_section_filter}>
      <h3>Le Nom</h3>
      <SearchInput
        id='search_name'
        value={search}
        onChange={e => {
          setSearchUpdated(true)
          setSearch(e.target.value)
        }}
        onClick={() => {
          setSearchUpdated(true)
          setSearch('')
        }}
      />
    </div>
  )
}

export default ByName
