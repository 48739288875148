import styles from '../../../css/accounts/find_user.module.css'
import LoadingPoint from '../../LoadingPoint'
import CopyTextToClipboard from '../../ui/CopyTextToClipboard'

const CheckEdSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
      aria-hidden='true'
      role='presentation'
      focusable='false'
      style={{
        display: 'block',
        fill: 'none',
        height: '20px',
        width: '20px',
        stroke: 'currentcolor',
        strokeWidth: '2.66667',
        overflow: 'visible',
      }}
    >
      <path fill='none' d='m4 16.5 8 8 16-16'></path>
    </svg>
  )
}

const DisplayUser = ({ data, isLoading, error }) => {
  return (
    <div className={styles.display_block}>
      {isLoading && <LoadingPoint />}
      {data && (
        <div className={styles.display_block_info}>
          {data.isIdentityValid && (
            <div className={styles.identity_verif}>
              <div className={styles.icon}>
                <svg
                  viewBox='0 0 16 16'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  role='presentation'
                  focusable='false'
                  style={{
                    display: 'block',
                    height: '18px',
                    width: '18px',
                    fill: 'currentColor',
                  }}
                >
                  <path d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z'></path>
                </svg>
              </div>
              <h2>identité vérifiée</h2>
            </div>
          )}
          {data?.identitySubmitted && !data.isIdentityValid && (
            <div className={styles.display_block_info_item}>
              <p>Identité</p>
              <span>En attente d'approbation</span>
            </div>
          )}
          {data?.first_name && (
            <div className={styles.display_block_info_item}>
              <p>Prénom</p>
              <CopyTextToClipboard text={data?.first_name} title='Prénom' />
            </div>
          )}
          {data?.name && (
            <div className={styles.display_block_info_item}>
              <p>Nom</p>
              <CopyTextToClipboard text={data?.name} title='Nom' />
            </div>
          )}
          {data.email && (
            <div className={styles.display_block_info_item}>
              <p>Email</p>
              <CopyTextToClipboard text={data?.email} title='Email' />
              {data.isEmailVerified && <CheckEdSvg />}
            </div>
          )}
          {data?.work_email && (
            <div className={styles.display_block_info_item}>
              <p>Email professionnel</p>
              <CopyTextToClipboard text={data?.work_email} title='Email pro' />
            </div>
          )}
          {data?.phone_number && (
            <div className={styles.display_block_info_item}>
              <p>Téléphone</p>
              <CopyTextToClipboard text={data?.phone_number} title='Téléphone' />
              {data.isTelephoneVerified && <CheckEdSvg />}
            </div>
          )}
        </div>
      )}
      {error && <p>Aucun utilisateur trouvé veuillez entrer un identifiant valide </p>}
    </div>
  )
}

export default DisplayUser
