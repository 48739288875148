import { useState, useEffect } from 'react'
import styles from '../../../../../../css/modehotes/annonce/details/infosDebase.module.css'
import Logement from './options/Logement'
import Hotel from './options/Hotel'
import Maison from './options/Maison'
import Annexe from './options/Annexe'
import LogementUnique from './options/LogementUnique'
import LogementNonResidenciel from './options/LogementNonResidenciel'
import useUpdateHotel from '../../../../../../hooks/useUpdateHotel'
import BottomForm from '../../defaults/components/modif_sections/BottomForm'
import Section from '../../defaults/components/modif_sections/Section'
import ContenHead from '../../defaults/components/modif_sections/ContenHead'
import { TYPE_LOGEMENT } from '../../../../../../enums/logement/type_logement.enums'
import { PROPERTY_TYPE_GROUP } from '../../../../../../enums/logement/type_property_group.enums'
import { TYPE_USAGE_ANNONCE } from '../../../../../../enums/logement/type_usage_annonce.enums'

const TypeLogement = ({ annonce }) => {
  const [open, setOpen] = useState(false)
  const [categoryProperty, setCategoryProperty] = useState('')
  const [typeproperty, setTypeproperty] = useState('')
  const [typelogement, setTypelogement] = useState('')
  const [typeannonce, setTypeAnnonce] = useState('')

  const { loading, updateData } = useUpdateHotel(setOpen)

  const handleSubmit = e => {
    e.preventDefault()
    const datas = {
      property_type_group: categoryProperty,
      property_type: typeproperty,
      accommodation_type: typelogement,
      usage_home_type: typeannonce,
    }
    updateData(datas)
  }
  useEffect(() => {
    setCategoryProperty(annonce.property_type_group)
    setTypeproperty(annonce.property_type)
    setTypelogement(annonce.accommodation_type)
    setTypeAnnonce(annonce.usage_home_type)
  }, [annonce])
  const openFuction = () => {
    setCategoryProperty(annonce.property_type_group)
    setTypeproperty(annonce.property_type)
    setTypelogement(annonce.accommodation_type)
    setTypeAnnonce(annonce.usage_home_type)
    setOpen(true)
  }
  const reset = () => {
    setCategoryProperty('')
    setTypeproperty('')
    setTypelogement('')
    setTypeAnnonce('')
    setOpen(false)
  }
  return (
    <div className={styles.block}>
      <h2>Logement et chambres</h2>
      {!open ? (
        <Section title='Type de logement' handleOpen={openFuction}>
          {annonce.accommodation_type === TYPE_LOGEMENT.ENTIRE_HOME && (
            <p>Type d'annonce : Logement entier </p>
          )}
          {annonce.accommodation_type === TYPE_LOGEMENT.PRIVATE_ROOM && (
            <p>Type d'annonce : Chambre privée </p>
          )}
          {annonce.accommodation_type === TYPE_LOGEMENT.SHARED_ROOM && (
            <p>Type d'annonce : Chambre partagée </p>
          )}
        </Section>
      ) : (
        <div className={styles.content}>
          <ContenHead title='Type de logement' handleReset={reset}>
            <p>
              Choisissez le type de logement qui correspond le mieux au vôtre pour qu'il apparaisse
              correctement dans les résultats de recherche et que les voyageurs sachent à quoi
              s'attendre.
            </p>
          </ContenHead>
          <form onSubmit={handleSubmit}>
            <div className={styles.body}>
              <div className={styles.input_block}>
                <label htmlFor='categorie'>
                  Quelle catégorie correspond le mieux à votre logement ?
                </label>
                <select
                  id='categorie'
                  defaultValue={categoryProperty}
                  onChange={e => setCategoryProperty(e.target.value)}
                >
                  <option value={PROPERTY_TYPE_GROUP.APARTMENT}>Appartement</option>
                  <option value={PROPERTY_TYPE_GROUP.HOUSE}>Maison</option>
                  <option value={PROPERTY_TYPE_GROUP.HOTEL}>Hôtel</option>
                  <option value={PROPERTY_TYPE_GROUP.ANNEX}>Annexe</option>
                  <option value={PROPERTY_TYPE_GROUP.UNIQUE_ACCOMMODATION}>Logement unique</option>
                  <option value={PROPERTY_TYPE_GROUP.OTHER_NON_RESIDENTIAL_ACCOMMODATION}>
                    Autre logement non résidentiel
                  </option>
                </select>
              </div>
              <div className={styles.input_block}>
                <label htmlFor='propriete'>Type de propriété</label>
                <select
                  id='propriete'
                  defaultValue={typeproperty}
                  onChange={e => setTypeproperty(e.target.value)}
                >
                  {categoryProperty === PROPERTY_TYPE_GROUP.APARTMENT && <Logement />}
                  {categoryProperty === PROPERTY_TYPE_GROUP.HOUSE && <Maison />}
                  {categoryProperty === PROPERTY_TYPE_GROUP.HOTEL && <Hotel />}
                  {categoryProperty === PROPERTY_TYPE_GROUP.ANNEX && <Annexe />}
                  {categoryProperty === PROPERTY_TYPE_GROUP.UNIQUE_ACCOMMODATION && (
                    <LogementUnique />
                  )}
                  {categoryProperty === PROPERTY_TYPE_GROUP.OTHER_NON_RESIDENTIAL_ACCOMMODATION && (
                    <LogementNonResidenciel />
                  )}
                </select>
              </div>
              <div className={styles.input_block}>
                <label htmlFor='logement'>Type de logement</label>
                <select
                  id='logement'
                  onChange={e => setTypelogement(e.target.value)}
                  defaultValue={typelogement}
                >
                  <option value={TYPE_LOGEMENT.ENTIRE_HOME}>Logement entier</option>
                  <option value={TYPE_LOGEMENT.PRIVATE_ROOM}>Chambre privée</option>
                  <option value={TYPE_LOGEMENT.SHARED_ROOM}>Chambre partagée</option>
                </select>
              </div>
              <div className={styles.input_block}>
                <label htmlFor='typeannonce'>Type d'hébergement</label>
                <select
                  id='typeannonce'
                  onChange={e => setTypeAnnonce(e.target.value)}
                  defaultValue={typeannonce}
                >
                  <option value={TYPE_USAGE_ANNONCE.PRIMARY_RESIDENCE}>
                    Une résidence principale
                  </option>
                  <option value={TYPE_USAGE_ANNONCE.SECONDARY_RESIDENCE}>
                    Une résidence secondaire
                  </option>
                  <option value={TYPE_USAGE_ANNONCE.NON_RESIDENTIAL}>
                    Un hôtel ou un hébergement non résidentiel
                  </option>
                </select>
              </div>
            </div>
            <BottomForm loading={loading} handleReset={reset} />
          </form>
        </div>
      )}
    </div>
  )
}

export default TypeLogement
