import { useContext } from 'react'
import styles from '../../css/accounts/AllaccountSeting.module.css'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { User } from '../../context/UserContext'
import SvgIconRightDirector from '../svgs/SvgIconRightDirector'

const AllAccountSetings = () => {
  const user = useContext(User)
  const location = useLocation()
  const path = location.pathname.split('/')[2]

  return (
    <>
      <div className={styles.setings_block}>
        <Link to={`/profile/${user?.id}`}>
          <div className={styles.left_block}>
            <div>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 32 32'
                aria-hidden='true'
                role='presentation'
                focusable='false'
                style={{
                  display: 'block',
                  height: '32px',
                  width: '32px',
                  stroke: 'currentcolor',
                  strokeWidth: '2',
                  fill: 'currentcolor',
                  overflow: 'visible',
                }}
              >
                <g fill='none'>
                  <circle cx='16' cy='16' r='14'></circle>
                  <path d='M14.02 19.66a6 6 0 1 1 3.96 0M17.35 19.67H18c3.69.61 6.8 2.91 8.54 6.08m-20.92-.27A12.01 12.01 0 0 1 14 19.67h.62'></path>
                </g>
              </svg>
            </div>
            <div>
              <h2>Aller au profil</h2>
              <p>Comment nous pouvons vous joindre</p>
            </div>
          </div>
          <SvgIconRightDirector />
        </Link>
        <Link
          to='/account-settings/info-personal'
          className={path === 'info-personal' ? styles.active : null}
        >
          <div className={styles.left_block}>
            <div>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 32 32'
                aria-hidden='true'
                role='presentation'
                focusable='false'
                style={{ display: 'block', height: '32px', width: '32px', fill: 'currentcolor' }}
              >
                <path d='M29 5a2 2 0 0 1 2 1.85V25a2 2 0 0 1-1.85 2H3a2 2 0 0 1-2-1.85V7a2 2 0 0 1 1.85-2H3zm0 2H3v18h26zm-3 12v2h-8v-2zm-16-8a3 3 0 0 1 2.5 4.67A5 5 0 0 1 15 20h-2a3 3 0 0 0-2-2.83V14a1 1 0 0 0-2-.12v3.29A3 3 0 0 0 7 20H5a5 5 0 0 1 2.5-4.33A3 3 0 0 1 10 11zm16 4v2h-8v-2zm0-4v2h-8v-2z'></path>
              </svg>
            </div>
            <div>
              <h2>Informations personnelles</h2>
              <p>
                Fournissez des renseignements personnels et indiquez comment nous pouvons vous
                joindre
              </p>
            </div>
          </div>
          <SvgIconRightDirector />
        </Link>
        <Link
          to='/account-settings/login-and-security'
          className={path === 'login-and-security' ? styles.active : null}
        >
          <div className={styles.left_block}>
            <div>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 32 32'
                aria-hidden='true'
                role='presentation'
                focusable='false'
                style={{ display: 'block', height: '32px', width: '32px', fill: 'currentcolor' }}
              >
                <path d='m16 .8.56.37C20.4 3.73 24.2 5 28 5h1v12.5C29 25.57 23.21 31 16 31S3 25.57 3 17.5V5h1c3.8 0 7.6-1.27 11.45-3.83L16 .8zm-1 3a22.2 22.2 0 0 1-9.65 3.15L5 6.97V17.5c0 6.56 4.35 11 10 11.46zm2 0v25.16c5.65-.47 10-4.9 10-11.46V6.97l-.35-.02A22.2 22.2 0 0 1 17 3.8z'></path>
              </svg>
            </div>
            <div>
              <h2>Connexion et sécurité</h2>
              <p>Mettez à jour votre mot de passe et sécurisez votre compte</p>
            </div>
          </div>
          <SvgIconRightDirector />
        </Link>
      </div>
    </>
  )
}

export default AllAccountSetings
