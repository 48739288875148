import { useEffect, useContext, useState } from 'react'
import styles from '../../../css/modehotes/revenues/revenue.module.css'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { User } from '../../../context/UserContext'
import LoadingPoint from '../../../components/LoadingPoint'
import { addCurrencyComplet } from '../../../utils/addCurrency'
import AppLayout from '../../../layouts/AppLayout'
import ErrorServer from '../../errors/ErrorServer'
import { useQuery } from 'react-query'
import SelecteDate from '../../../components/hote_mode/revenues/SelecteDate'

const Revenue = () => {
  const [selectedDate, setSelectedDate] = useState(() => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1 // Les mois sont indexés de 0 à 11, donc ajoutez 1
    return `${year}-${month.toString().padStart(2, '0')}` // 'YYYY-MM'
  })

  const user = useContext(User)
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
  }, [history])

  const {
    data = {},
    isLoading,
    error,
  } = useQuery(['get-host_revenue', selectedDate], () =>
    axios.get(`/host_revenue?date=${selectedDate}`).then(res => {
      return res.data
    })
  )

  if (error) return <ErrorServer />

  return (
    <AppLayout>
      <title>Revenus - Gowifmi</title>
      <div className={styles.container}>
        {isLoading ? (
          <div className={styles.container_load_liste}>
            <LoadingPoint />
          </div>
        ) : (
          <div className={styles.main}>
            <h1>Vos revenus</h1>
            <div className={styles.net}>
              <h2>{addCurrencyComplet(data.annualIncome, 'usd')}</h2>
              <p>Revenus des réservations pour {selectedDate.split('-')[0]}</p>
            </div>
            <div className={styles.detail}>
              <div>
                <h2>{addCurrencyComplet(data.annualIncomePaid, 'usd')}</h2>
                <div className={styles.detail_block}>
                  <span className={styles.black}></span>
                  <p>Versé</p>
                </div>
              </div>
              <div>
                <h2>{addCurrencyComplet(data.annualUpcomingIncome, 'usd')}</h2>
                <div className={styles.detail_block}>
                  <span className={styles.gray}></span>
                  <p>À venir</p>
                </div>
              </div>
            </div>

            <div className={styles.infos}>
              <SelecteDate selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
              <div className={styles.infos_block}>
                <p>Revenus des réservations versés</p>
                <p className={styles.infos_block_number}>
                  {addCurrencyComplet(data.monthlyIncomePaid, 'usd')}
                </p>
              </div>
              <div className={styles.infos_block}>
                <p>Revenus des réservations à venir</p>
                <p className={styles.infos_block_number}>
                  {addCurrencyComplet(data.monthlyUpcomingIncome, 'usd')}
                </p>
              </div>
              <div className={styles.infos_block}>
                <p>Nuits réservées</p>
                <p className={styles.infos_block_number}>{data.monthlyReservedNights}</p>
              </div>
              <div className={styles.infos_block}>
                <p>Nuits non réservées</p>
                <p className={styles.infos_block_number}>{data.monthlyUnReservedNights}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  )
}

export default Revenue
