import { useEffect, useContext, useState } from 'react'
import styles from '../../../css/modehotes/statistics/evaluations/statistic.module.css'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { User } from '../../../context/UserContext'
import LoadingPoint from '../../../components/LoadingPoint'
import AppLayout from '../../../layouts/AppLayout'
import ErrorServer from '../../errors/ErrorServer'
import { useQuery } from 'react-query'
import Header from '../../../components/hote_mode/statistics/Header'
import HotelGlobalEvaluation from '../../../components/hote_mode/statistics/evaluations/HotelGlobalEvaluation'
import CommentHotel from '../../../components/hote_mode/statistics/evaluations/CommentHotel'
import ListingHotel from '../../../components/hote_mode/statistics/ListingHotel'

const CommentListing = () => {
  const [selectedListing, setSelectedListing] = useState('')

  const user = useContext(User)
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      history.push('/')
    }
  }, [history])

  const {
    data = {},
    refetch,
    isLoading,
    error,
  } = useQuery(['get-host_evaluation', selectedListing], () =>
    axios.get(`/statistics/host_evaluation?hotel_id=${selectedListing}`).then(res => {
      return res.data
    })
  )
  if (error) return <ErrorServer />

  return (
    <AppLayout>
      <title>Évaluations - Gowifmi</title>
      <div className={styles.container}>
        <Header />
        <ListingHotel selectedListing={selectedListing} setSelectedListing={setSelectedListing} />
        {isLoading ? (
          <div className={styles.container_load_liste}>
            <LoadingPoint />
          </div>
        ) : (
          <div className={styles.main}>
            <HotelGlobalEvaluation data={data} />
            <CommentHotel
              refetch={refetch}
              commentLength={data.commentLength}
              comments={data.comments}
            />
          </div>
        )}
      </div>
    </AppLayout>
  )
}

export default CommentListing
