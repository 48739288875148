import styles from '../../../css/mains/add.module.css'
import { Link, Redirect } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import { useLocation } from 'react-router'
import AppLayout from '../../../layouts/AppLayout'
import Loading from '../../../components/Loading'
import { User } from '../../../context/UserContext'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: toast => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

function UpdateFeedback() {
  const [redirect, SetRedirect] = useState(false)
  const [contact, setContact] = useState(null)
  const [loading, setLoading] = useState(true)
  const user = useContext(User)

  const location = useLocation()
  const path = location.pathname.split('/')[3]
  const { register, handleSubmit } = useForm()

  const onSubmit = data => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Pour mettre à jour ce contact',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, modifié !',
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .patch('/contact/' + path, data)
          .then(() => {
            Toast.fire({ icon: 'success', text: 'contact modifié avec succès' })
            SetRedirect(true)
          })
          .catch(() => {
            Toast.fire({ icon: 'error', text: 'échec de la mise à jour' })
          })
      }
    })
  }

  useEffect(() => {
    axios
      .get('/contact/' + path)
      .then(res => {
        setContact(res.data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }, [path])

  if (redirect) return <Redirect to='/feedbaks' />

  if (user === null) {
    return <Redirect to='/' />
  }
  return (
    <AppLayout>
      <div className={styles.block_content}>
        <p>Rendez-vous</p>
        {loading ? (
          <Loading />
        ) : (
          <div className={styles.container_bouton}>
            <div className={styles.bouton}>
              <Link to='/feedbaks' title='Liste des rendez-vous'>
                <i className='fa fa-list-ul'></i>&nbsp; Liste des rendez-vous
              </Link>
              <Link to='/feedbaks/update' title='Modifier un contact' className={styles.active}>
                <i className='fa fa-edit'></i>&nbsp; Modifier un rendez-vous
              </Link>
              <Link to='/reports/users' title='Liste des Signaler utilisateurs'>
                <i className='fa fa-list-ul'></i>&nbsp; Liste des utilisateurs signalés
              </Link>
              <Link to='/reports/hotels' title='Liste des annonces signalés'>
                <i className='fa fa-list-ul'></i>&nbsp; Liste des annonces signalés
              </Link>
            </div>
            <div className={styles.container_text}>
              <h2>
                <span className={styles.modif}>Modification</span> d'un contact
              </h2>
              <p>
                Veuillez <span className={styles.modif}>Corriger</span> le formulaire
              </p>
            </div>
            <form action='' onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.form}>
                <div className={styles.form_position}>
                  <label htmlFor='type'>Type</label>
                  <input
                    autoComplete='off'
                    type='text'
                    id='type'
                    placeholder='type...'
                    {...register('type', { required: false, value: contact.type })}
                  />
                </div>
                <div className={styles.form_position}>
                  <label htmlFor='fonctionnalite'>Fonctionnalité</label>
                  <input
                    autoComplete='off'
                    type='text'
                    id='fonctionnalite'
                    placeholder='fonctionnalite...'
                    {...register('fonctionnalite', {
                      required: false,
                      value: contact.fonctionnalite,
                    })}
                  />
                </div>
                <div className={styles.form_position}>
                  <label htmlFor='message'>Message</label>
                  <textarea
                    id='message'
                    type='text'
                    cols='90'
                    rows='5'
                    placeholder='saisir vos remarques...'
                    {...register('message', { value: contact.message })}
                  ></textarea>
                </div>
              </div>
              <div className={styles.button_modif}>
                <button type='submit'>modifier</button>
              </div>
            </form>
          </div>
        )}
      </div>
    </AppLayout>
  )
}
export default UpdateFeedback
