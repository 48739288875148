import { useEffect, useState } from 'react'
import styles from '../../../../../css/modehotes/annonce/details/listAnnonce.module.css'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import momentTimeZone from 'moment-timezone'
import HeaderList from './components/list_annonce/HeaderList'
import LoadingList from './components/list_annonce/LoadingList'
import Pagination from '../../defaults/Pagination'
import { useQuery } from 'react-query'

const ListAnnonce = () => {
  const [searchParams, setSearchParams] = useState(window.location.search)

  const history = useHistory()
  const path = window.location.pathname.split('/')[4]

  const userTimezone = momentTimeZone.tz.guess()

  useEffect(() => {
    const unlisten = history.listen(location => {
      setSearchParams(location.search)
    })

    return () => {
      unlisten()
    }
  }, [history])

  const fetchData = async () => {
    const { data } = await axios.get(`/gowifmi_hotels${searchParams}`)
    return data
  }

  const { data = [], isLoading, error } = useQuery([`get_all_hotels`, searchParams], fetchData)

  const handleDetail = id => {
    history.push(`/hosting/listings/details/${id}${searchParams}`)
  }

  return (
    <>
      <div className={styles.annonce_list}>
        <HeaderList isLoading={isLoading} annonce={data} />
        {isLoading ? (
          <LoadingList />
        ) : (
          <div className={styles.content_list}>
            {error && (
              <div className={styles.no_result}>
                <p>Une erreur est survenue veuilllez réessayer</p>
              </div>
            )}
            {data?.total_data > 0 && (
              <>
                <div className={styles.content_list_block}>
                  {data.results.map(e => (
                    <button
                      key={e._id}
                      onClick={() => handleDetail(e._id)}
                      className={e._id === path ? styles.active : null}
                    >
                      <div className={styles.card_hotel_container}>
                        <div>
                          <div className={styles.card_hotel}>
                            {e.images[0]?.url ? (
                              <img src={e.images[0]?.url} alt='' />
                            ) : (
                              <svg
                                viewBox='0 0 16 16'
                                xmlns='http://www.w3.org/2000/svg'
                                aria-hidden='true'
                                role='presentation'
                                focusable='false'
                              >
                                <path d='M8.602 1.147l.093.08 7.153 6.914-.696.718L14 7.745V14.5a.5.5 0 0 1-.41.492L13.5 15H10V9.5a.5.5 0 0 0-.41-.492L9.5 9h-3a.5.5 0 0 0-.492.41L6 9.5V15H2.5a.5.5 0 0 1-.492-.41L2 14.5V7.745L.847 8.86l-.696-.718 7.153-6.915a1 1 0 0 1 1.297-.08z'></path>
                              </svg>
                            )}
                          </div>
                        </div>
                        <div>
                          <h3>{e.title || 'Donnez un titre à votre annonce'}</h3>
                          <p>
                            Modifié : {momentTimeZone.utc(e.updatedAt).tz(userTimezone).calendar()}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div
                          className={
                            e.gowifmi_published
                              ? `${styles.publish_gowifmi} ${styles.active}`
                              : styles.publish_gowifmi
                          }
                        ></div>
                      </div>
                    </button>
                  ))}
                </div>
                <Pagination data={data} />
              </>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default ListAnnonce
