import { useState, useContext } from 'react'
import styles from '../../../css/accounts/profile.module.css'
import { User } from '../../../context/UserContext'
import moment from 'moment'
import Swal from 'sweetalert2'
import axios from 'axios'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: toast => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

function ProfileBlock() {
  const [file, setFile] = useState()
  const [chargement, setChargement] = useState(false)
  const user = useContext(User)
  const handlePicture = e => {
    e.preventDefault()
    setChargement(true)
    const data = new FormData()
    data.append('name', user.name)
    data.append('user_Id', user.id)
    data.append('file', file)

    axios
      .post('/user/upload', data)
      .then(res => {
        Toast.fire({ icon: 'success', title: 'image ajouter avec succes' })
        setChargement(false)
        window.location.reload()
      })
      .catch(err => {
        Toast.fire({ icon: 'error', title: 'Error' })
        setChargement(false)
      })
  }
  return (
    <>
      <div className={styles.profil}>
        <div className={styles.profil_block}>
          <div className={styles.profil_image}>
            {!user?.picture && !file ? (
              <svg
                viewBox='0 0 32 32'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                role='presentation'
                focusable='false'
              >
                <path d='m16 .7c-8.437 0-15.3 6.863-15.3 15.3s6.863 15.3 15.3 15.3 15.3-6.863 15.3-15.3-6.863-15.3-15.3-15.3zm0 28c-4.021 0-7.605-1.884-9.933-4.81a12.425 12.425 0 0 1 6.451-4.4 6.507 6.507 0 0 1 -3.018-5.49c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5a6.513 6.513 0 0 1 -3.019 5.491 12.42 12.42 0 0 1 6.452 4.4c-2.328 2.925-5.912 4.809-9.933 4.809z'></path>
              </svg>
            ) : (
              <img src={file ? URL.createObjectURL(file) : user?.picture} alt='avatar' />
            )}
          </div>
          <form action='' onSubmit={handlePicture} className={styles.form_profil}>
            <label htmlFor='file' className={styles.profil_label}>
              <i className='fas fa-cloud-upload-alt'></i> Changer d'image
            </label>
            <input
              type='file'
              id='file'
              name='file'
              accept='.jpg, .jpeg, .png'
              onChange={e => setFile(e.target.files[0])}
              styles={{ display: 'none' }}
            />
            <br />
            {file && <input type='submit' value={chargement ? 'chargement..' : 'Mettre à jour'} />}
          </form>
          <div className={styles.profil_text}>
            <ul>
              <li>
                Nom: <span>{user?.name}</span>
              </li>
              <li>
                Prénom: <span>{user?.first_name}</span>
              </li>
              <li>
                Email: <span>{user?.email}</span>
              </li>
              <li>
                Téléphone: <span>{user?.phone_number}</span>
              </li>
              <li>
                Date de création: <span>{moment(user?.createdAt).format('LLL')}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
export default ProfileBlock
