const GowifmiLogoSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='120'
      height='30'
      viewBox='0 0 120 30'
      fill='none'
    >
      <path
        d='M44.1091 23.1072C42.7998 23.1072 41.6201 22.8 40.57 22.1856C39.5199 21.5584 38.6967 20.7072 38.1004 19.632C37.504 18.5568 37.2058 17.3728 37.2058 16.08C37.2058 14.8384 37.504 13.6992 38.1004 12.6624C38.7097 11.6256 39.5523 10.8064 40.6283 10.2048C41.7043 9.60321 42.9294 9.30241 44.3036 9.30241C45.0555 9.30241 45.7815 9.38561 46.4815 9.55201C47.1946 9.71841 47.8363 9.95521 48.4067 10.2624L47.1233 13.2384C46.2417 12.88 45.4185 12.7008 44.6536 12.7008C43.6554 12.7008 42.8841 13.0208 42.3396 13.6608C41.7951 14.288 41.5228 15.1136 41.5228 16.1376C41.5228 16.8032 41.646 17.4048 41.8923 17.9424C42.1386 18.4672 42.4822 18.8832 42.9229 19.1904C43.3767 19.4976 43.8888 19.6512 44.4592 19.6512C44.7703 19.6512 45.0296 19.6256 45.237 19.5744V16.4832L48.6012 16.0992V22.1088C47.1362 22.7744 45.6389 23.1072 44.1091 23.1072Z'
        fill='#007FF4'
      />
      <path
        d='M55.5274 22.9536C54.4384 22.9536 53.4661 22.7424 52.6105 22.32C51.7678 21.8976 51.1067 21.3152 50.627 20.5728C50.1603 19.8176 49.927 18.9536 49.927 17.9808C49.927 17.008 50.1668 16.1504 50.6465 15.408C51.1261 14.6528 51.7873 14.0704 52.63 13.6608C53.4856 13.2512 54.4579 13.0464 55.5468 13.0464C56.6488 13.0464 57.6211 13.2576 58.4637 13.68C59.3193 14.0896 59.9805 14.6656 60.4472 15.408C60.9139 16.1504 61.1473 17.008 61.1473 17.9808C61.1473 18.9664 60.9074 19.8368 60.4278 20.592C59.9611 21.3344 59.2999 21.9168 58.4443 22.3392C57.6016 22.7488 56.6293 22.9536 55.5274 22.9536ZM55.5663 20.0736C56.0978 20.0736 56.5321 19.8816 56.8692 19.4976C57.2192 19.1008 57.3942 18.5952 57.3942 17.9808C57.3942 17.3664 57.2192 16.8672 56.8692 16.4832C56.5321 16.0864 56.0978 15.888 55.5663 15.888C55.0348 15.888 54.594 16.0864 54.244 16.4832C53.9069 16.8672 53.7384 17.3664 53.7384 17.9808C53.7384 18.5952 53.9069 19.1008 54.244 19.4976C54.594 19.8816 55.0348 20.0736 55.5663 20.0736Z'
        fill='#007FF4'
      />
      <path
        d='M61.8885 13.1808H65.8554L66.9639 19.4208L68.3056 13.1808H71.6503L73.0115 19.5744L74.12 13.1808H77.3091L75.2867 22.8H70.6391L69.5502 17.712L68.5195 22.8H64.0275L61.8885 13.1808Z'
        fill='#007FF4'
      />
      <path
        d='M82.0929 22.9536C81.004 22.9536 80.1548 22.608 79.5455 21.9168C78.9362 21.2256 78.6316 20.2848 78.6316 19.0944V13.1808H82.7541V18.9216C82.7541 19.216 82.8319 19.4464 82.9874 19.6128C83.156 19.7664 83.3699 19.8432 83.6292 19.8432C83.8884 19.8432 84.1542 19.7664 84.4264 19.6128L84.6404 22.2816C84.3551 22.4992 83.9792 22.6656 83.5125 22.7808C83.0587 22.896 82.5856 22.9536 82.0929 22.9536ZM80.6928 11.7408C80.0835 11.7408 79.565 11.5552 79.1371 11.184C78.7223 10.8 78.5149 10.3328 78.5149 9.78241C78.5149 9.23201 78.7353 8.77121 79.176 8.40001C79.6168 8.02881 80.1224 7.84321 80.6928 7.84321C81.2892 7.84321 81.8012 8.03521 82.2291 8.41921C82.6698 8.80321 82.8902 9.25761 82.8902 9.78241C82.8902 10.3456 82.6698 10.8128 82.2291 11.184C81.7883 11.5552 81.2762 11.7408 80.6928 11.7408Z'
        fill='#007FF4'
      />
      <path
        d='M86.3598 15.5616H85.1347V13.1808H86.3598V12.3744C86.3598 10.9536 86.7033 9.87841 87.3904 9.14881C88.0905 8.41921 89.0628 8.05441 90.3073 8.05441C91.3055 8.05441 92.2325 8.30401 93.0881 8.80321L92.952 11.3952C92.2001 11.1008 91.5713 10.9536 91.0657 10.9536C90.6768 10.9536 90.3786 11.0496 90.1712 11.2416C89.9638 11.4208 89.8601 11.6832 89.8601 12.0288C89.8601 12.3616 89.9703 12.7456 90.1906 13.1808H92.9131V15.5616H90.4629V22.8H86.3598V15.5616Z'
        fill='#007FF4'
      />
      <path
        d='M94.0715 13.3152H97.9801V15.2736C98.4209 14.5184 98.92 13.9616 99.4774 13.6032C100.048 13.232 100.722 13.0464 101.5 13.0464C102.265 13.0464 102.926 13.2384 103.483 13.6224C104.054 13.9936 104.481 14.4992 104.767 15.1392C105.596 13.744 106.802 13.0464 108.384 13.0464C109.136 13.0464 109.79 13.2448 110.348 13.6416C110.918 14.0384 111.359 14.6144 111.67 15.3696C111.981 16.112 112.137 16.9888 112.137 18V22.8H108.034V18C108.034 17.3472 107.917 16.8544 107.684 16.5216C107.463 16.1888 107.152 16.0224 106.75 16.0224C106.309 16.0224 105.927 16.208 105.603 16.5792C105.292 16.9504 105.136 17.3984 105.136 17.9232V22.8H101.072V18C101.072 17.3472 100.955 16.8544 100.722 16.5216C100.502 16.1888 100.184 16.0224 99.7691 16.0224C99.3283 16.0224 98.9524 16.208 98.6413 16.5792C98.3301 16.9504 98.1745 17.3984 98.1745 17.9232V22.8H94.0715V13.3152Z'
        fill='#007FF4'
      />
      <path
        d='M117.453 22.9536C116.364 22.9536 115.514 22.608 114.905 21.9168C114.296 21.2256 113.991 20.2848 113.991 19.0944V13.1808H118.114V18.9216C118.114 19.216 118.192 19.4464 118.347 19.6128C118.516 19.7664 118.73 19.8432 118.989 19.8432C119.248 19.8432 119.514 19.7664 119.786 19.6128L120 22.2816C119.715 22.4992 119.339 22.6656 118.872 22.7808C118.418 22.896 117.945 22.9536 117.453 22.9536ZM116.052 11.7408C115.443 11.7408 114.925 11.5552 114.497 11.184C114.082 10.8 113.875 10.3328 113.875 9.78241C113.875 9.23201 114.095 8.77121 114.536 8.40001C114.976 8.02881 115.482 7.84321 116.052 7.84321C116.649 7.84321 117.161 8.03521 117.589 8.41921C118.029 8.80321 118.25 9.25761 118.25 9.78241C118.25 10.3456 118.029 10.8128 117.589 11.184C117.148 11.5552 116.636 11.7408 116.052 11.7408Z'
        fill='#007FF4'
      />
      <path
        d='M0 3.94737C0 1.7673 1.78993 0 3.99793 0H26.3863C28.5943 0 30.3842 1.7673 30.3842 3.94737V26.0526C30.3842 28.2327 28.5943 30 26.3863 30H3.99792C1.78993 30 0 28.2327 0 26.0526V3.94737Z'
        fill='#007FF4'
      />
      <path
        d='M6.75204 14.9153C6.75204 10.6626 8.18823 7.66129 11.0606 5.91129C12.5755 4.97043 14.4347 4.5 16.6381 4.5C18.8219 4.5 20.8778 4.83873 22.8059 5.51615C23.007 5.59014 23.1473 5.86556 23.0781 6.06619L22.4992 7.7466C22.4179 7.9825 22.1964 8.11082 21.9501 8.05645C21.714 7.98119 21.4681 7.91533 21.2123 7.85887C20.9762 7.7836 20.6614 7.70834 20.268 7.63307C19.8745 7.5578 19.5007 7.49194 19.1465 7.43549C18.3596 7.28495 17.5235 7.20969 16.6381 7.20969C14.4347 7.20969 12.8214 7.80242 11.7984 8.98791C10.795 10.1734 10.2933 12.168 10.2933 14.9718C10.2933 18.1519 10.8737 20.2782 12.0345 21.3508C12.6247 21.8965 13.2739 22.2729 13.9822 22.4798C14.6904 22.6868 15.5266 22.7903 16.4906 22.7903C16.6922 22.7903 16.9068 22.7874 17.1343 22.7817C18.9096 22.7367 20.268 21.2461 20.268 19.4927V17.9552C20.268 17.1085 20.913 16.3974 21.7654 16.3044C22.7614 16.1958 23.6322 16.9658 23.6322 17.9552V23.856C23.6322 24.1716 23.3801 24.4865 23.0715 24.5685C22.7173 24.6438 22.3927 24.7191 22.0976 24.7944C21.8025 24.8508 21.3894 24.9261 20.8582 25.0202C20.3466 25.1142 19.8548 25.1989 19.3826 25.2742C18.2022 25.4247 17.0414 25.5 15.9004 25.5C12.969 25.5 10.7065 24.6062 9.1129 22.8186C7.539 21.0309 6.75204 18.3965 6.75204 14.9153Z'
        fill='white'
      />
      <path
        d='M23.1258 13.8333C23.1258 14.4777 22.5967 15 21.9442 15C21.2916 15 20.7626 14.4777 20.7626 13.8333C20.7626 13.189 21.2916 12.6667 21.9442 12.6667C22.5967 12.6667 23.1258 13.189 23.1258 13.8333Z'
        fill='white'
      />
    </svg>
  )
}

export default GowifmiLogoSvg
