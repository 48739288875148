import { useEffect, useContext, useState } from 'react'
import styles from '../../../../css/modehotes/reservation/listeReservation.module.css'
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import Swal from 'sweetalert2'
import { User } from '../../../../context/UserContext'
import LoadingPoint from '../../../../components/LoadingPoint'
import Header from '../../../../components/hote_mode/bookings/Header'
import AppLayout from '../../../../layouts/AppLayout'
import { useQuery } from 'react-query'
import Pagination from '../../../../components/ui/Pagination'

const ListRequestDelete = () => {
  const [searchParams, setSearchParams] = useState(window.location.search)

  const user = useContext(User)
  const history = useHistory()

  useEffect(() => {
    if (user === null) {
      history.push('/')
    }
    const unlisten = history.listen(location => {
      setSearchParams(location.search)
    })

    return () => {
      unlisten()
    }
  }, [history, user])

  const fetchData = async () => {
    const { data } = await axios.get(`/gowifmi_reservations/all_reservation_delete${searchParams}`)
    return data
  }

  const {
    data = {},
    isLoading,
    refetch,
    error,
  } = useQuery([`all_reservation_delete`, searchParams], fetchData)

  const handleDelete = id => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Une fois la réservation supprimée, vous ne pourrez plus la récupérer.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Oui, supprimé!',
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .delete('/gowifmi_reservations/' + id)
          .then(() => {
            Swal.fire('Supprimé!', 'Votre réservation a été supprimée.', 'success')
            refetch()
          })
          .catch(err => {
            Swal.fire('error', 'Une erreur est survenue veuillez réessayer', 'error')
          })
      }
    })
  }

  return (
    <AppLayout>
      <title>Réservations - Gowifmi</title>
      <div className={styles.voyage_container}>
        <Header isLoading={isLoading} data={data} />
        {isLoading ? (
          <div className={styles.container_load_liste}>
            <LoadingPoint />
          </div>
        ) : (
          <>
            {error && (
              <div className={styles.no_result}>
                <p>Une erreur est survenue veuilllez réessayer</p>
              </div>
            )}
            {data?.totalRecords < 1 && (
              <div className={styles.no_result}>
                <h2>Aucun résultat trouvé</h2>
                <p>Veuillez essayer de sélectionner différents mots-clés ou filtres.</p>
              </div>
            )}
            {data?.totalRecords > 0 && (
              <div className={styles.container_arry}>
                <table className={styles.customers}>
                  <thead>
                    <tr>
                      <th className={styles.image}>Annonce</th>
                      <th>Date d'arrivée</th>
                      <th>Date de départ</th>
                      <th>Qui annule la demande</th>
                      <th>Référence</th>
                      <th>Voyageur</th>
                      <th>Date réservation</th>
                      {user?.role === 'admin' && <th>Sup définetivement</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.reservations.map(item => (
                      <tr key={item._id}>
                        <td className={styles.image}>
                          <Link to={`/hosting/reservations/details/${item._id}`}>
                            <div>
                              <div className={styles.card}>
                                <img src={item.hotel?.images[0].url} alt='couverture' />
                              </div>
                            </div>
                            <p>{item.hotel?.title}</p>
                          </Link>
                        </td>
                        <td className={styles.rows}>
                          {moment(item.reservation_infos?.checkin).format('ll')}
                        </td>
                        <td className={styles.rows}>
                          {moment(item.reservation_infos?.checkout).format('ll')}
                        </td>
                        <td className={styles.rows}>{item.cancelation_request}</td>
                        <td className={styles.rows}>{item.reservation_infos?.reference}</td>
                        <td className={styles.rows}>{item.user_id?.first_name}</td>
                        <td className={styles.rows}>{moment(item.createdAt).format('ll')}</td>
                        {user?.role === 'admin' && (
                          <td className={styles.action}>
                            <button className={styles.first} onClick={() => handleDelete(item._id)}>
                              Supprimer
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination data={data} />
              </div>
            )}
          </>
        )}
      </div>
    </AppLayout>
  )
}

export default ListRequestDelete
