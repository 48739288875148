import styles from '../../../../../css/modehotes/annonce/details/detail_head.module.css'
import Status from './components/header_details/Status'
import ResInstantane from './components/header_details/ResInstantane'
import Preview from './components/header_details/Preview'
import History from './components/header_details/History'
import ClosePage from './components/header_details/ClosePage'
import Calendar from './components/header_details/Calendar'
import HostProfile from './components/header_details/HostProfile'

const Head = ({ annonce }) => {
  return (
    <div className={styles.container}>
      <div>
        <h1>{annonce.title} </h1>

        <div className={styles.actions}>
          <Status annonce={annonce} />
          <ResInstantane annonce={annonce} />
          <Calendar annonce={annonce} />
          <Preview annonce={annonce} />
          <History annonce={annonce} />
          <HostProfile annonce={annonce} />
        </div>
      </div>
      <div className={styles.close_block}>
        <ClosePage />
      </div>
    </div>
  )
}

export default Head
